import { Card, Col, Flex, List, Row, Segmented, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import bag from '../../assets/img/all/bag.svg';
import emp from '../../assets/img/all/employee.svg';
import Gatepass from '../../assets/img/all/gatepass.svg';
import lot from '../../assets/img/all/lot.svg';
import recentg from '../../assets/img/all/recentegatepass.svg';
import first1 from '../../assets/img/dashboard/righticon.svg';
import first2 from '../../assets/img/dashboard/righticon2.svg';
import first3 from '../../assets/img/dashboard/righticon3.svg';
import { getDashboardData } from '../../store/services/authService';
import SideCardHeader from '../common/SideCardHeader';
import { Line } from '@ant-design/plots';
import moment from 'moment';



const Dashboard = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState(
        [{
            year: '2024-01-25 16:00:00',
            value: '2',
            category: 'test 1'
        }]);
    const [filterValue, setFilterValue] = useState("DAILY")
    const auth = useSelector(state => state.auth);
    const { dashboardData } = auth;

    const dispatchData = dashboardData && dashboardData.recent_created_dispatch && dashboardData.recent_created_dispatch.length > 0 && dashboardData.recent_created_dispatch.map((data, index) => ({
        key: index + 1, // Assuming you want the index as the key
        person_name: data.user.first_name + " " + data.user.last_name,
        delivery_place: data.delivery_place,
        date: moment(data.date).format('DD-MM-YYYY'),
        invoice_number: data.invoice_number
    }));

    const batchData = dashboardData && dashboardData.recent_created_batch && dashboardData.recent_created_batch.length > 0 && dashboardData.recent_created_batch.map((data, index) => ({
        key: index + 1, // Assuming you want the index as the key
        batch_num: "B " + data.batch_count,
        qty: data.qty ? data.qty :"-",
        created_by: data.created_by,
        invoice_number: data.invoice_number,
    }));

    const batchColumns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Batch Number',
            dataIndex: 'batch_num',
            key: 'batch_num',
            headerStyle: { background: '#0000' }, // Set the background color for this column header
            ellipsis: true,
        },
        {
            title: 'Bag Qty',
            dataIndex: 'qty',
            key: 'qty',
            ellipsis: true,
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
            ellipsis: true,
        },
    ];

    const dispatchColumns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
            fixed: "right",
            width: 50
        },
        {
            title: 'Customer name',
            dataIndex: 'person_name',
            key: 'person_name',
            headerStyle: { background: '#0000' },
            ellipsis: true // Set the background color for this column header
        },
        {
            title: 'Delivery Place',
            dataIndex: 'delivery_place',
            key: 'delivery_place',
            ellipsis: true,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            ellipsis: true
        },
        {
            title: 'Invoice number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            ellipsis: true,
            fixed: "right",
        },
    ];

    const asyncFetchApi = async () => {
        await fetch('https://gw.alipayobjects.com/os/bmw-prod/55424a73-7cb8-4f79-b60d-3ab627ac5698.json').then(res => res.json()).then(json => setData(json)).catch((error) => {
            console.log('fetch data failed', error);
        });
    }
    useEffect(() => {
        asyncFetchApi();
    }, [])

    console.log({ data });


    useEffect(() => {
        dispatch(getDashboardData({ filterValue }));
    }, [filterValue])


    const productionData = dashboardData && dashboardData.production && dashboardData.production.length > 0
        ? dashboardData.production.map((d, i) => ({
            year: d.date,
            value: d.count,
            category: d.certificate
        }))
        : [];

    const config = {
        data: productionData,
        xField: (d) => new Date(d.year),
        yField: 'value',
        yReverse: true,
        yScale: {
            type: 'linear',
            nice: true,
            // Invert the scale to start from the bottom
            invert: true,
        },
        sizeField: 'value',
        shapeField: 'trail',
        legend: { size: false },
        colorField: 'category',
        smooth: true,
        animate: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    };

    const listData = [];

    dashboardData && dashboardData.recent_update && dashboardData.recent_update.length > 0 && dashboardData.recent_update.map((data, index) => {
        let image;
        if (data && data.record_type === 'BATCH') {
            image = first1;
        } else if (data && data.record_type === 'DISPATCH') {
            image = first2;
        } else if (data && data.record_type === 'GATEPASS') {
            image = first3;
        }
        listData.push({
            image,
            title: data && data.user + ` (${data.role})`,
            // title: data && data.user + ` (${data.role && data.role.charAt(0).toUpperCase() + data.role.slice(1)})`,
            description: data && data.log,
            time: data && data.created_at && moment(data.created_at).fromNow()
        })
    })


    return (
        <div className='flex justify-center h-full max-h-screen'>

            {/* CONTENT SECTION STARTS  */}
            <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar  flex flex-col h-full min-h-screen overflow-hidden'>

                <div className='border-b border-b-[#919da94d] flex items-center 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'>
                    <p className='font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>Dashboard</p>
                </div>
                <div className='flex-1 overflow-auto  px-6 py-4 scrollbar-container'>

                    <Row gutter={{
                        xs: 2,
                        sm: 2,
                        md: 8,
                        lg: 16,
                        xl: 28,
                        xxl: 28
                    }}>
                        <Col span={6}>
                            <Card id="Dashboard-card" className='group cursor-pointer  bg-white rounded-[15px] transition duration-300 ease-in-out hover:text-red-500'>
                                <p className='text-[#919DA9] text-[10px] 2xl:text-sm xl:text-[12px]  group-hover:text-primary transition-all group-hover:font-bold'>Bag Created</p>
                                <div className='flex w-full items-center pt-0 2xl:pt-1 xl:pt-1 justify-between'>
                                    <p className='text-primary xl:text-[18px] text-[14px] 2xl:text-[24px] transition-all group-hover:text-secondary  '>
                                        {dashboardData && dashboardData.total_bag_created ? dashboardData.total_bag_created : "0"}
                                    </p>
                                    <img src={bag} color='red' alt="Bag" className='group-hover:filter-red-500 group-hover:backdrop-brightness-white group-hover:filter-tint-rose-500 transition duration-300 ease-in-out' />
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card id="Dashboard-card" className='group cursor-pointer  bg-white rounded-[15px] transition duration-300 ease-in-out hover:text-red-500'>
                                <p className='text-[#919DA9] text-[10px] 2xl:text-sm xl:text-[12px] group-hover:text-primary transition-all group-hover:font-bold'>Batch Created</p>
                                <div className='flex w-full items-center pt-0 2xl:pt-1 xl:pt-1 justify-between'>
                                    <p className='text-primary 2xl:text-[24px] transition-all group-hover:text-secondary  xl:text-[18px] text-[14px]'>
                                        {dashboardData && dashboardData.total_batch_created ? dashboardData.total_batch_created : "0"}
                                    </p>
                                    {/* <p className='mb-0'>302094</p> */}
                                    <img src={lot} color='red' alt="Lot" className='group-hover:filter-red-500 group-hover:backdrop-brightness-white group-hover:filter-tint-rose-500 transition duration-300 ease-in-out' />
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card id="Dashboard-card" className='group cursor-pointer  bg-white rounded-[15px] transition duration-300 ease-in-out hover:text-red-500'>
                                <p className='text-[#919DA9] text-[10px] 2xl:text-sm xl:text-[12px] group-hover:text-primary transition-all group-hover:font-bold'>Dispatch Created</p>
                                <div className='flex w-full items-center pt-0 2xl:pt-1 xl:pt-1 justify-between'>
                                    <p className='text-primary 2xl:text-[24px] transition-all group-hover:text-secondary  xl:text-[18px] text-[14px]'>
                                        {dashboardData && dashboardData.total_dispatch_created ? dashboardData.total_dispatch_created : "0"}
                                    </p>
                                    {/* <p className='mb-0'>302094</p> */}
                                    <img src={Gatepass} color='red' alt="Lot" className='group-hover:filter-red-500 group-hover:backdrop-brightness-white group-hover:filter-tint-rose-500 transition duration-300 ease-in-out' />
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card id="Dashboard-card" className='group cursor-pointer  bg-white rounded-[15px] transition duration-300 ease-in-out hover:text-red-500'>
                                <p className='text-[#919DA9] text-[10px] 2xl:text-sm xl:text-[12px] group-hover:text-primary transition-all group-hover:font-bold'>Total Members</p>
                                <div className='flex w-full items-center pt-0 2xl:pt-1 xl:pt-1 justify-between'>
                                    <p className='text-primary 2xl:text-[24px] transition-all group-hover:text-secondary  xl:text-[18px] text-[14px]'>
                                        {dashboardData && dashboardData.total_members ? dashboardData.total_members : "0"}
                                    </p>
                                    <img src={emp} color='red' alt="Lot" className='group-hover:filter-red-500 group-hover:backdrop-brightness-white group-hover:filter-tint-rose-500 transition duration-300 ease-in-out' />
                                </div>
                            </Card>
                        </Col>
                        <Col span={12} className='mt-4 mb-2'>
                            <Flex gap="middle" align="center" className='mt-2 mb-3' horizontal="true">
                                <div className='flex items-center gap-x-2'>
                                    <img src={recentg} alt='recent' />  <p className='2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary'>Recent Batch</p>
                                </div>
                                <Link to="/batch"><p className='text-secondary font-GlegooBold text-xs'>View All</p> </Link>                           </Flex>
                            <Table
                                className={`rounded-[15px] h-[240px] gatepass-table min-h-[240px] ${!batchData && "custom-dash-table"}`} scroll={{
                                    // x: "max-content",
                                    // y: 200
                                    y: batchData ? 200 : undefined,
                                }}
                                dataSource={batchData}
                                columns={batchColumns}
                                pagination={false} />
                        </Col>
                        <Col span={12} className='mt-4 mb-2'>
                            <Flex gap="middle" align="center" className='mt-2 mb-3' horizontal="true">
                                <div className='flex items-center gap-x-2'>
                                    <img src={recentg} alt='recent' />  <p className='2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary'>Recent Dispatches</p>
                                </div>
                                <Link to="/dispatch-batch"><p className='text-secondary font-GlegooBold text-xs'>View All</p> </Link>                           </Flex>
                            <Table
                                className={`${!dispatchData && "custom-dash-table"} rounded-[15px] xl:h-[170px] gatepass-table min-h-[240px]`} scroll={{
                                    // x: "max-content",
                                    // y: 200
                                    y: dispatchData ? 200 : undefined,
                                }}
                                dataSource={dispatchData}
                                columns={dispatchColumns}
                                pagination={false} />
                        </Col>
                       {/*  <Col span={24} className='mb-4'>
                            <Flex justify='space-between' align="center" className=' mb-3' horizontal="true">
                                <p className='text-base font-GlegooBold text-primary'>Production Breakdown</p>
                                <div>  <Segmented onChange={setFilterValue} size='middle' className='rounded-full  dashboard-segment' block options={[{ label: 'Daily', value: 'DAILY' }, { label: 'Weekly', value: 'WEEKLY' }, { label: 'Monthly', value: "MONTHLY" }, { label: 'Yearly', value: 'YEARLY' }]} /></div>
                            </Flex>
                             <div className='rounded-[15px] bg-white h-[330px]'><Line {...config} /></div> 
                        </Col> */}
                    </Row>
                </div>
            </div>
            {/* CONTENT SECTION ENDS  */}

            {/* SIDEBAR SECTION STARTS  */}
            <div className='bg-main border-l border-l-[#919da94d] w-[30%] flex flex-col '>
                <SideCardHeader />

                <div className='py-3 2xl:px-8 px-5 xl:px-7 flex items-center justify-between border-b border-b-[#919da94d] '>
                    <p className='custom-sixt text-primary font-GlegooBold'>Recent Updates</p>
                    <p className='  custom-sixt text-[#919DA9] font-GlegooBold lg:text-[12px] text-[12px]'>{dashboardData && dashboardData.recent_update && (`(${dashboardData.recent_update.length})`)}</p>
                </div>
                <div className='overflow-auto flex-1 scrollbar-container '>
                    <List
                        size="large"
                        className='rounded-none '
                        dataSource={listData}
                        renderItem={(item) => (
                            <div className=' 2xl:mx-5  xl:mx-5 lg:mx-3 mx-3  2xl:px-2 xl:px-2 px-2 border-b py-3' >
                                <div className='flex items-start w-full '>

                                    <div className='flex items-center gap-x-3'>
                                        <img src={item.image} alt="img" />
                                        <div >
                                            <p className='text-primary font-gilroyBold custom-sixt'>{item.title}</p>
                                            <p className='flex-wrap text-custom-gray custom-fourt'>{item.description}</p></div>
                                    </div>
                                    <div className='ml-auto'><p className='whitespace-nowrap text-[10px]  text-custom-gray'>{item.time}</p></div>
                                </div>
                            </div>
                        )}
                    />
                </div>
            </div>
            {/* SIDEBAR SECTION ENDS  */}

        </div >
    )
}

export default Dashboard
