import { createSlice } from "@reduxjs/toolkit";
import { CreateTransportAgency, actionDeleteTransportAgency, GetTransportAgencyDetail, actionUpdateTransportAgency, actionGetTransportAgency, actionGetTransportAgencyDetail, actionCreateTransportAgency } from "../services/transportService";

const transportSlice = createSlice({
    name: "transportAgency",
    initialState: {
        transportAgencies: "",
        transportAgencyLoader: false,
        transportAgencyCount: "",
        transportAgencyDetail: "",
        transportAgencyDetailLoader: false,
        deleteTransportAgencyLoader: false,
        deleteSucc: false,
        createTransportAgencyLoader: false,
        updateTransportAgencyLoader: false,
    },
    reducers: {
        deleteSuccess: (state) => {
            state.deleteSucc = true;
        }
    },
    extraReducers: (buider) => {
        buider
            // Get transportAgency all
            .addCase(actionGetTransportAgency.pending, (state) => {
                state.transportAgencyLoader = true;
            })
            .addCase(actionGetTransportAgency.fulfilled, (state, action) => {
                state.transportAgencies = action?.payload?.data;
                state.transportAgencyCount = action?.payload?.total_records;
                state.transportAgencyLoader = false;
            })

            .addCase(actionGetTransportAgency.rejected, (state) => {
                state.transportAgencyLoader = false;
            })

            // Get Single TransportAgency
            .addCase(actionGetTransportAgencyDetail.pending, (state) => {
                state.transportAgencyDetailLoader = true;
            })
            .addCase(actionGetTransportAgencyDetail.fulfilled, (state, action) => {
                state.transportAgencyDetail = action.payload;
                state.transportAgencyDetailLoader = false;
            })
            .addCase(actionGetTransportAgencyDetail.rejected, (state) => {
                state.transportAgencyDetailLoader = false;
            })

            // Delete TransportAgency
            .addCase(actionDeleteTransportAgency.pending, (state) => {
                state.deleteTransportAgencyLoader = true;
            })
            .addCase(actionDeleteTransportAgency.fulfilled, (state, action) => {
                state.transportAgencies = state.transportAgencies.filter(transportAgency => transportAgency.id !== action.payload)
                state.deleteTransportAgencyLoader = false;
            })
            .addCase(actionDeleteTransportAgency.rejected, (state) => {
                state.deleteTransportAgencyLoader = false;
            })

            // Create TransportAgency
            .addCase(actionCreateTransportAgency.pending, (state) => {
                state.createTransportAgencyLoader = true;
            })
            .addCase(actionCreateTransportAgency.fulfilled, (state) => {
                state.createTransportAgencyLoader = false;
            })
            .addCase(actionCreateTransportAgency.rejected, (state) => {
                state.createTransportAgencyLoader = false;
            })

            // Update TransportAgency
            .addCase(actionUpdateTransportAgency.pending, (state) => {
                state.updateTransportAgencyLoader = true;
            })
            .addCase(actionUpdateTransportAgency.fulfilled, (state, action) => {
                state.updateTransportAgencyLoader = false;
            })
            .addCase(actionUpdateTransportAgency.rejected, (state) => {
                state.updateTransportAgencyLoader = false;
            })


    },
});
export default transportSlice.reducer;

export const { deleteSuccess } = transportSlice.actions;