import { createSlice } from "@reduxjs/toolkit";
import {
  getMachine,
  actionAddBags,
  getTempBags,
  actionAddBatch,
  actionDiscardBatch,
  actionDeleteBag,
  actionGetBatchs,
  actionGetBatchDetail,
  actionDownloadBags,
  actionGetBagDetail,
  actionGetTempBagDetail,
  actionUpdateBag,
  getSingleMachines,
  actionAddMachine,
  actionDeleteMachine,
  actionUpdateMachine,
  actionDownloadSingleBag,
  actionChangeBatchStatus,
} from "../services/lotService";

const lotSlice = createSlice({
  name: "lot",
  initialState: {
    machines: "",
    batchs: "",
    SingleBatch: "",
    singleBatchLoader: false,
    batchsCount: "",
    getMachinesLoader: false,
    addBagLoader: false,
    tempBagCount: "",
    getBagsLoader: false,
    addBatchLoader: false,
    discardBatchLoader: false,
    deleteBagLoader: false,
    getBatchsLoader: false,
    totalBags: "",
    downloadQrLoader: {},
    singleBagLoader: false,
    updateBagLoader: false,
    singleBag: false,
    getSinglemachines: "",
    machinesCount: "",
    getSingleMachinesLoader: false,
    addMachineLoader: false,
    updateMachineLoader: false,
    deleteMachineLoader: false,
    deleteSuccess: false,
    downloadSingleBagLoader: {},
  },
  reducers: {
    deleteSuccess: (state) => {
      state.deleteSuccess = true;
    },
  },
  extraReducers: (buider) => {
    buider
      // getBatchs state
      .addCase(actionGetBatchs.pending, (state) => {
        state.getBatchsLoader = true;
      })
      .addCase(actionGetBatchs.fulfilled, (state, action) => {
        state.batchs = action.payload?.data;
        state.batchsCount = action.payload?.total_records;
        state.getBatchsLoader = false;
      })

      .addCase(actionGetBatchs.rejected, (state) => {
        state.getBatchsLoader = false;
      })
      // getMachine State
      .addCase(getMachine.pending, (state) => {
        state.getMachinesLoader = true;
      })
      .addCase(getMachine.fulfilled, (state, action) => {
        state.machines = action.payload?.data;
        state.machinesCount = action.payload?.total_records;
        state.getMachinesLoader = false;
      })
      .addCase(getMachine.rejected, (state) => {
        state.getMachinesLoader = false;
      })
      // get Single Machine State
      .addCase(getSingleMachines.pending, (state) => {
        state.getSingleMachinesLoader = true;
      })
      .addCase(getSingleMachines.fulfilled, (state, action) => {
        state.getSinglemachines = action.payload;
        state.getSingleMachinesLoader = false;
      })
      .addCase(getSingleMachines.rejected, (state) => {
        state.getSingleMachinesLoader = false;
      })
      // add Machine State
      .addCase(actionAddMachine.pending, (state) => {
        state.addMachineLoader = true;
      })
      .addCase(actionAddMachine.fulfilled, (state, action) => {
        state.machines = [action.payload, ...state.machines];
        state.addMachineLoader = false;
      })
      .addCase(actionAddMachine.rejected, (state) => {
        state.addMachineLoader = false;
      })

      // Update Machine
      // Update Roles
      .addCase(actionUpdateMachine.pending, (state) => {
        state.updateMachineLoader = true;
      })
      .addCase(actionUpdateMachine.fulfilled, (state, action) => {
        state.machines = state.machines.map((d) =>
          d.id === action.payload?.id ? action.payload : d
        );
        state.updateMachineLoader = false;
      })
      .addCase(actionUpdateMachine.rejected, (state) => {
        state.updateMachineLoader = false;
      })

      // Delete Machine
      .addCase(actionDeleteMachine.pending, (state) => {
        state.deleteMachineLoader = true;
      })
      .addCase(actionDeleteMachine.fulfilled, (state, action) => {
        state.machines = state.machines.filter((d) => d.id !== action.payload);
        state.deleteMachineLoader = false;
      })
      .addCase(actionDeleteMachine.rejected, (state) => {
        state.deleteMachineLoader = false;
      })

      // actionAddBags State
      .addCase(actionAddBags.pending, (state) => {
        state.addBagLoader = true;
      })
      .addCase(actionAddBags.fulfilled, (state, action) => {
        state.totalBags = [action.payload, ...state.totalBags];
        state.tempBagCount = state.totalBags && state.totalBags.length;
        state.addBagLoader = false;
      })
      .addCase(actionAddBags.rejected, (state) => {
        state.addBagLoader = false;
      })

      // getBags State
      .addCase(getTempBags.pending, (state) => {
        state.getBagsLoader = true;
      })
      .addCase(getTempBags.fulfilled, (state, action) => {
        const { data, offset } = action.payload;
        if (offset === 0) {
          state.totalBags = data.data;
        } else {
          const currentTempBags = state.totalBags || [];
          const newTempBags = data.data || [];

          state.totalBags = [...currentTempBags, ...newTempBags];
        }
        state.tempBagCount = data?.total_records?.total_records;
        state.getBagsLoader = false;
      })
      .addCase(getTempBags.rejected, (state) => {
        state.getBagsLoader = false;
      })

      // Get Single Batch State
      .addCase(actionGetBatchDetail.pending, (state) => {
        state.singleBatchLoader = true;
      })
      .addCase(actionGetBatchDetail.fulfilled, (state, action) => {
        const { offset, data } = action.payload;

        if (offset === 0) {
          state.SingleBatch = data;
        } else {
          const currentBags = state.SingleBatch?.bags?.bags || [];
          const newBags = data?.bags?.bags || [];

          state.SingleBatch = {
            ...state.SingleBatch,
            bags: {
              bags: [...currentBags, ...newBags],
              total_bags: data?.bags?.total_bags || 0,
            },
          };
        }

        state.singleBatchLoader = false;
      })
      .addCase(actionGetBatchDetail.rejected, (state) => {
        state.singleBatchLoader = false;
      })

      // actionAddBatch State
      .addCase(actionAddBatch.pending, (state) => {
        state.addBatchLoader = true;
      })
      .addCase(actionAddBatch.fulfilled, (state, action) => {
        state.totalBags = [];
        state.addBatchLoader = false;
      })
      .addCase(actionAddBatch.rejected, (state) => {
        state.addBatchLoader = false;
      })

      // actionDiscardBatch State
      .addCase(actionDiscardBatch.pending, (state) => {
        state.discardBatchLoader = true;
      })
      .addCase(actionDiscardBatch.fulfilled, (state, action) => {
        state.totalBags = [];
        state.discardBatchLoader = false;
      })
      .addCase(actionDiscardBatch.rejected, (state) => {
        state.discardBatchLoader = false;
      })

      // actionDeleteBag State
      .addCase(actionDeleteBag.pending, (state) => {
        state.deleteBagLoader = true;
      })
      .addCase(actionDeleteBag.fulfilled, (state, action) => {
        state.totalBags = state.totalBags.filter(
          (bag) => bag.id !== action.payload
        );
        state.tempBagCount = state.tempBagCount - 1;
        state.deleteBagLoader = false;
      })
      .addCase(actionDeleteBag.rejected, (state) => {
        state.deleteBagLoader = false;
      })

      // Get Single Bag State
      .addCase(actionGetBagDetail.pending, (state) => {
        state.singleBagLoader = true;
      })
      .addCase(actionGetBagDetail.fulfilled, (state, action) => {
        state.singleBag = action.payload.data;
        state.singleBagLoader = false;
      })
      .addCase(actionGetBagDetail.rejected, (state) => {
        state.singleBagLoader = false;
      })

      // Update Bag
      .addCase(actionUpdateBag.pending, (state) => {
        state.updateBagLoader = true;
      })
      .addCase(actionUpdateBag.fulfilled, (state, action) => {
        state.updateBagLoader = false;
        state.singleBag = {
          ...state.singleBag,
          bags: {
            bags: state.singleBag?.bags?.bags.map((d) =>
              d.id === action.payload.id ? action.payload : d
            ),
          },
        };
      })
      .addCase(actionUpdateBag.rejected, (state) => {
        state.updateBagLoader = false;
      })

      // Get Temp Single Bag State
      .addCase(actionGetTempBagDetail.pending, (state) => {
        state.singleBagLoader = true;
      })
      .addCase(actionGetTempBagDetail.fulfilled, (state, action) => {
        state.singleBag = action.payload;
        state.singleBagLoader = false;
      })
      .addCase(actionGetTempBagDetail.rejected, (state) => {
        state.singleBagLoader = false;
      })

      // Download Bags
      .addCase(actionDownloadBags.pending, (state, action) => {
        console.log(action);
        state.downloadQrLoader[action?.meta?.arg?.id] = true;
      })
      .addCase(actionDownloadBags.fulfilled, (state, action) => {
        state.downloadQrLoader[action?.meta?.arg?.id] = false;
      })
      .addCase(actionDownloadBags.rejected, (state, action) => {
        state.downloadQrLoader[action?.meta?.arg?.id] = false;
      })
      // Download Single Bag
      .addCase(actionDownloadSingleBag.pending, (state, action) => {
        console.log(action?.meta?.arg?.batch_number);
        state.downloadSingleBagLoader[action?.meta?.arg?.batch_number] = true;
        state.SingleBatch = {
          ...state.SingleBatch,
          bags: {
            ...state.SingleBatch.bags,
            bags: state.SingleBatch.bags?.bags?.map((d) =>
              d.batch_number === action?.meta?.arg?.batch_number
                ? { ...d, print_count: d.print_count + 1 }
                : d
            ),
          },
        };
      })
      .addCase(actionDownloadSingleBag.fulfilled, (state, action) => {
        state.downloadSingleBagLoader[action?.meta?.arg?.batch_number] = false;
      })
      .addCase(actionDownloadSingleBag.rejected, (state, action) => {
        state.downloadSingleBagLoader[action?.meta?.arg?.batch_number] = false;
      })

      .addCase(actionChangeBatchStatus.pending, (state, action) => {
        state.changeStatusLoader = true;
      })
      .addCase(actionChangeBatchStatus.fulfilled, (state, action) => {
        state.batchs =
          state.batchs &&
          state.batchs.map((batch) =>
            batch.id === action.payload.id
              ? {
                  ...batch,
                  status: action.payload.status,
                }
              : batch
          );

        state.SingleBatch =
          state.SingleBatch && state.SingleBatch.id === action.payload.id
            ? {
                ...state.SingleBatch,
                status: action.payload.status,
              }
            : state.SingleBatch;

        state.changeStatusLoader = false;
      })
      .addCase(actionChangeBatchStatus.rejected, (state, action) => {
        state.changeStatusLoader = false;
      });
  },
});
export default lotSlice.reducer;
export const { deleteSuccess } = lotSlice.actions;
