
import React, { useEffect, useState } from 'react'
import usersvg from '../../assets/img/dashboard/user.svg';
import { Button, Cascader, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import SideCardHeader from '../common/SideCardHeader';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { actionGetGatepassDetail } from '../../store/services/gatepassService';
import { actionGetCompany } from '../../store/services/companyService';
import { actionGetTransportAgency } from '../../store/services/transportService';
import { useDispatch, useSelector } from 'react-redux';

const EditGatepass = () => {

    const [values, setValues] = useState([0, 0, 0, 0]);
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const gatepassData = useSelector(state => state.gatepass);
    const companies = useSelector(state => state.company)
    const agency = useSelector(state => state.transportAgency)

    useEffect(() => {
        if (id) {
            dispatch(actionGetGatepassDetail(id));
        }
    }, [id, dispatch]);

    const onChange = (val) => {
        console.log({ val });
    }
    useEffect(() => {
        if (!companies.getCompanyLoader && !agency.transportAgencyLoader) {
            dispatch(actionGetCompany({ limit: 10, offset: 0, getall: "YES" }))
            dispatch(actionGetTransportAgency({ limit: 10, offset: 0, getall: "YES" }))
        }
    }, [dispatch])
    const company = companies && companies.companies && companies.companies.length > 0 && companies.companies.map((d, i) => ({
        key: i,
        // value: d.id,     
        value: d.business_name,
        label: d.business_name,
    }))
    const trasportAgency = agency && agency.transportAgencies && agency.transportAgencies.length > 0 && agency.transportAgencies.map((d, i) => ({
        key: i,
        value: d.id,
        label: d.agency_name,
    }))

    useEffect(() => {
        if (gatepassData.gatepassDetail) {
            form.setFieldsValue({
                business_name: gatepassData?.gatepassDetail?.business_name,
                // date: gatepassData?.gatepassDetail?.date,
                invoice_number: gatepassData?.gatepassDetail?.invoice_number,
                lr_number: gatepassData?.gatepassDetail?.lr_number,
                transport_agency: gatepassData?.gatepassDetail?.transport_agency,
                driver_name: gatepassData?.gatepassDetail?.driver_name,
                contact_number: gatepassData?.gatepassDetail?.contact_number,
            })
        }
    }, [gatepassData.gatepassDetail])


    const countryOptions = [
        { value: '+1', label: 'United States (+1)' },
        { value: '+91', label: 'India (+91)' },
        { value: '+44', label: 'United Kingdom (+44)' },
    ];


    const handleIncrement = (index) => {
        const newValues = [...values];
        newValues[index] = newValues[index] + 1;
        setValues(newValues);
    };

    const handleDecrement = (index) => {
        if (values[index] > 0) {
            const newValues = [...values];
            newValues[index] = newValues[index] - 1;
            setValues(newValues);
        }
    };

    const onFinish = (values) => {
        console.log({ values });
    }
    return (
        // Optional Foooter so add new Structure 
        <Form layout='vertical'
            onFinish={onFinish} form={form} className='h-full relative max-h-screen gatepass-container overflow-hidden'>
            <div className='flex justify-center h-full '>
                <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>

                    <div className='border-b border-b-[#919da94d] flex items-center 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'>
                        <p className='font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>Edit Gatepass</p>
                    </div>
                    <div className=' scrollbar-container flex-1 overflow-auto px-6 py-4 xl:pb-20 2xl:pb-0  pb-20 '>

                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <div className='flex items-center gap-x-2 mb-4'>
                                    <img src={usersvg} alt="User Svg" />  <p className='2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary'>Company Details</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="company Name"
                                    name="business_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select company!',
                                        },
                                    ]}
                                >
                                    <Select allowClear
                                        showSearch
                                        loading={companies.getCompanyLoader}
                                        placeholder="Select company"
                                        optionFilterProp="children" className=''
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={company}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Date"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Date!',
                                        },
                                    ]}
                                >
                                    <DatePicker className='w-full ' onChange={onChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Invoice Number *"
                                    name="invoice_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Invoice Number!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Invoice Number' onChange={onChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="L R Number"
                                    name="lr_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Invoice Number!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter L R Number' onChange={onChange} />
                                </Form.Item>
                            </Col>
                            <Divider className='my-2' />
                            <Col span={24} >
                                <div className='flex items-center gap-x-2 mb-4'>
                                    <img src={usersvg} alt="User Svg" />  <p className='2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary'>Transportation Details</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Transporting Agency"
                                    name="transport_agency"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Agency!',
                                        },
                                    ]}
                                >
                                    <Select allowClear
                                        showSearch
                                        loading={agency.transportAgencyLoader}
                                        placeholder="Select Agency"
                                        optionFilterProp="children" className=''
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={trasportAgency}

                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} />
                            <Col span={12}>
                                <Form.Item
                                    label="Driver Name"
                                    name="driver_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Driver Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Driver Name' onChange={onChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Contact Number"
                                    name="contact_number"
                                    normalize={(value) =>
                                        value.replace(/[^0-9]/g, "").trim()
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Contact Number!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && (value.length !== 10)) {
                                                    return Promise.reject("Please Enter Valid Phone number!")
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}
                                >

                                    <Input controls={false} placeholder='Enter Contact Number' className='rounded-0 w-full border-0 country-input'
                                    // addonBefore={
                                    //     <Cascader open={false} allowClear={false} defaultValue={"+91"}
                                    //         options={countryOptions} />
                                    // }
                                    />

                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* SIDEBAR START HERE */}
                <div className='bg-main border-l  border-l-[#919da94d] w-[30%] flex flex-col '>
                    <SideCardHeader />
                    <div className='px-4 pt-5' id='custom-increment-input'>
                        <div className='flex items-center gap-x-2 mb-4'>
                            <img src={usersvg} alt="User Svg" />  <p className='2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary'>Carriage Details</p>
                        </div>
                    </div>
                    <div className='px-4'>
                        <p className='text-custom-gray custom-sixt mb-2'>Bag Count</p>
                        {[1, 2, 3, 4].map((grade, index) => (
                            <div key={index} className="flex ant-input items-center justify-between mb-5">
                                <p className='text-custom-gray custom-sixt'>{index === 3 ? 'Density : ' : `Grade ${grade} :`}</p>
                                <div className="flex items-center gap-3 ">
                                    <span onClick={() => handleDecrement(index)} className='px-2 text-primary cursor-pointer'><MinusOutlined /></span>
                                    <p className='px-2 text-primary w-10 text-center cursor-default'>{values[index]}</p>
                                    <span onClick={() => handleIncrement(index)} className='px-2 text-primary cursor-pointer' ><PlusOutlined /></span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className='footer z-0 bg-white sticky flex items-center px-4 gap-x-2 bottom-0 w-full border-t border-t-[rgba(145, 157, 169, 0.3)]  bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3'>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button htmlType='submit' className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Update Details</Button>
                </Form.Item>
                <NavLink to='/gatepass'><Button className='warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Cancel</Button></NavLink>
            </div>
            {/* Footer Section */}

        </Form >
    )
}

export default EditGatepass
