import { Button, Cascader, Col, Form, Input, Row } from 'antd';
import React from 'react';
import india from "../../assets/img/all/india.svg";
import truck from "../../assets/img/all/truck.svg";
import SideCardHeader from '../common/SideCardHeader';
import { useNavigate } from 'react-router-dom';
import { actionCreateTransportAgency } from '../../store/services/transportService';
import { useDispatch, useSelector } from 'react-redux';

const AddTransportingAgency = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const transportAgencyData = useSelector(state => state.transportAgency);

    const onChange = (val) => {
        console.log({ val });
    }
    console.log(transportAgencyData, "transportAgencyData")

    const countryOptions = [
        { value: '+1', label: 'United States (+1)' },
        { value: '+91', label: <span className='flex items-center justify-center'><img src={india} alt="India" className="mr-2" />+91</span> },
        { value: '+44', label: 'United Kingdom (+44)' },
    ];

    const onFinish = (request) => {
        dispatch(actionCreateTransportAgency({ request, navigate }));
    }



    return (
        // Optional Foooter so add new Structure 
        <Form layout='vertical'
            onFinish={onFinish} className='h-full relative max-h-screen gatepass-container overflow-hidden'>
            <div className='flex justify-center h-full '>
                <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>

                    <div className='border-b border-b-[#919da94d] flex items-center 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'>
                        <p className='font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>Create Transport Agency</p>
                    </div>
                    <div className=' scrollbar-container flex-1 overflow-auto px-6 py-4 xl:pb-20 2xl:pb-0  pb-20 '>

                        <Row gutter={[16, 8]}>
                            <Col span={24} >
                                <div className='flex items-center gap-x-2 mb-4'>
                                    <img src={truck} alt="Truck" /><p className='2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary'>Agency Details</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Agency Name"
                                    name="agency_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Agency Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Agency Name' onChange={onChange} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="GSTIN Number"
                                    name="gst_in"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter GSTIN No!',
                                        },
                                        {
                                            pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
                                            message: 'Please enter a valid GSTIN No!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter GSTIN No' onChange={onChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* SIDEBAR START HERE */}
                <div className='bg-main w-[30%] flex flex-col '>
                    <SideCardHeader />

                </div>
            </div>

            {/* Footer Section */}
            <div className='footer z-0 bg-white sticky flex items-center px-4 gap-x-2 bottom-0 w-full  border-t border-t-[rgba(145, 157, 169, 0.3)]  bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3'>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button htmlType='submit' loading={transportAgencyData && transportAgencyData.createTransportAgencyLoader} className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2'>Create Agency</Button>
                </Form.Item>
                <Button onClick={() => navigate(-1)} className='warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Discard Agency</Button>
            </div>
            {/* Footer Section */}

        </Form >
    )
}

export default AddTransportingAgency