import {
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Empty,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Segmented,
  Space,
  Spin,
  Table
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import searchImg from "../../assets/img/all/search.svg";
import user from "../../assets/img/dashboard/nophoto.png";
import {
  actionDeleteMember,
  actionGetMemberDetail,
  actionGetMembers,
} from "../../store/services/memberService";
import { actionGetRoles } from "../../store/services/rbacService";
import ChangePassword from "../auth/ChangePassword";
import SideCardHeader from "../common/SideCardHeader";

const SystemManagement = () => {
  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.member);
  const { roles } = useSelector((state) => state.rbac);
  const {
    members,
    memberCount,
    getMemberLoader,
    memberDetail,
    memberDetailLoader,
    deleteSuccess,
    deleteMemberLoader,
  } = memberData;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("all");
  const [selectedRowKey, setSelectedRowKey] = useState(0);
  const [staticId, setStaticId] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  useMemo(() => {
    const newData =
      members &&
      members.length &&
      members?.map((member, i) => ({
        key: i,
        num: (pagination.current - 1) * pagination.pageSize + i + 1,
        id: member.id,
        member_name: {
          name: `${member.first_name} ${member.last_name}`,
          image: member.profile_pic,
        },
        email: member.email,
        role:
          member.roles && member.roles.length > 0
            ? member.roles.map((data, index, array) => (
              <span key={index}>
                {data.name}
                {index < array.length - 1 && ", "}
              </span>
            ))
            : "-",
        username: member.username,
        mobile: member.phone,
        gender: member.gender,
      }));
    setData(newData);
    memberData && members && members.length > 0 && setStaticId(members[0].id);
  }, [memberData]);

  useEffect(() => {
    if (search || role) {
      dispatch(
        actionGetMembers({
          offset: 0,
          limit: 10,
          keyword: search,
          role: role === "all" ? "" : role ? role : "",
        })
      );
    } else {
      dispatch(
        actionGetMembers({
          offset: pagination.current - 1,
          limit: pagination.pageSize,
          keyword: search ? search : "",
          role: role === "all" ? "" : role ? role : "",
        })
      );
    }
  }, [dispatch, search, pagination, role]);

  useEffect(() => {
    dispatch(actionGetRoles({ limit: 10, offset: 0, getall: "YES" }));
  }, [dispatch]);

  useEffect(() => {
    staticId && dispatch(actionGetMemberDetail(staticId));
    setSelectedRowKey(0);
  }, [staticId]);


  // Manage Pagination Here
  const handleTableChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize,
      total: memberCount,
    });
  };

  const confirm = (id) => {
    dispatch(actionDeleteMember(id))
      .then((response) => {
        try {
          if (deleteSuccess) {
            const deletedRowIndex = members.findIndex((item) => item.id === id);
            const newStaticId =
              deletedRowIndex > 0 ? members[deletedRowIndex - 1].id : null;
            setSelectedRowKey(deletedRowIndex);
            dispatch(actionGetMemberDetail(newStaticId));
          } else {
            console.log("Error Occurs");
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSearch = (value) => {
    console.log(value);
    setSearch(value);
  };

  const getMemberDetailApiCall = (id) => {
    if (id) {
      dispatch(actionGetMemberDetail(id));
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "num",
      key: "num",
      render: (text) => <p>{text}</p>,
      // width: 80,
    },
    {
      title: "Member Name",
      dataIndex: "member_name",
      key: "member_name",
      // width: 200,
      render: (text) => {
        return (
          <div className="flex items-center gap-x-2 rounded-full   object-cover  bg-transparent  ">
            <div className="h-12 w-12 border border-primary rounded-full">
              <img
                src={(text && text.image) || user}
                className="w-full h-full rounded-full "
                alt={"User Img"}
              />
            </div>
            <p>{text?.name}</p>
          </div>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      ellipsis: true,
    },
    {
      title: "Mobile No",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      ellipsis: true,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      align: "center",
      width: 100,
      render: (item) => (
        <Space size="middle">
          <div>
            <Link
              to={`/edit-member/${item.id}`}
              className="flex text-primary hover:text-primary"
            >
              <EditOutlined className="action-icon" />
              <span>Edit</span>
            </Link>
          </div>

          <Popconfirm
            okButtonProps={{
              loading: deleteMemberLoader,
            }}
            placement="top"
            title="Are you sure you want to delete this User?"
            onConfirm={() => confirm(item.id)}
            okText="Yes"
            cancelText="No"
          >
            <div className="flex text-[#ff0000]">
              <DeleteOutlined className="action-icon" style={{ color: 'red', fontWeight: 400 }} />
              Delete
            </div>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const desitems = [
    {
      key: "1",
      label: "Username",
      children: memberDetail && memberDetail.username,
    },
    {
      key: "3",
      label: "Email Address",
      children: memberDetail && memberDetail.email,
    },
    {
      key: "4",
      label: "User ID",
      children: "#" + ((memberDetail && memberDetail.id) || ""), // Ensure memberDetail.id is defined
    },
    {
      key: "6",
      label: "Created At",
      children:
        memberDetail &&
        moment(memberDetail.created_at).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const getCertificaterName = (type) => {
    let name;
    switch (type) {
      case "Standard_sand_grade_I":
        name = "Grade-I";
        break;
      case "Standard_sand_grade_II":
        name = "Grade-II";
        break;
      case "Standard_sand_grade_III":
        name = "Grade-III";
        break;
      case "Standard_Density_sand_IV":
        name = "Density Sand";
        break;
      case "Bsen196_iso679_V":
        name = "BSEN 196";
        break;
      case "Astm_c778_grade_sand_VI":
        name = "ASTM C-778";
        break;
      case "Astm_20_30_sand_VII":
        name = "ASTM 20/30";
        break;
      case "Bs_1377_density_sand_VIII":
        name = "BS 1377";
        break;
      case "Astm_d1556m_15_density_sand_IX":
        name = "ASTM D1556";
        break;
    }
    return name;
  };

  return (
    <div className="flex justify-center h-full max-h-screen gatepass-container">
      <div className="bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden">
        <Row
          justify={"center"}
          align={"middle"}
          className="border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]"
        >
          <Col span={6}>
            <p className="font-GlegooBold whitespace-nowrap text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5">
              System Management
            </p>
          </Col>
          <Col span={12} xxl={12} lg={18} sm={18} xl={18} align="end">
            <div className="mr-5 w-1/2 ml-auto">
              <Input
                onChange={(e) => handleSearch(e.target.value)}
                className="custom-search"
                placeholder="Search Member"
                suffix={<img src={searchImg} alt="search" />}
              />
            </div>
          </Col>
        </Row>
        <Row className="border-b border-b-[rgba(145, 157, 169, 0.3)] overflow-x-auto scrollbar-container py-3">
          <Col xxl={15} xl={24} lg={24} sm={24} className="px-6  ">
            {/* <Segmented className='Hrmodule-segment bg-main' */}
            <Segmented
              className="custom-segment bg-main capitalize"
              onChange={setRole}
              options={[
                { label: "All", value: "" },
                ...((roles &&
                  roles.length &&
                  roles.map((d) => ({ label: d.name, value: d.id }))) ||
                  []),
              ]}
            />
          </Col>
        </Row>
        <div className="flex-1 overflow-hidden px-4 ">{/* scrollbar-container */}
          <div className="px-3 overflow-hidden">
            <Table className="table-scrollbar" sticky={true}
              columns={columns}
              pagination={false}
              loading={getMemberLoader}
              scroll={{
                x: "max-content",
                y: window.innerWidth < 1500 ? "55vh" : "65vh",
              }}
              dataSource={data}
              rowClassName={(record) =>
                record.key === selectedRowKey ? "selected-row" : ""
              }
              onRow={(record) => ({
                onClick: () => {
                  if (record.key !== selectedRowKey) {
                    getMemberDetailApiCall(record.id);
                    setSelectedRowKey(record.key);
                  }
                },
              })}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between  2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] bg-transparent border-t border-t-custom-gray border-opacity-[30%] px-5"
          align={"middle"}
        >
          <NavLink to="/add-member">
            {" "}
            <Button className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]">
              Add New Member
            </Button>
          </NavLink>

          <div className="flex justify-end" id="custom-pagination">
            <Pagination
              className="flex items-center"
              {...pagination}
              showSizeChanger
              showQuickJumper
              hideOnSinglePage
              pageSizeOptions={["10", "20", "30"]}
              total={memberCount}
              onChange={(page, pageSize) => handleTableChange(page, pageSize)}
            />
          </div>
        </div>
      </div>

      {/* SIDEBAR START HERE */}
      <div className="bg-[#FFFFFF] border-l border-l-[#919da94d] w-[30%] flex flex-col ">
        <SideCardHeader />
        {memberCount ? (
          <>
            <div className="overflow-auto custom-scrollbar  px-1 flex-1 py-1">
              <Spin spinning={memberDetailLoader}>
                <div className="px-4">
                  <div className=" mx-auto flex flex-col items-center justify-center w-full  py-4 border-opacity-[30%] border-custom-gray">
                    <img
                      src={
                        memberData && memberDetail && memberDetail.profile_pic
                      }
                      alt="Url"
                      width={95}
                      height={95}
                      className="rounded-[15px]"
                    />
                    <p className="text-primary 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold   lg:text-[14px] font-bold">
                      {memberData &&
                        memberDetail &&
                        memberDetail.first_name + " " + memberDetail.last_name}
                    </p>
                    <p className="text-[#919DA9] 2xl:text-[10px] xl:text-[10px] font-gilroyMedium lg:text-[10px] text-[10px]">
                      {memberData &&
                        memberDetail &&
                        memberData?.memberDetail.roles &&
                        memberData?.memberDetail.roles.length
                        ? memberData?.memberDetail.roles.map(
                          (data, index, array) => (
                            <span key={index}>
                              {data.name}
                              {index < array.length - 1 && ", "}
                            </span>
                          )
                        )
                        : "-"}
                    </p>

                    <Divider />

                    {showChangePassword ? (
                      // Render Change Password component here
                      <ChangePassword
                        setShowChangePassword={setShowChangePassword}
                        id={memberData && memberDetail && memberDetail.id}
                      />
                    ) : (
                      <>
                        <Descriptions
                          column={2}
                          layout="vertical"
                          id="gatepass-description"
                          className="mt-4"
                          items={desitems}
                        />
                        <Divider className="mb-3" />
                        <p className="text-[14px] text-primary">
                          {" "}
                          Additional Details
                        </p>
                        <Divider className="mb-0 mt-3" />
                        <div className="grid grid-cols-2 grid-flow-col w-full">
                          <div>
                            <p className="text-[12px] text-custom-gray mt-4 mb-1">
                              Contact Number
                            </p>
                            <p className="text-primary custom-fourt">
                              {memberData &&
                                memberDetail &&
                                memberData?.memberDetail.phone}
                            </p>
                          </div>
                          <div>
                            <p className="text-[12px] text-custom-gray mt-4 mb-1">
                              Date of Birth
                            </p>
                            <p className="text-primary custom-fourt">
                              {memberData &&
                                memberDetail &&
                                memberData?.memberDetail.date_of_birth
                                ? moment(
                                  memberData?.memberDetail.date_of_birth
                                ).format("DD MMM YYYY")
                                : "-"}
                            </p>
                          </div>
                        </div>
                        {memberDetail &&
                          memberDetail?.certificates &&
                          memberDetail?.certificates.length > 0 && (
                            <div className=" w-full">
                              <p className="text-[12px] text-custom-gray mt-4 mb-1">
                                Certificate Access
                              </p>
                              <div className="grid grid-cols-2">
                                {memberDetail?.certificates.map((data) => {
                                  return (
                                    <p
                                      key={data.id}
                                      className="text-primary custom-fourt mb-1"
                                    >
                                      {getCertificaterName(data.name)}
                                    </p>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </Spin>
            </div>
            <div className=" bg-white relative gap-x-2 flex z-50 justify-evenly  px-3 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  border-t border-t-custom-gray border-opacity-[30%] 2xl:py-[17px] xl:py-3 py-3">
              <Link
                to={`/edit-member/${memberData && memberDetail && memberDetail.id
                  }`}
                className={
                  "w-1/2 text-center flex items-center justify-center filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] px-5"
                }
              >
                Edit Details
              </Link>
              <Button
                onClick={() => setShowChangePassword(true)}
                className="outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] w-1/2"
              >
                Change Password
              </Button>
            </div>
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="pt-10 flex items-center justify-center flex-col"
          />
        )}
      </div>
    </div>
  );
};

export default SystemManagement;
