import { createSlice } from "@reduxjs/toolkit";
import {
  actionCreateDispatch,
  actionDeleteDispatch,
  actionGetDispatchDetail,
  actionUpdateDispatch,
  actionGetDispatches,
  actionDeleteDispatchBag,
  actionAddDispatchBag,
  actionUpdateDispatchStatus,
} from "../services/dispatchService";

const dispatchSlice = createSlice({
  name: "dispatch",
  initialState: {
    dispatches: "",
    dispatchLoader: false,
    dispatchCount: "",
    dispatchDetail: "",
    dispatchDetailLoader: false,
    deletedispatchLoader: false,
    deleteSuccess: false,
    CreatedispatchLoader: false,
    updatedispatchLoader: false,
    deletedispatchBagLoader: {},
    addBagLoader: false,
    updateDispatchStatusLoader: false,
  },
  reducers: {
    deleteSuccess: (state) => {
      state.deleteSuccess = true;
    },
  },
  extraReducers: (buider) => {
    buider
      // Get transportAgency all
      .addCase(actionGetDispatches.pending, (state) => {
        state.dispatchLoader = true;
      })
      .addCase(actionGetDispatches.fulfilled, (state, action) => {
        state.dispatches = action?.payload?.data;
        state.dispatchCount = action?.payload?.total_records;
        state.dispatchLoader = false;
      })
      .addCase(actionGetDispatches.rejected, (state) => {
        state.dispatchLoader = false;
      })

      // Get Single TransportAgency
      .addCase(actionGetDispatchDetail.pending, (state) => {
        state.dispatchDetailLoader = true;
      })
      .addCase(actionGetDispatchDetail.fulfilled, (state, action) => {
        state.dispatchDetail = action.payload;
        state.dispatchDetailLoader = false;
      })
      .addCase(actionGetDispatchDetail.rejected, (state) => {
        state.dispatchDetailLoader = false;
      })

      // Delete TransportAgency
      .addCase(actionDeleteDispatch.pending, (state) => {
        state.deletedispatchLoader = true;
      })
      .addCase(actionDeleteDispatch.fulfilled, (state, action) => {
        state.dispatches = state.dispatches.filter(
          (d) => d.id !== action.payload
        );
        state.deletedispatchLoader = false;
      })
      .addCase(actionDeleteDispatch.rejected, (state) => {
        state.deletedispatchLoader = false;
      })

      // Create TransportAgency
      .addCase(actionCreateDispatch.pending, (state) => {
        state.CreatedispatchLoader = true;
      })
      .addCase(actionCreateDispatch.fulfilled, (state) => {
        state.CreatedispatchLoader = false;
      })
      .addCase(actionCreateDispatch.rejected, (state) => {
        state.CreatedispatchLoader = false;
      })

      // Update TransportAgency
      .addCase(actionUpdateDispatch.pending, (state) => {
        state.updatedispatchLoader = true;
      })
      .addCase(actionUpdateDispatch.fulfilled, (state, action) => {
        state.updatedispatchLoader = false;
      })
      .addCase(actionUpdateDispatch.rejected, (state) => {
        state.updatedispatchLoader = false;
      })

      .addCase(actionDeleteDispatchBag.pending, (state, action) => {
        state.deletedispatchBagLoader[action.payload] = true;
      })
      .addCase(actionDeleteDispatchBag.fulfilled, (state, action) => {
        state.dispatchDetail = {
          ...state.dispatchDetail,
          bags: state?.dispatchDetail?.bags.filter(
            (bag) => bag.id !== action.payload
          ),
        };

        state.deletedispatchBagLoader[action.payload] = true;
      })
      .addCase(actionDeleteDispatchBag.rejected, (state, action) => {
        state.deletedispatchBagLoader[action.payload] = true;
      })
      // Add Bags
      .addCase(actionAddDispatchBag.pending, (state, action) => {
        state.addBagLoader = true;
      })
      .addCase(actionAddDispatchBag.fulfilled, (state, action) => {
        const bagNew = {
          id: action.payload?.id,
          batch_number: action.payload?.batch_number,
          type: action.payload?.type,
        };
        state.dispatchDetail = {
          ...state.dispatchDetail,
          bags: [...state.dispatchDetail.bags, bagNew],
        };
        state.addBagLoader = false;
      })
      .addCase(actionAddDispatchBag.rejected, (state, action) => {
        state.deletedispatchBagLoader[action.payload] = true;
      })

      // Update Dispatch StatusS
      .addCase(actionUpdateDispatchStatus.pending, (state) => {
        state.updateDispatchStatusLoader = true;
      })
      .addCase(actionUpdateDispatchStatus.fulfilled, (state, action) => {
        state.dispatches = state.dispatches.map((d) =>
          d.id == action.payload?.id ? action.payload : d
        );
        state.updateDispatchStatusLoader = false;
      })
      .addCase(actionUpdateDispatchStatus.rejected, (state) => {
        state.updateDispatchStatusLoader = false;
      });
  },
});
export default dispatchSlice.reducer;

export const { deleteSuccess } = dispatchSlice.actions;
