import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import * as moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import plus from '../../assets/img/all/plus.svg';
import usersvg from '../../assets/img/dashboard/user.svg';
import truck from "../../assets/img/all/truck.svg";
import { actionGetCompany } from '../../store/services/companyService';
import { actionGetDispatchDetail, actionUpdateDispatch } from '../../store/services/dispatchService';
import { actionGetTransportAgency } from '../../store/services/transportService';
import SideCardHeader from '../common/SideCardHeader';
import { actionGetMembers } from '../../store/services/memberService';
import dayjs from 'dayjs';

const EditDispatch = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { Id } = useParams();
    const companies = useSelector(state => state.company)
    const agency = useSelector(state => state.transportAgency)
    const dispatchData = useSelector(state => state.dispatch);
    const usersData = useSelector(state => state.member)

    const onFinish = (values) => {
        const req = {
            ...values,
            date: moment(values.date).format("YYYY-MM-DD")
        }
        dispatch(actionUpdateDispatch({ id: Id, req, navigate }))
    }

    useEffect(() => {
        if (!companies.getCompanyLoader && !agency.transportAgencyLoader) {
            dispatch(actionGetCompany({ limit: 10, offset: 0, getall: "YES" }))
            dispatch(actionGetTransportAgency({ limit: 10, offset: 0, getall: "YES" }))
        }
        dispatch(actionGetMembers({
            offset: 0,
            limit: 10,
            getall: "YES"
        }))
    }, [dispatch]);

    useEffect(() => {
        if (Id) {
            dispatch(actionGetDispatchDetail(Id))
        }
    }, [Id]);

    useEffect(() => {
        if (dispatchData && dispatchData.dispatchDetail) {
            form.setFieldsValue({
                company: dispatchData?.dispatchDetail?.company?.id,
                date: dispatchData?.dispatchDetail?.date ? dayjs(dispatchData?.dispatchDetail?.date) : "",
                invoice_number: dispatchData?.dispatchDetail?.invoice_number,
                delivery_place: dispatchData?.dispatchDetail?.delivery_place,
                transport_agency: dispatchData?.dispatchDetail?.transport_agency?.id,
                user: dispatchData?.dispatchDetail?.user?.id,
                driver_name: dispatchData?.dispatchDetail?.driver_name,
                driver_contact_number: dispatchData?.dispatchDetail?.driver_contact_number,
                vehicle_no: dispatchData?.dispatchDetail?.vehicle_no,

            })
        }
    }, [dispatchData.dispatchDetail])


    const company = companies && companies.companies && companies.companies.length > 0 && companies.companies.map((d, i) => ({
        key: i,
        value: d.id,
        label: d.business_name,
    }))
    const trasportAgency = agency && agency.transportAgencies && agency.transportAgencies.length > 0 && agency.transportAgencies.map((d, i) => ({
        key: i,
        value: d.id,
        label: d.agency_name,
    }));

    const users = usersData && usersData.members && usersData.members.length > 0 && usersData.members.map((d, i) => ({
        key: i,
        value: d.id,
        label: d.first_name + " " + d.last_name,
    }))


    return (
        // Optional Foooter so add new Structure 
        <Form layout='vertical' form={form}
            onFinish={onFinish} className='h-full relative max-h-screen gatepass-container overflow-hidden'>
            <div className='flex justify-center h-full '>
                <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>

                    <div className='border-b border-b-[#919da94d] flex items-center 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'>
                        <p className='font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>Update Dispatch</p>
                    </div>
                    <div className=' scrollbar-container flex-1 overflow-auto px-6 py-4 xl:pb-20 2xl:pb-0  pb-20 '>

                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <div className='flex items-center gap-x-2 mb-4'>
                                    <img src={truck} alt="truck" />  <p className='2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary'>Order Details</p>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div className='flex items-center justify-between '>

                                    <span className='mb-2 ml-2  text-[#919da9] text-[16px]'>Company Name</span>
                                    <Link to="/add-company">
                                        <div className='flex gap-x-2 items-center'>
                                            <img src={plus} alt="Plus" />
                                            <span className='ml-auto text-[#FF5A26] text-[14px]'>Add Company</span>
                                        </div>
                                    </Link>
                                </div>
                                <Form.Item
                                    // label="Company Name"
                                    name="company"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Company Name !',
                                        },
                                    ]}
                                >
                                    <Select allowClear loading={companies.getCompanyLoader} disabled={companies.getCompanyLoader}
                                        showSearch
                                        placeholder="Select Company"
                                        optionFilterProp="children" className=''
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={company}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Dispatch Date"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Dispatch Date!',
                                        },
                                    ]}
                                >
                                    <DatePicker placeholder='Select date' className='w-full' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Invoice Number"
                                    name="invoice_number"
                                    /*    normalize={(value) =>
                                           value.replace(/[^0-9]/g, "").trim()
                                       } */
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Invoice Number!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Invoice Number' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Delivery Address"
                                    name="delivery_place"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Delivery Address!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Enter Delivery Address' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <div className='flex items-center justify-between '>

                                    <span className='mb-2 ml-2  text-[#919da9] text-[16px]'>Transport Agency</span>
                                    <Link to="/add-transport-agency">
                                        <div className='flex gap-x-2 items-center'>
                                            <img src={plus} alt="Plus" />
                                            <span className='ml-auto text-[#FF5A26] text-[14px]'>Add Transport Agency</span>
                                        </div>
                                    </Link>
                                </div>
                                <Form.Item
                                    //label="Transporting Agency"
                                    name="transport_agency"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Transport Agency!',
                                        },
                                    ]}
                                >
                                    <Select allowClear loading={agency.transportAgencyLoader} disabled={agency.transportAgencyLoader}
                                        showSearch
                                        placeholder="Select Agency"
                                        optionFilterProp="children" className=''
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={trasportAgency}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <div className='flex items-center justify-between '>

                                    <span className='mb-2 ml-2  text-[#919da9] text-[16px]'>User ( Select who scans the bag )</span><Link to="/system-management">
                                    </Link>
                                </div>
                                <Form.Item
                                    name="user"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please Select User!',
                                        },
                                    ]}
                                >
                                    <Select allowClear
                                        showSearch loading={users.getMemberLoader} disabled={users.getMemberLoader}
                                        placeholder="Select User"
                                        optionFilterProp="children" className=''
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={users}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* SIDEBAR START HERE */}
                <div className='bg-main border-l  border-l-[#919da94d] w-[30%] flex flex-col '>
                    <SideCardHeader />
                    <div className="overflow-auto custom-scrollbar  px-1 flex-1 py-1 2xl:pb-0 xl:pb-14">
                        <div className="px-4 pt-5" id="custom-increment-input">
                            <div className="flex items-center gap-x-2 mb-4">
                                <img src={usersvg} alt="User Svg" />{" "}
                                <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                                    Driver Details
                                </p>
                            </div>
                        </div>
                        <Row className="px-4" gutter={[16, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Driver Name"
                                    name="driver_name"
                                    normalize={(value) => value.replace(/[^a-zA-Z\s]/g, "")}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Driver Name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Driver Name" />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Driver Contact Number"
                                    name="driver_contact_number"
                                    normalize={(value) => value.replace(/[^0-9]/g, "").trim()}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Driver Contact Number!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && value.length !== 10) {
                                                    return Promise.reject(
                                                        "Please enter a valid 10-digit mobile number! "
                                                    );
                                                }

                                                // You can add additional conditions if needed

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        controls={false}
                                        placeholder="Enter Driver Contact Number"
                                        className="rounded-0 w-full border-0 country-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Vehicle No."
                                    name="vehicle_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Vehicle No.!",
                                        },
                                        /* {
                                            pattern: /^[A-Z]{2}\s?[0-9]{2}\s?[A-Z]{1,2}\s?[0-9]{1,4}$/,
                                            message: 'Please enter a valid Vehicle No.!',
                                        }, */
                                    ]}
                                >
                                    <Input placeholder="Enter Vehicle No." />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div >

            {/* Footer Section */}
            <div className='footer z-0 bg-white sticky flex items-center px-4 gap-x-2 bottom-0 w-full  border-t border-t-[rgba(145, 157, 169, 0.3)]  bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3' >
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button loading={dispatchData.CreatedispatchLoader} htmlType='submit' className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Update Dispatch</Button>
                </Form.Item>
                <Button onClick={() => navigate(-1)} className='warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Discard Dispatch</Button>
            </div>
            {/* Footer Section */}

        </Form >
    )
}

export default EditDispatch