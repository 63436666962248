import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web.config";
import { deleteSuccess } from "../slices/transportSlice";

// Action to get all TransportAgency
export const actionGetTransportAgency = createAsyncThunk("actionGetTransportAgency", async ({ limit, offset, keyword, getall }) => {
    try {
        const res = await axios.get(`${BASE_URL}/trasport-agencies?limit=${limit}&offset=${offset}${keyword ? "&keyword=" + keyword : ""}${getall ? "&getall=" + getall : ""}&order=DESC`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return res.data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});

//Get TransportAgency Detail
export const actionGetTransportAgencyDetail = createAsyncThunk('actionGetTransportAgencyDetail', async (id) => {
    try {
        const res = await axios.get(`${BASE_URL}/trasport-agencies/${id}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

// Delete TransportAgency
export const actionDeleteTransportAgency = createAsyncThunk('actionDeleteTransportAgency', async (id, { dispatch }) => {
    try {
        const res = await axios.delete(`${BASE_URL}/trasport-agencies/${id}`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            dispatch(deleteSuccess());
            return id;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Create TransportAgency
export const actionCreateTransportAgency = createAsyncThunk('actionCreateTransportAgency', async (data) => {
    const { request, navigate } = data;
    try {
        const res = await axios.post(`${BASE_URL}/trasport-agencies`, request);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/transport-agencies');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})

//Update TransportAgency
export const actionUpdateTransportAgency = createAsyncThunk('actionUpdateTransportAgency', async (data) => {
    const { request, id, navigate } = data;
    try {
        const res = await axios.patch(`${BASE_URL}/trasport-agencies/${id}`, request);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/transport-agencies');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})