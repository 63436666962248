import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web.config";
import { deleteSuccess } from "../slices/rbacSlice";

// Add Roles
export const actionAddRoles = createAsyncThunk(
  "actionAddRoles",
  async (data) => {
    const { val, setModalOpen, form, setRoleId } = data;
    try {
      // Type Check
      const res = await axios.post(`${BASE_URL}/role`, val);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        setModalOpen && setModalOpen(false);
        form && form.resetFields();
        setRoleId && setRoleId("");
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

export const actionUpdateRoles = createAsyncThunk(
  "actionUpdateRoles",
  async (data) => {
    const { val, setModalOpen, form, roleId } = data;
    try {
      // Type Check
      const res = await axios.put(`${BASE_URL}/role/${roleId}`, val);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);

        setModalOpen && setModalOpen(false);
        form && form.resetFields();
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Get Roles
export const actionGetRoles = createAsyncThunk(
  "actionGetRoles",
  async ({ limit, offset, keyword, getall }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/role?limit=${limit}&offset=${offset}&order=DESC${
          keyword ? "&keyword=" + keyword : ""
        }${getall ? "&getall=" + getall : ""}`
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        return res?.data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Get Single  Role
export const actionGetRolesDetail = createAsyncThunk(
  "actionGetRolesDetail",
  async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}/role/${id}`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Delete Role
export const actionDeleteRole = createAsyncThunk(
  "actionDeleteRole",
  async (id, { dispatch }) => {
    try {
      const res = await axios.delete(`${BASE_URL}/role/${id}`);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        dispatch(deleteSuccess());
        return id;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

//Get Permissions
export const actiongetPermission = createAsyncThunk(
  "actiongetPermission",
  async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/permission?limit=20&offset=0&getall=YES&order=ASC`
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        return res.data;
      } else if (parseInt(status) === 404) {
        return [];
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Assign Role
/* export const actionAssignRole = createAsyncThunk('actionAssignRole', async ({ req }) => {
    try {
        const res = await axios.post(`${BASE_URL}/permission/assign-role`, req);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            //  dispatch(actionGetRolesDetail(req.role))
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
}) */

export const actionAssignRole = createAsyncThunk(
  "actionAssignRole",
  async ({ req /* , setTargetKeys, nextTargetKeys  */ }, { dispatch }) => {
    try {
      // console.log({ nextTargetKeys, setTargetKeys });
      const res = await axios.post(`${BASE_URL}/permission/assign-role`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        //  dispatch(actionGetRolesDetail(req.role))
        // setTargetKeys && setTargetKeys(nextTargetKeys);
        console.log("from service");
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Delete Role
export const actionDeleteAssignRole = createAsyncThunk(
  "deleteAssignRole",
  async ({ moveKeys, fullObjects, Id }) => {
    try {
      const req = {
        role: parseInt(Id),
        permissions: moveKeys,
      };
      const res = await axios.post(
        `${BASE_URL}/permission/delete-assigned-role`,
        req
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        return { fullObjects, moveKeys, Id };
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);
