import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import setAuthToken from "../../components/config/setAuthToken";
import { BASE_URL } from "../../components/config/web.config";
import { handleGetResponse, handlePostResponse } from "./commonService";

// Login Api
export const actionLogin = createAsyncThunk(
  "actionLogin",
  async (data, { dispatch, rejectWithValue }) => {
    const { values, navigate, checked } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/sign-in`, values);
      const { status, message: customMsg, data: responseData } = res.data;

      if (parseInt(status) === 200) {
        const { roles } = responseData;
        const isAdmin =
          roles &&
          roles.length > 0 &&
          roles.some((role) => role.name == "admin");
        if (isAdmin) {
          message.success(customMsg, 5);
          setAuthToken(responseData.accessToken);
          dispatch(authMe());
          navigate && navigate(`/`);
          return responseData;
        } else {
          message.error("Only Admin Can Login This Portal", 5);
        }
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
      throw rejectWithValue(error?.message);
    }
  }
);

// Auth me
export const authMe = createAsyncThunk("authMe", async () => {
  try {
    const res = await axios.get(`${BASE_URL}/auth/me`);
    const { status, message: customMsg, data } = res.data;
    if (parseInt(status) === 200) {
      return data;
    } else if (parseInt(status) === 401) {
      localStorage.removeItem("vytechQmsBackendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
      message.error(customMsg, 5);
    } else {
      message.error(customMsg, 5);
      throw new Error(`API Error: ${customMsg}`);
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("vytechQmsBackendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
      message.error(error?.message, 5);
    }
  }
});

// Forgot Password
export const actionForgotPassword = createAsyncThunk(
  "actionForgotPassword",
  async (data) => {
    const { values, navigate } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/forgot-password`, values);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Reset Password
export const actionResetPassword = createAsyncThunk(
  "actionResetPassword",
  async (data) => {
    const { req, navigate } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/reset-password`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Logout User
export const actionLogout = createAsyncThunk(
  "actionLogout",
  async (navigate) => {
    // Remove token from localStorage
    localStorage.removeItem("vytechQmsBackendJwtToken");
    setAuthToken(false);
    navigate("/login");
  }
);

export const actionChangePassword = createAsyncThunk(
  "actionChangePassword",
  async (data) => {
    const { id, password, form, setShowChangePassword } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/change-password/${id}`, {
        password: password,
      });
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        form && form.resetFields();
        setShowChangePassword && setShowChangePassword(false);
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "getDashboardData",
  async (data) => {
    const { filterValue } = data;
    try {
      const res = await axios.get(
        `${BASE_URL}/dashboard?production_filter_data=${filterValue}`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (err) {
      console.log(err?.response?.data?.message);
    }
  }
);
