import {
  DeleteOutlined,
  EditOutlined,
  AppstoreAddOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  List,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import searchImg from "../../assets/img/all/search.svg";
import iconDimond from "../../assets/img/rbac/dimond.svg";
import {
  actionAddRoles,
  actionDeleteRole,
  actionGetRolesDetail,
  actionUpdateRoles,
  actionGetRoles,
} from "../../store/services/rbacService";
import SideCardHeader from "../common/SideCardHeader";

const Roles = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const roleData = useSelector((state) => state.rbac);
  const {
    roles,
    getSingleRole,
    rolesCount,
    getSingleRolesLoader,
    deleteMemberLoader,
    deleteSuccess,
    addRoleLoader,
    updateRoleLoader,
  } = roleData;

  const [modalOpen, setModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [roleId, setRoleId] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(0);
  const [staticId, setStaticId] = useState();
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (keyword) {
      dispatch(
        actionGetRoles({
          limit: 10,
          offset: 0,
          keyword: keyword ? keyword : "",
        })
      );
    } else {
      dispatch(
        actionGetRoles({
          limit: pagination.pageSize,
          offset: pagination.current - 1,
          keyword: keyword ? keyword : "",
        })
      );
    }
  }, [dispatch, keyword, pagination]);

  useMemo(() => {
    const newData =
      roles &&
      roles.length &&
      roles?.map((d, i) => ({
        key: i,
        num: (pagination.current - 1) * pagination.pageSize + i + 1,
        id: d.id,
        name: <p className="capitalize">{d.name}</p>,
        role: d.id,
      }));
    setData(newData);
    roles && roles.length && setStaticId(roles[0]?.id);
  }, [roleData]);

  const handleUpdateRole = (id) => {
    console.log(id);
    dispatch(actionGetRolesDetail(id));
    setModalOpen(true);
    setRoleId(id);
  };

  useEffect(() => {
    if (roleId) {
      form.setFieldsValue({ name: getSingleRole?.name });
    }
  }, [getSingleRole]);

  useEffect(() => {
    staticId && dispatch(actionGetRolesDetail(staticId));
  }, [staticId]);

  // Delete API
  const confirm = (id) => {
    dispatch(actionDeleteRole(id))
      .then((response) => {
        try {
          if (roleData.deleteSuccess) {
            const deletedRowIndex = roles?.findIndex((item) => item.id === id);
            const newStaticId =
              deletedRowIndex > 0 ? roles[deletedRowIndex - 1].id : null;
            setSelectedRowKey(deletedRowIndex - 1);
            dispatch(actionGetRolesDetail(newStaticId));
          } else {
            console.log("Error Occurs");
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Manage Pagination Here
  const handleTableChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize,
      total: rolesCount,
    });
  };

  useEffect(() => {
    if (roleId) {
      dispatch(actionGetRolesDetail(roleId));
    }
  }, [roleId]);

  const columns = [
    {
      title: "#",
      dataIndex: "num",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      // width: 150,
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   align: "right",
    //   render: (item) => {
    //     const roleId = item.id;

    //     return (
    //       roleId !== 1 && (
    //         <Dropdown
    //           placement="bottom"
    //           menu={{
    //             items: [
    //               {
    //                 label: (
    //                   <>
    //                     <Link
    //                       to={`/view-role/${item.id}`}
    //                       className="flex text-primary hover:text-primary"
    //                     >
    //                       <AppstoreAddOutlined className="action-icon" />
    //                       <span>Modify Permission</span>
    //                     </Link>
    //                   </>
    //                 ),
    //               },
    //               {
    //                 label: (
    //                   <>
    //                     <a
    //                       onClick={() => handleUpdateRole(item.id)}
    //                       className="flex text-primary hover:text-primary"
    //                     >
    //                       <EditOutlined className="action-icon" />
    //                       <span>Edit Role</span>
    //                     </a>
    //                   </>
    //                 ),
    //               },
    //               {
    //                 label: (
    //                   <Popconfirm
    //                     okButtonProps={{
    //                       loading: roleData.deleteMemberLoader,
    //                     }}
    //                     placement="topLeft"
    //                     title="Are you sure you want to delete this User?"
    //                     onConfirm={() => confirm(item.id)}
    //                     okText="Yes"
    //                     cancelText="No"
    //                   >
    //                     <div className="flex text-primary">
    //                       <DeleteOutlined className="action-icon" />
    //                       Delete Role
    //                     </div>
    //                   </Popconfirm>
    //                 ),
    //               },
    //             ],
    //           }}
    //         >
    //           <EllipsisOutlined className="text-xl text-primary cursor-pointer" />
    //         </Dropdown>
    //       )
    //     );
    //   },
    // },
  ];

  const onFinish = (val) => {
    roleId
      ? dispatch(
          actionUpdateRoles({ val, navigate, setModalOpen, form, roleId })
        )
      : dispatch(
          actionAddRoles({ val, navigate, setModalOpen, form, setRoleId })
        );
  };

  const onFinishFailed = (err) => {
    console.log({ err });
  };

  return (
    <div className="flex justify-center h-full max-h-screen gatepass-container">
      <div className="bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden">
        <Row
          justify={"center"}
          align={"middle"}
          className="border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]"
        >
          <Col span={6}>
            <p className="font-GlegooBold whitespace-nowrap text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5">
              Roles
            </p>
          </Col>
          <Col span={12} xxl={12} lg={18} sm={18} xl={18} align="end">
            <div className="mr-5 w-1/2 ml-auto">
              <Input
                onChange={(e) => setKeyword(e.target.value)}
                className="custom-search"
                placeholder="Search Role"
                suffix={<img src={searchImg} alt="search" />}
              />
            </div>
          </Col>
        </Row>
        <div className="flex-1 overflow-auto  px-4 py-4 scrollbar-container">
          <div className="px-3 overflow-auto">
            <Table
              columns={columns}
              pagination={false}
              loading={roleData.getMemberLoader}
              rowClassName={(record) =>
                record.key === selectedRowKey ? "selected-row" : ""
              }
              scroll={
                {
                  // x: 'max-content'
                }
              }
              onRow={(record) => ({
                onClick: () => {
                  if (record.key !== selectedRowKey) {
                    setRoleId(record.id);
                    dispatch(actionGetRolesDetail(record.id));
                    setSelectedRowKey(record.key);
                  }
                },
              })}
              dataSource={data}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between  2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] bg-transparent border-t border-t-custom-gray border-opacity-[30%] px-5"
          align={"middle"}
        >
          {/* <Button
            onClick={() => setModalOpen(true)}
            className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]"
          >
            Add New Role
          </Button> */}
          <div className="flex justify-end" id="custom-pagination">
            <Pagination
              className="flex items-center"
              {...pagination}
              showSizeChanger
              showQuickJumper
              hideOnSinglePage
              pageSizeOptions={["10", "20", "30"]}
              total={rolesCount}
              onChange={(page, pageSize) => handleTableChange(page, pageSize)}
            />
          </div>
        </div>
      </div>
      {/* SIDEBAR START HERE */}
      <div className="bg-[#F4F8FF] border-l border-l-[#919da94d] w-[30%] flex flex-col ">
        <SideCardHeader />
        <div className="overflow-auto custom-scrollbar  px-1 flex-1 py-1">
          <List
            loading={getSingleRolesLoader}
            header={<div>Permissions</div>}
            bordered
            dataSource={getSingleRole && getSingleRole?.permissions}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <Typography.Text className="flex gap-2 items-center">
                  <img src={iconDimond} width={11} alt="iconDiamon" />
                  {item.permissions}
                </Typography.Text>
              </List.Item>
            )}
          />
        </div>
      </div>

      {/*  Modal For Add Role */}
      <Modal
        afterClose={() => {
          form.resetFields();
          setRoleId("");
        }}
        title="Add Role"
        style={{
          top: 20,
        }}
        onCancel={() => {
          setModalOpen(false);
          setRoleId(false);
        }}
        open={modalOpen}
        footer={false}
      >
        <Spin spinning={getSingleRolesLoader}>
          <Form
            form={form}
            layout="vertical"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Name"
              name="name"
              normalize={(value) => value.replace(/[^A-Za-z\s]/g, "")}
              rules={[
                {
                  required: true,
                  message: "Please Enter a Value!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <div className="flex mb-[-10px] pt-4 gap-x-3">
              <Button
                className=" cancel-btn"
                // style={{ padding: "5px 15px" }}

                onClick={() => {
                  setModalOpen(false);
                  setRoleId(false);
                }}
              >
                Cancel
              </Button>
              <Form.Item>
                {" "}
                <Button
                  loading={addRoleLoader || updateRoleLoader}
                  htmlType="submit"
                  className="filled-btn"
                >
                  {roleId ? "Update Role" : "Add Role"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default Roles;
