import { createSlice } from "@reduxjs/toolkit";
import { actionAddRoles, actionAssignRole, actionDeleteAssignRole, actionDeleteRole, actionUpdateRoles, actiongetPermission, actiongetRoles, actionGetRolesDetail, actionGetRoles } from "../services/rbacService";

const rbacSlice = createSlice({
    name: 'rbac',
    initialState: {
        permissions: [],
        permissionLoader: false,
        roles: [],
        rolesCount: [],
        rolesLoader: false,
        addRoleLoader: false,
        getSingleRolesLoader: false,
        getSingleRole: {},
        assignRoleLoader: false,
        deleteAssignLoader: false,
        deleteSuccess: false,

    },
    reducers: {
        deleteSuccess: (state) => {
            state.deleteSuccess = true;
        }
    },
    extraReducers: (builder) => {
        builder
            // Get Permission
            .addCase(actiongetPermission.pending, (state) => {
                state.permissionLoader = true;
            })
            .addCase(actiongetPermission.fulfilled, (state, action) => {
                state.permissions = action.payload.data;
                state.permissionLoader = false;
            })
            .addCase(actiongetPermission.rejected, (state) => {
                state.permissionLoader = false;
            })

            // Add Roles
            .addCase(actionAddRoles.pending, (state) => {
                state.addRoleLoader = true;
            })
            .addCase(actionAddRoles.fulfilled, (state, action) => {
                state.roles = [...state.roles, action.payload]
                state.addRoleLoader = false;
            })
            .addCase(actionAddRoles.rejected, (state) => {
                state.addRoleLoader = false;
            })

            // Get Roles
            .addCase(actionGetRoles.pending, (state) => {
                state.rolesLoader = true;
            })
            .addCase(actionGetRoles.fulfilled, (state, action) => {
                state.roles = action?.payload?.data;
                state.rolesCount = action.payload?.total_records
                state.rolesLoader = false;
            })
            .addCase(actionGetRoles.rejected, (state) => {
                state.rolesLoader = false;
            })

            // Get Single Role
            .addCase(actionGetRolesDetail.pending, (state) => {
                state.getSingleRolesLoader = true;
            })
            .addCase(actionGetRolesDetail.fulfilled, (state, action) => {
                state.getSingleRole = action.payload;
                state.getSingleRolesLoader = false;
            })
            .addCase(actionGetRolesDetail.rejected, (state) => {
                state.getSingleRolesLoader = false;
            })

            // Delete Roles
            .addCase(actionDeleteRole.pending, (state) => {
                state.deleteRoleLoader = true;
            })
            .addCase(actionDeleteRole.fulfilled, (state, action) => {
                state.roles = state.roles.filter(d => d.id !== action.payload);
                state.deleteRoleLoader = false;
            })
            .addCase(actionDeleteRole.rejected, (state) => {
                state.deleteRoleLoader = false;
            })

            // Update Roles
            .addCase(actionUpdateRoles.pending, (state) => {
                state.updateRoleLoader = true;
            })
            .addCase(actionUpdateRoles.fulfilled, (state, action) => {
                state.roles = state.roles.map(d => d.id === action.payload.id ? action.payload : d);
                state.updateRoleLoader = false;
            })
            .addCase(actionUpdateRoles.rejected, (state) => {
                state.updateRoleLoader = false;
            })

            // Assign  Permission User
            .addCase(actionAssignRole.fulfilled, (state, action) => {
                const permissionIdsToRemove = action?.payload?.map((data) => data.permissions?.id) || [];
                const newPermissions = [
                    ...state.getSingleRole.permissions,
                    ...(action?.payload?.map((data) => ({ ...data.permissions, id: data.permissions.id.toString() })) || [])
                ];

                state.getSingleRole = {
                    ...state.getSingleRole,
                    permissions: newPermissions,
                };
                state.assignRoleLoader = false;
            })

            .addCase(actionAssignRole.rejected, (state) => {
                state.assignRoleLoader = false;
            })

            // Assign  Permission User
            .addCase(actionDeleteAssignRole.pending, (state) => {
                state.deleteAssignLoader = true;
            })
            .addCase(actionDeleteAssignRole.fulfilled, (state, action) => {
                const { fullObjects } = action.payload;

                const fullObjectsIds = fullObjects && fullObjects.map(obj => obj.id);
                const uniqueFullObjects = fullObjects.filter(obj => !state.permissions.some(p => p.id === obj.id));

                state.permissions = [...state.permissions, ...uniqueFullObjects];

                state.getSingleRole = {
                    ...state.getSingleRole,
                    permissions: state.getSingleRole.permissions.filter(
                        (d) => !(fullObjectsIds && fullObjectsIds.includes(parseInt(d.id)))
                    ),
                };
                state.deleteAssignLoader = false;
            })
            .addCase(actionDeleteAssignRole.rejected, (state) => {
                state.deleteAssignLoader = false;
            })

    }
})


export default rbacSlice.reducer;
export const { deleteSuccess } = rbacSlice.actions;
