import { Avatar, Button, Col, Descriptions, Empty, Input, Pagination, Popconfirm, Row, Table, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import copied from '../../assets/img/all/copied.svg';
import copy from '../../assets/img/all/copy.svg';
import searchh from "../../assets/img/all/search.svg";
import { actionDeleteTransportAgency, actionGetTransportAgency, actionGetTransportAgencyDetail } from '../../store/services/transportService';
import SideCardHeader from '../common/SideCardHeader';


const { Paragraph } = Typography;

const TransportingAgencies = () => {
    const [selectedRowKey, setSelectedRowKey] = useState(0);
    const [data, setData] = useState([]);
    const [staticId, setStaticId] = useState();
    const [agencyId, setAgencyId] = useState(null);
    const [search, setSearch] = useState("")
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const transportAgencyData = useSelector(state => state.transportAgency);
    const { transportAgencies, deleteSucc, transportAgencyDetail, transportAgencyCount, transportAgencyLoader } = transportAgencyData;
    const dispatch = useDispatch();

    useEffect(() => {
        if (search) {
            dispatch(actionGetTransportAgency({ offset: 0, limit: 10, keyword: search }));
        } else {
            dispatch(actionGetTransportAgency({ offset: pagination.current - 1, limit: pagination.pageSize, keyword: search }));
        }
    }, [dispatch, search, pagination]);


    useMemo(() => {
        const newData = transportAgencies && transportAgencies.length && transportAgencies.map((agency, i) => ({
            key: i,
            num: (pagination.current - 1) * pagination.pageSize + i + 1,
            agency_id: agency.id,
            agency_name: agency.agency_name,
            gst_in: agency.gst_in || '-'
        }));
        setData(newData);
        transportAgencies && transportAgencies.length && setStaticId(transportAgencies[0]?.id);
    }, [transportAgencies]);


    useEffect(() => {
        staticId && dispatch(actionGetTransportAgencyDetail(staticId));
        setSelectedRowKey(0);
        setAgencyId(staticId);
    }, [staticId]);

    const GetTransportAgencyDetailApiCall = (id, key) => {
        if (id && (key !== selectedRowKey)) {
            dispatch(actionGetTransportAgencyDetail(id));
        }
    }

    const handleSearch = (value) => {
        setSearch(value);
    };

    const handleTableChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
            total: transportAgencyData.transportAgencyCount,
        });
    }

    console.log({ transportAgencyDetail });

    // Delete API
    const confirm = async (id) => {
        try {
            const response = dispatch(actionDeleteTransportAgency(id));
            console.log({ deleteSucc });

            let newStaticId = null;

            if (deleteSucc) {
                const deletedRowIndex = transportAgencies?.findIndex(item => item.id === id);
                newStaticId = deletedRowIndex > 0 ? transportAgencies[deletedRowIndex - 1].id : null;
                setSelectedRowKey(deletedRowIndex);
                console.log({ deleteSucc, deletedRowIndex, newStaticId });
            } else {
                console.log("Error Occurs");
            }
            if (newStaticId !== null) {
                dispatch(actionGetTransportAgencyDetail(newStaticId));
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const columns = [
        {
            title: '#',
            width: 50,
            dataIndex: 'num',
            key: 'num',
        },

        {
            title: 'Transport Agency Name',
            dataIndex: 'agency_name',
            key: 'agency_name',
            width: 250,
        },


        {
            title: 'GSTIN Number',
            dataIndex: 'gst_in',
            key: 'gst_in',
            width: 150,
        },

    ];

    const desitems = [
        {
            key: '1',
            label: 'Agency ID',
            children: `#${transportAgencyData && transportAgencyDetail && transportAgencyDetail.id}`,
        },
        /*   {
              key: '2',
              label: 'Contact Number',
              children: <Paragraph
                  className='text-primary'
                  copyable={{
                      icon: [<img src={copy} key="copy-icon" alt="copy-icon" />, <img src={copied} key="copied-icon" alt="copy-icon" />],
                      tooltips: ['Copy', 'Copied!'],
                  }}
              >
                  {transportAgencyData && transportAgencyDetail && transportAgencyDetail.phone}
              </Paragraph>,
          }, */
        {
            key: '3',
            label: 'GSTIN Number',
            children: transportAgencyData && transportAgencyDetail && transportAgencyDetail.gst_in || "-",
        },
    ];

    return (
        <div className='flex justify-center h-full max-h-screen gatepass-container'>
            {/* Content Section */}
            <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>
                <Row
                    justify={'center'}
                    align={'middle'}
                    className='border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'
                >
                    <Col span={6}>
                        <p className='font-GlegooBold text-primary whitespace-nowrap 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>
                        Transport Agencies
                        </p>
                    </Col>
                    <Col span={12} xxl={12} lg={18} sm={18} xl={18} align="end">
                        <div className='mr-5 w-1/2 ml-auto'>
                            <Input onChange={(e) => handleSearch(e.target.value)} className='custom-search' placeholder="Search Agency" suffix={<img src={searchh} alt="search" />} />
                        </div>
                    </Col>

                </Row>
                <div className='flex-1 overflow-hidden px-6 py-4 scrollbar-container'>
                    <Table className='table-scrollbar'
                        loading={transportAgencyLoader}
                        columns={columns}
                        dataSource={data}
                        rowClassName={(record) => (record.key === selectedRowKey ? 'selected-row' : '')}
                        onRow={(record) => ({
                            onClick: () => {
                                GetTransportAgencyDetailApiCall(record.agency_id, record.key)
                                setSelectedRowKey(record.key);
                                setAgencyId(record.id);
                            }
                        })}
                        pagination={false}
                        scroll={{
                            x: "max-content",
                            y: window.innerWidth < 1500 ? "60vh" : undefined,
                        }}
                    />
                </div>
                <div className=' flex items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] border-t border-t-custom-gray border-opacity-[30%] px-5' align={'center'}>
                    <Link to="/add-transport-agency">  <Button className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Add New Agency</Button></Link>
                    <div className='flex justify-end' id='custom-pagination'>
                        <Pagination
                            className='flex items-center'
                            {...pagination}
                            showSizeChanger
                            showQuickJumper
                            hideOnSinglePage
                            pageSizeOptions={['10', '20', '30']}
                            total={transportAgencyCount}
                            onChange={(page, pageSize) => handleTableChange(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
            {/* Content Section */}


            {/* Sidebar  Section Start Here */}
            <div className='bg-[#FFFFFF] border-l border-l-[#919da94d] w-[30%] flex flex-col '>
                <SideCardHeader />
                {
                    transportAgencyCount ?
                        <>
                            <div className='overflow-auto custom-scrollbar  px-1 flex-1 py-1'>

                                <div className='px-4'>
                                    {/* Hello PX-4 */}
                                    <div className='mx-auto flex flex-col items-center justify-center w-full border-b py-4 border-opacity-[30%] border-custom-gray'>
                                        <Avatar shape="square" size={90} className='bg-[#919DA9] text-white] mb-3'>
                                            {transportAgencyData && transportAgencyDetail &&
                                                transportAgencyDetail.agency_name &&
                                                transportAgencyDetail.agency_name.slice(0, 2).toUpperCase()}
                                        </Avatar>
                                        <p className='text-primary text-center 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold   lg:text-[14px] font-bold'>{transportAgencyData && transportAgencyData.transportAgencyDetail && transportAgencyData.transportAgencyDetail.agency_name}</p>
                                    </div>
                                    <Descriptions column={2} layout="vertical" id='gatepass-description' className='mt-4' items={desitems} />

                                </div>
                            </div>

                            {
                                (transportAgencyDetail && transportAgencyDetail.agency_name.toLowerCase() !== "self") &&

                                <div className=' bg-white relative flex gap-x-2 z-50 justify-evenly  px-3 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  border-t border-t-custom-gray border-opacity-[30%] 2xl:py-[17px] xl:py-3 py-3'>

                                    <Link to={`/edit-transport-agency/${transportAgencyData && transportAgencyDetail && transportAgencyDetail.id}`} className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] px-5 w-1/2'>Edit Agency Details</Link>

                                    <Popconfirm
                                        okButtonProps={{
                                            // loading: memberData.deleteMemberLoader,
                                        }}
                                        placement="top"
                                        title="Are you sure you want to delete Agency?"
                                        onConfirm={() => confirm(transportAgencyData && transportAgencyDetail && transportAgencyDetail.id)}
                                        okText="Yes"
                                        cancelText="No">
                                        <Button className='warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px] px-5 w-1/2'>Delete Agency</Button>
                                    </Popconfirm>
                                </div>
                            }
                        </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="pt-10 flex items-center justify-center flex-col" />
                }
            </div>
            {/* Sidebar  Section Start Here */}
        </div >
    )
}

export default TransportingAgencies
