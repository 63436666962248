import { DeleteOutlined, EditOutlined, CalendarOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Input, Modal, Pagination, Popconfirm, Row, Space, Spin, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import searchImg from "../../assets/img/all/search.svg";
import { actionAddMachine, actionDeleteMachine, actionUpdateMachine, getMachine, getSingleMachines } from '../../store/services/lotService';
import SideCardHeader from '../common/SideCardHeader';

const Machine = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [modalOpen, setModalOpen] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [machineId, setMachineId] = useState(false);
    const [selectedRowKey, setSelectedRowKey] = useState(1);
    const [staticId, setStaticId] = useState();
    const [keyword, setKeyword] = useState("");
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const batchData = useSelector(state => state.lot);
    const { machines, getMachinesLoader, getSinglemachines, getSingleMachinesLoader, deleteMachineLoader, deleteSuccess, addMachineLoader, updateMachineLoader, machinesCount } = batchData;

    useEffect(() => {
        if (keyword) {
            dispatch(getMachine({ limit: 10, offset: 0, keyword: keyword ? keyword : "" }));
        } else {
            dispatch(getMachine({ limit: pagination.pageSize, offset: pagination.current - 1, keyword: keyword ? keyword : "" }));
        }
    }, [dispatch, keyword, pagination]);

    useMemo(() => {
        const newData = machines && machines.length && machines?.map((d, i) => ({
            key: i,
            num: (pagination.current - 1) * pagination.pageSize + i + 1,
            id: d?.id,
            name: <p className='capitalize'>{d?.name}</p>,
            created_at: <p className='capitalize'>{moment(d?.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>,
        }));
        setData(newData);
    }, [machines]);


    const handleUpdateMachine = (id) => {
        console.log(id);

        dispatch(getSingleMachines(id));
        setModalOpen(true);
        setMachineId(id);
    }

    useEffect(() => {
        if (getSinglemachines) {
            form.setFieldsValue({ name: getSinglemachines?.name })
        }
    }, [getSinglemachines])

    useEffect(() => {
        staticId && dispatch(getSingleMachines(staticId));
        setSelectedRowKey(0)
    }, [staticId]);

    // Delete API
    const confirm = (id) => {
        dispatch(actionDeleteMachine(id))
            .then(response => {
                try {
                    if (deleteSuccess) {
                        const deletedRowIndex = machines?.findIndex(item => item.id === id);
                        const newStaticId = deletedRowIndex > 0 ? machines[deletedRowIndex - 1].id : null;
                        setSelectedRowKey(deletedRowIndex - 1);
                    } else {
                        console.log("Error Occurs");
                    }
                } catch (err) {
                    console.log({ err });
                }

            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    // Manage Pagination Here
    const handleTableChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
            total: machinesCount,
        });
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'num',
            width: 100,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 150,
        },
        {
            title: <p className='flex items-center gap-x-2'><CalendarOutlined />Created At</p>,
            dataIndex: 'created_at',
            // width: 200,
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            align: 'right',
            //  width: 100,
            render: (item) => (
                <Space size="middle">
                    <div>
                        <a onClick={() => handleUpdateMachine(item.id)}

                            className='flex text-primary hover:text-primary'
                        >
                            <EditOutlined
                                className='action-icon'

                            />
                            <span>Edit</span>
                        </a>
                    </div>
                    <Popconfirm
                        okButtonProps={{
                            loading: deleteMachineLoader,
                        }}
                        placement="topLeft"
                        title="Are you sure you want to delete this User?"
                        onConfirm={() => confirm(item.id)}
                        okText="Yes"
                        cancelText="No">
                        <div className="flex text-[#ff0000]">
                            <DeleteOutlined className="action-icon" style={{ color: 'red', fontWeight: 400 }} />
                            Delete
                        </div>
                    </Popconfirm>
                </Space>

            ),
        },
    ];

    const onFinish = (val) => {
        machineId ? dispatch(actionUpdateMachine({ val, navigate, setModalOpen, form, machineId })) :
            dispatch(actionAddMachine({ val, navigate, setModalOpen, form, setMachineId }));
    };


    const onFinishFailed = (err) => {
        console.log({ err });
    }

    return (
        <div className='flex justify-center h-full max-h-screen gatepass-container'>
            <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>
                <Row
                    justify={'center'}
                    align={'middle'}
                    className='border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'
                >
                    <Col span={6}>
                        <p className='font-GlegooBold whitespace-nowrap text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>
                            Machines
                        </p>
                    </Col>
                    <Col span={12} xxl={12} lg={18} sm={18} xl={18} align="end">
                        <div className='mr-5 w-1/2 ml-auto'>
                            <Input onChange={(e) => setKeyword(e.target.value)} className='custom-search' placeholder="Search Machine" suffix={<img src={searchImg} alt="search" />} />
                        </div>
                    </Col>

                </Row>
                <div className='flex-1 overflow-hidden  px-4 py-4 scrollbar-container'>
                    <div className='px-3 overflow-auto'>
                        <Table columns={columns} className='table-scrollbar'
                            pagination={false}
                            loading={getMachinesLoader}
                            rowClassName={(record) => (record.key === selectedRowKey ? 'selected-row' : '')}
                            scroll={{
                                x: "max-content",
                                y: window.innerWidth < 1500 ? "62vh" : undefined,
                            }}
                            onRow={(record) => ({
                                onClick: () => {
                                    setSelectedRowKey(record.key)
                                },
                            })}
                            dataSource={data} />
                    </div>
                </div>
                <div className='flex items-center justify-between  2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] bg-transparent border-t border-t-custom-gray border-opacity-[30%] px-5' align={'middle'}>
                    <Button onClick={() => setModalOpen(true)} className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Add New Machine</Button>
                    <div className='flex justify-end' id='custom-pagination'>
                        <Pagination
                            className='flex items-center'
                            {...pagination}
                            showSizeChanger
                            showQuickJumper
                            hideOnSinglePage
                            pageSizeOptions={['10', '20', '30']}
                            total={machinesCount}
                            onChange={(page, pageSize) => handleTableChange(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
            {/* SIDEBAR START HERE */}
            <div className='bg-[#F4F8FF] border-l  border-l-[#919da94d] w-[30%] flex flex-col '>
                <SideCardHeader />
                <div className='overflow-auto custom-scrollbar  px-1 flex-1 py-1'>
                </div>
            </div>

            {/*  Modal For Add Machine */}
            <Modal afterClose={() => {
                form.resetFields();
                setMachineId("")
            }}
                title="Add Machine"
                style={{
                    top: 20,
                }}
                onCancel={() => {
                    setModalOpen(false)
                    setMachineId(false)
                }}
                open={modalOpen}
                footer={false}
            >
                <Spin spinning={getSingleMachinesLoader}>
                    <Form
                        form={form}
                        layout="vertical"
                        size="large"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}>

                        <Form.Item
                            label="Name"
                            name="name"
                            normalize={(value) => value.toUpperCase()}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter a Value!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const invalidChars = /[ivx]/i; // i flag for case-insensitive

                                        if (value && value.length > 3) {
                                            return Promise.reject("Entered value must be shorter than or equal to 3 characters");
                                        }

                                        if (value && invalidChars.test(value)) {
                                            return Promise.reject("Please enter a value without 'i', 'v', or 'x'.");
                                        }

                                        // You can add additional conditions if needed

                                        return Promise.resolve();
                                    },
                                })
                            ]}
                        >
                            <Input placeholder='Name' />
                        </Form.Item>

                        <div className='flex mb-[-10px] pt-4 gap-x-3'>
                            <Form.Item>   <Button className='outline-btn p-0' style={{ padding: "5px 15px" }} onClick={() => {
                                setModalOpen(false);
                                setMachineId(false);
                            }}>Cancel</Button></Form.Item>
                            <Form.Item>  <Button loading={addMachineLoader || updateMachineLoader} htmlType="submit" className='filled-btn'>{machineId ? "Update Machine" : "Add Machine"}</Button></Form.Item>
                        </div>
                    </Form>
                </Spin>

            </Modal>

        </div >
    )
}

export default Machine;

