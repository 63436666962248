import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import bgImage from "../../assets/img/certificateImages/bgImage.png";
import logo from "../../assets/img/certificateImages/logo.png";
import GlegooBold from "../../assets/fonts/Glegoo-Bold.ttf";
import GlegooRegular from "../../assets/fonts/Glegoo-Regular.ttf";
import GilroyBold from "../../assets/fonts/Gilroy-Bold.ttf";
import GilroyMedium from "../../assets/fonts/Gilroy-Medium.ttf";
import GilroyRegular from "../../assets/fonts/Gilroy-Regular.ttf";
import GilroySemiBold from "../../assets/fonts/Gilroy-SemiBold.ttf";
import { BASE_URL } from "../../components/config/web.config";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetCertificate } from "../../store/services/memberService";
import { actionGetDispatchDetail } from "../../store/services/dispatchService";

Font.register({
  family: "Glegoo-Regular",
  src: GlegooRegular,
});

Font.register({
  family: "Glegoo-Bold",
  src: GlegooBold,
});
Font.register({
  family: "Gilroy-Regular",
  src: GilroyRegular,
});
Font.register({
  family: "Gilroy-Medium",
  src: GilroyMedium,
});
Font.register({
  family: "Gilroy-Bold",
  src: GilroyBold,
});
Font.register({
  family: "Gilroy-SemiBold",
  src: GilroySemiBold,
});

const styles = StyleSheet.create({
  backgroundImage: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    height: "224px",
    width: "228.77px",
  },
  mainTableLayout: {
    display: "flex",
    flexDirection: "row",
    fontSize: "8px",
    borderTop: "0.5px solid #050340",
  },
  tableCol: {
    width: "27%",
    textAlign: "center",
    borderRightWidth: 0.5,
    borderColor: "#050340",
  },
  tableLastCol: {
    width: "19%",
    textAlign: "center",
  },
  subCol: {
    padding: "6 0",
    borderBottomWidth: 0.5,
    borderColor: "#050340",
  },
  LastSubCol: {
    padding: "6 0",
  },
  lastbox: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  sign1: {
    width: "62px",
    height: "41px",
  },
  sign2: {
    width: "87px",
    height: "39px",
  },
  sign3: {
    width: "79px",
    height: "33px",
  },
  boxes: {
    flex: 1,
    fontFamily: "Gilroy-Medium",
    fontSize: "8px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "90px",
    height: "70px",
    // borderRight: "0.5px solid #E9E9ED",
  },
  boxesLast: {
    flex: 1,
    fontFamily: "Gilroy-Medium",
    fontSize: "8px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "90px",
    height: "70px",
  },
  titleText: {
    flex: 1,
    fontFamily: "Gilroy-Medium",
    fontSize: "8px",
    textAlign: "center",
  },
  heading: {
    fontSize: 10,
    fontFamily: "Gilroy-Bold",
    textAlign: "center",
  },
  mainView: {
    padding: 10,
    // backgroundColor: "red",
  },
  innerView: {
    border: "0.5px solid #050340",
    borderTop: "none",
  },
  flex1: {
    flex: 1,
  },
  innerChild: {
    paddingTop: 10,
    paddingBottom: 6,
    borderRight: "0.5px solid #050340",
    flex: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    border: "0.5px solid #050340",
    borderTop: "none",
  },
  innerLastChild: {
    paddingTop: 10,
    paddingBottom: 6,
    flex: 1,
  },
  innerText: {
    fontSize: 8,
    textAlign: "center",
    color: "#2B2A28",
    fontFamily: "Gilroy-Medium",
  },
  innerHeading: {
    fontSize: 8,
    textAlign: "center",
    color: "#2B2A28",

    fontFamily: "Gilroy-Bold",
  },
  mainHeader: {
    border: "0.5px solid #050340",
    paddingTop: 8,
    paddingBottom: 6,
  },
});

const PrintGatepass = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dispatchData = useSelector((state) => state.dispatch);
  const { certificates } = useSelector((state) => state.member);
  const {
    dispatches,
    addBagLoader,
    dispatchCount,
    dispatchDetail,
    deletedispatchLoader,
    dispatchLoader,
    dispatchDetailLoader,
    deleteSuccess,
  } = dispatchData;

  useEffect(() => {
    dispatch(actionGetCertificate());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(actionGetDispatchDetail(id));
    }
  }, [id]);

  const getBagNameFromType = (type) => {
    let name;
    switch (type) {
      case "Standard_sand_grade_I":
        name = "Grade-I";
        break;
      case "Standard_sand_grade_II":
        name = "Grade-II";
        break;
      case "Standard_sand_grade_III":
        name = "Grade-III";
        break;
      case "Standard_Density_sand_IV":
        name = "Density Sand";
        break;
      case "Bsen196_iso679_V":
        name = "BSEN 196";
        break;
      case "Astm_c778_grade_sand_VI":
        name = "ASTM C-778";
        break;
      case "Astm_20_30_sand_VII":
        name = "ASTM 20/30";
        break;
      case "Bs_1377_density_sand_VIII":
        name = "BS 1377";
        break;
      case "Astm_d1556m_15_density_sand_IX":
        name = "ASTM D1556";
        break;
    }
    return name;
  };

  return (
    <PDFViewer style={{ width: "100%", height: "950px" }}>
      <Document>
        <Page size={[595.28]}>
          <View
            style={{
              border: "1px double #050340",
              margin: "10px",
            }}
          >
            <View
              style={{
                border: "1px double #050340",
                margin: "4px",
                height: "auto",
              }}
            >
              <View style={styles.backgroundImage}>
                <Image src={bgImage} style={styles.backgroundImage} />
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#532937",
                  padding: "0px 10px",
                }}
              >
                <View>
                  <Text
                    style={{
                      fontSize: "24px",
                      fontFamily: "Glegoo-Bold",
                    }}
                  >
                    Gate pass
                  </Text>
                </View>
                <View
                  style={{
                    width: "80px",
                    height: "26px",
                  }}
                >
                  <Image src={logo} alt="logo" />
                </View>
              </View>
              <View
                style={{
                  borderTop: "1px solid #532937",
                  margin: "0px 10px",
                }}
              ></View>
              <View
                style={{
                  width: "26%",
                  border: "1.3px solid #532937",
                  backgroundColor: "#532937",
                  margin: "-1px 10px 0px auto",
                }}
              ></View>
              <View
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "18px",
                  fontSize: "8px",
                  color: "#2B2A28",
                  padding: "0px 10px",
                }}
              >
                <View style={{ width: "100%" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      Date :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                      {dispatchDetail &&
                        moment(dispatchDetail.date).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      Gate Pass No :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                      {dispatchDetail &&
                        moment(dispatchDetail.date).format("YYYYMMDD") +
                          "-" +
                          dispatchDetail.id}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      Invoice No :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                      {dispatchDetail && dispatchDetail.invoice_number}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      Transporting Agency :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                      {dispatchDetail &&
                        dispatchDetail.transport_agency?.agency_name}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      Driver Name :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                      {dispatchDetail &&
                        dispatchDetail.driver_name}
                    </Text>
                  </View>
                </View>
                <View style={{ width: "100%" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      Driver Contact Number :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                      +91{" "}
                      {dispatchDetail && dispatchDetail.driver_contact_number}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      L.R Number :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                      -
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: "2px",

                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                        paddingTop: 2,
                        marginRight: 5,
                      }}
                    >
                      Vehicle :
                    </Text>
                    {/* Main Div */}
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0px 5px",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <View
                          style={{
                            height: 8,
                            width: 8,
                            border: "0.3px solid #000000",
                            borderRadius: 1,
                          }}
                        ></View>
                        <Text>Truck</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <View
                          style={{
                            height: 8,
                            width: 8,
                            border: "0.3px solid #000000",
                            borderRadius: 1,
                          }}
                        ></View>
                        <Text>Tempo</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <View
                          style={{
                            height: 8,
                            width: 8,
                            border: "0.3px solid #000000",
                            borderRadius: 1,
                          }}
                        ></View>
                        <Text>Tractor</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <View
                          style={{
                            height: 8,
                            width: 8,
                            border: "0.3px solid #000000",
                            borderRadius: 1,
                          }}
                        ></View>
                        <Text>Car</Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Gilroy-Bold",
                      }}
                    >
                      Vehicle No :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Gilroy-Medium",
                        marginLeft: "2.5px",
                      }}
                    >
                       {dispatchDetail &&
                        dispatchDetail.vehicle_no}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.mainView}>
                <View style={styles.mainHeader}>
                  <Text style={styles.heading}>Item Details</Text>
                </View>

                <View style={styles.row}>
                  <View style={styles.innerChild}>
                    <Text style={styles.innerHeading}>Sr No.</Text>
                  </View>
                  <View style={styles.innerChild}>
                    <Text style={styles.innerHeading}>Descripation</Text>
                  </View>
                  <View style={styles.innerChild}>
                    <Text style={styles.innerHeading}>Unit</Text>
                  </View>
                  <View style={styles.innerChild}>
                    <Text style={styles.innerHeading}>QTY</Text>
                  </View>
                  <View style={styles.innerLastChild}>
                    <Text style={styles.innerHeading}>Remark</Text>
                  </View>
                </View>
                {/*  Header Ends Here */}

                {certificates &&
                  certificates.length > 0 &&
                  (() => {
                    let counter = 0;

                    return certificates.map(
                      (c, index) =>
                        dispatchDetail &&
                        dispatchDetail.bags &&
                        dispatchDetail.bags.length > 0 &&
                        dispatchDetail.bags.filter((b) => b.type === c.type)
                          .length > 0 && (
                          <View style={styles.row}>
                            <View style={styles.innerChild}>
                              <Text style={styles.innerText}>{++counter}</Text>
                            </View>
                            <View style={styles.innerChild}>
                              <Text style={styles.innerText}>
                                {getBagNameFromType(c?.type)}
                              </Text>
                            </View>
                            <View style={styles.innerChild}>
                              <Text style={styles.innerText}>-</Text>
                            </View>
                            <View style={styles.innerChild}>
                              <Text style={styles.innerText}>
                                {dispatchDetail &&
                                  dispatchDetail.bags &&
                                  dispatchDetail.bags.length > 0 &&
                                  dispatchDetail.bags.filter(
                                    (b) => b.type === c.type
                                  ).length}
                              </Text>
                            </View>

                            <View style={styles.innerLastChild}>
                              <Text style={styles.innerText}>-</Text>
                            </View>
                          </View>
                        )
                    );
                  })()}
              </View>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: "50px",
                  margin: "15px 10px 3px 10px",
                }}
              >
                <Text style={styles.titleText}>Admin Signature</Text>
                <Text style={styles.titleText}>Checked By Signature</Text>
                <Text style={styles.titleText}>Driver Signature</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  // border: "0.5px solid #E9E9ED",
                  margin: "0 10px 10px 10px",
                }}
              >
                <View style={styles.boxes}>
                  {/* <Image src={sigen2} style={styles.sign2} /> */}
                </View>
                <View style={styles.boxes}>
                  {/* <Image src={sigen1} style={styles.sign1} /> */}
                </View>
                <View style={styles.boxesLast}>
                  {/* <Image src={sigen3} style={styles.sign3} /> */}
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PrintGatepass;
