import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { actionAddBags } from "../../../store/services/lotService";

const GradeII = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [customValidationError, setCustomValidationError] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const grade = useSelector((state) => state.lot);
  const { user } = useSelector((state) => state.auth);

  const { machines, addBagLoader, getMachinesLoader } = grade;

  const onFinish = (values) => {
    const isValueSelected = selectedValue.length > 0;
    if (!isValueSelected) {
      setCustomValidationError("Please select a Machine Type.");
    } else {
      setCustomValidationError("");

      const req = {
        ...values,
        type: "Standard_sand_grade_II",
        pass_through: values.pass_through
          ? parseFloat(values.pass_through).toFixed(2)
          : "",
        retain_on: values.retain_on
          ? parseFloat(values.retain_on).toFixed(2)
          : "",
        hcl: values.hcl ? parseFloat(values.hcl).toFixed(2) : "",
        user_id: user?.id,
        screen: selectedValue,
      };

      console.log({ req }, "rrrrrr");
      // Check if selectedValue is not empty before calling the API
      if (isValueSelected) {
        dispatch(actionAddBags({ req, form, setSelectedValue }));
      }
    }
  };

  const onFinishFailed = (err) => {
    if (selectedValue.length <= 0) {
      setCustomValidationError("Please select a Machine Type.");
      console.log("Empty");
    } else {
      setCustomValidationError("");
    }
    console.log({ err });
  };
  return (
    <>
      <div className="rounded-[15px] px-4 py-1 bg-white  my-2 ">
        <Form
          initialValues={{
            pass_through: 100,
          }}
          name="lot-form"
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="text-[20px] text-[#000] mt-3">
            Standard Sand ( Grade-II )
          </div>
          <div className="text-[#000]">
            <p className="text-[12px] mt-4 ">Machine Name ( Screen )</p>
            <div className="mt-1 w-auto flex text-[14px] font-semibold">
              {getMachinesLoader ? (
                <Spin
                  className="my-2"
                  size="small"
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: "#00205E",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                machines &&
                machines.length &&
                machines?.map((letter, index) => {
                  return (
                    <div
                      key={letter.id}
                      className={`border-2 cursor-pointer ${
                        machines.length - 1 === index
                          ? "border-r-1"
                          : "border-r-0"
                      }  border-[#B2BCCF] bg-[#fff] px-3.5 py-1.5 ${
                        selectedValue === letter.name
                          ? "bg-primary   text-white"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedValue(letter.name);
                        setCustomValidationError(false);
                      }}
                    >
                      {letter.name}
                    </div>
                  );
                })
              )}
            </div>
          </div>
          {customValidationError && (
            <p className="text-red-500 mt-1">{customValidationError}</p>
          )}
          <Row gutter={[16, 0]} className="graed  mt-3">
            <Col span={10}>
              <Form.Item
                label="Value for Pass From Sieve 1.00 mm"
                name="pass_through"
                normalize={(value) => value.replace(/[^0-9.]/g, "").trim()}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Value for Pass From Sieve 1.00 mm!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 90.0 && value <= 100.0)) {
                        return Promise.reject(
                          "Please enter a valid value between 90.00 and 100.00!"
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  className=""
                  placeholder="Enter Value for Pass From Sieve 1.00 mm"
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={
                  <div className="flex w-full items-center justify-between">
                    <p className="text-[12px]">
                      Value for IS test retain on Sieve 0.500 mm
                    </p>
                    <p className="text-[12px] text-[#717171] text-right">
                      ( 99.50 - 100.00 )
                    </p>
                  </div>
                }
                name="retain_on"
                normalize={(value) => value.replace(/[^0-9.]/g, "").trim()}
                rules={[
                  {
                    required: true,
                    message:
                      "Please Select Value for IS test retain on Sieve 0.500 mm!",
                  },
                ]}
              >
                <Select
                  placeholder="Select value for IS test retain on Sieve 0.500 mm"
                  optionFilterProp="children"
                  options={[
                    {
                      value: "99.50",
                      label: "99.50",
                    },
                    {
                      value: "99.60",
                      label: "99.60",
                    },
                    {
                      value: "99.70",
                      label: "99.70",
                    },
                    {
                      value: "99.80",
                      label: "99.80",
                    },
                    {
                      value: "99.90",
                      label: "99.90",
                    },
                    {
                      value: "100",
                      label: "100",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={
                  <div className="flex w-full items-center justify-between">
                    <p className="text-[12px]">
                      Loss of Mass Extracted Against Hot HCL %
                    </p>
                    <p className="text-[12px] text-[#717171] text-right">
                      ( 0.05 - 0.250 )
                    </p>
                  </div>
                }
                name="hcl"
                normalize={(value) => value.replace(/[^0-9.]/g, "").trim()}
                rules={[
                  {
                    required: true,
                    message:
                      "Please Enter Loss of Mass Extracted Against Hot HCL %!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 0.05 && value <= 0.25)) {
                        return Promise.reject(
                          "Please enter a valid value between 0.05 and 0.250!"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder="Enter Loss of Mass Extracted Against Hot HCL %" />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item
                label={
                  <div className="flex w-full items-center justify-between">
                    <p className="text-[12px]">Custom Batch</p>
                  </div>
                }
                name="custom_batch_id"
                rules={[
                  {
                    required: false,
                    message: "Please Enter Custom Batch!",
                  },
                ]}
              >
                <Input placeholder="Enter Custom Batch" />
              </Form.Item>
            </Col>
          </Row>

          <div className="footer z-0  flex items-center gap-x-2 bottom-0 w-full   bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                loading={addBagLoader}
                disabled={addBagLoader}
                htmlType="submit"
                className="outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2"
              >
                Add Bag
              </Button>{" "}
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default GradeII;
