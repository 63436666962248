import { HighlightOutlined, KeyOutlined } from '@ant-design/icons';
import { Button, Dropdown, Typography, Tooltip, Popconfirm } from 'antd';
import React from 'react';
import dots from "../../assets/img/all/dots.svg";
import logouticon from "../../assets/img/dashboard/logout.svg";
import dotswhite from "../../assets/img/all/dotswhite.svg";
import url from '../../assets/img/dashboard/profile.svg';
import logoutbtn from "../../assets/img/dashboard/logoutbtn.svg"
import logoutbtnWhite from "../../assets/img/dashboard/logoutbtnWhite.svg"
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { actionLogout } from '../../store/services/authService';

const SideCardHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth)

    const logout = () => {
        dispatch(actionLogout(navigate));
    }

    const items = [

        {
            key: '1',
            label: (
                <Link to={`/change-password/${user?.id}`}>
                    Change Password
                </Link>
            ),
            icon: <KeyOutlined style={{ fontSize: 15 }} className='text-primary tex-[25px]' />,
        },

        {
            key: '2',

            label: <div onClick={logout} className='flex items-center gap-x-2'>
                <img src={logouticon} alt='logouticon' className='h-[15px] w-[15px]' />
                <p className='mb-0'
                /*   editable={{
                      editing: false,
                      icon: <HighlightOutlined />,
  
                  }} */
                >
                    Logout</p>
            </div>,
        },
    ];

    return (
        <div className='border-b border-b-[#919da94d] 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]  flex items-center'>
            <div className='flex items-center mx-2 w-full'>
                <div className='ml-4 2xl:h-[65px] xl:h-[40px] lg:h-[35px] h-[33px]  border border-primary rounded-full'>
                    <img src={user && user.profile_pic} className='w-full h-full rounded-full ' alt={"User Img"} />
                </div>
                <div className='flex-col justify-stretch items-center ml-3 '>
                    <p className='text-primary 2xl:text-[24px] xl:text-[18px] text-[10px] font-Glegoo lg:text-[14px] font-bold'>
                        {`${user && user.first_name} ${user && user.last_name}`}
                    </p>

                    <p className='text-[#919DA9] 2xl:text-[16px] xl:text-[12px] font-gilroyMedium lg:text-[8px] text-[8px]'>{user && user.email}</p>
                </div>

                <div className='ml-auto mr-3'>
                    <Popconfirm
                        title="Are you sure you want to logout?"
                        onConfirm={logout} // This function will be called when the user confirms
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                    >
                       {/*  <Tooltip title="Logout" placement="bottom"> */}
                            <Button className='bg-[#DFDEFF] h-auto 2xl:px-4 xl:px-3 px-2 ml-auto 2xl:py-4 xl:py-3 py-2  duration-700 transition-all hover:bg-[#00205E] hover:text-white border-0 flex justify-center items-center rounded-[10px] group'>
                                <img src={logoutbtn} alt='logout' width={'20px'} height={'20px'} className='group-hover:hidden' />
                                <img src={logoutbtnWhite} alt="logout" width={'20px'} height={'20px'} className='white-dots group-hover:block hidden' />
                            </Button>
                        {/* </Tooltip> */}
                    </Popconfirm>
                </div>
            </div>
        </div>
    )
}

export default SideCardHeader
