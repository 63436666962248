import Icon, {
  EditOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Collapse,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Dropdown,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import filter from "../../assets/img/all/filter.svg";
import searchImg from "../../assets/img/all/search.svg";
import logo from "../../assets/img/all/logo.svg";
import SideCardHeader from "../common/SideCardHeader";
import moment from "moment";
import moon from "../../assets/img/all/moon.svg";
import up from "../../assets/img/all/up.svg";
import down from "../../assets/img/all/down.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddDispatchBag,
  actionDeleteDispatch,
  actionDeleteDispatchBag,
  actionGetDispatchDetail,
  actionGetDispatches,
  actionUpdateDispatchStatus,
} from "../../store/services/dispatchService";
import deleteIconn from "../../assets/img/all/deleteIconn.svg";
import addBag from "../../assets/img/all/addBag.svg";
import iconGradeI from "../../assets/img/grade/iconGradeI.svg";
import iconGradeII from "../../assets/img/grade/iconGradeII.svg";
import iconGradeIII from "../../assets/img/grade/iconGradeIII.svg";
import iconGradeIV from "../../assets/img/grade/iconGradeIV.svg";
import iconGradeIX from "../../assets/img/grade/iconGradeIX.svg";
import iconGradeV from "../../assets/img/grade/iconGradeV.svg";
import iconGradeVI from "../../assets/img/grade/iconGradeVI.svg";
import iconGradeVII from "../../assets/img/grade/iconGradeVII.svg";
import iconGradeVIII from "../../assets/img/grade/iconGradeVIII.svg";
import print from "../../assets/img/all/print.svg";
import lotAction from "../../assets/img/all/lotAction.svg";
import { actionGetCertificate } from "../../store/services/memberService";
import dayjs from "dayjs";

const Dispatches = () => {
  const dispatch = useDispatch();
  const [selectedRowKey, setSelectedRowKey] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [staticId, setStaticId] = useState();
  const [dispatchId, setDispatchId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const dispatchData = useSelector((state) => state.dispatch);
  const { certificates } = useSelector((state) => state.member);
  const {
    dispatches,
    addBagLoader,
    dispatchCount,
    dispatchDetail,
    deletedispatchLoader,
    dispatchLoader,
    dispatchDetailLoader,
    updateDispatchStatusLoader,
    deleteSuccess,
  } = dispatchData;

  useEffect(() => {
    dispatch(actionGetCertificate());
  }, [dispatch]);

  useEffect(() => {
    if (search) {
      dispatch(
        actionGetDispatches({
          offset: 0,
          limit: 10,
          keyword: search ? search : "",
          date: date ? dayjs(date).format("YYYY-MM-DD") : "",
          s: status ? status : "",
        })
      );
    } else {
      dispatch(
        actionGetDispatches({
          offset: pagination.current - 1,
          limit: pagination.pageSize,
          keyword: search ? search : "",
          date: date ? dayjs(date).format("YYYY-MM-DD") : "",
          s: status ? status : "",
        })
      );
    }
  }, [dispatch, search, pagination, status, date]);

  const handleGetWithDate = (date, dateString) => {
    setDate(dateString ? dayjs(dateString) : null);
  };

  const handleClickDispatchStatus = (value, id) => {
    console.log({ value, id });
    dispatch(actionUpdateDispatchStatus({ id, dispatchStatus: value.key }));
    console.log(id, "iddd");
  };

  const items = [
    {
      key: "DRAFT",
      label: "Draft",
      value: "DRAFT",
    },
  ];
  const itemsDispatch = [
    {
      key: "DISPATCH",
      label: "Dispatched",
      value: "DISPATCH",
    },
  ];

  useMemo(() => {
    const newData =
      dispatches &&
      dispatches.length &&
      dispatches.map((d, i) => ({
        key: i + 1,
        id: d.id,
        num: (pagination.current - 1) * pagination.pageSize + i + 1,
        date: d.date ? moment(d.date).format("ll") : "-",
        name: d.company.business_name,
        agency_name: d.transport_agency.agency_name,
        person_name: d.company.person_name,
        // status:
        //   <Tag color={`${d.status === 'DRAFT' ? "orange" : "green"}`} icon={d.status === 'DRAFT' ? <InfoCircleOutlined /> : <CheckCircleOutlined />}>{d.status === "DRAFT" ? 'Draft' : 'Dispatched'}</Tag>,

        status: (
          <>
            <div>
              {/*   <Tooltip title={d.status === 'DRAFT' ? 'Change Status Draft to Dispatch' : 'Change Status Dispatch to Draft'}> */}
              <Dropdown
                menu={{
                  items: d.status === 'DRAFT' ? itemsDispatch : items,
                  onClick: (key) => {
                    handleClickDispatchStatus(key, d.id);
                  },
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag
                    className="cursor-pointer"
                    color={d.status === "DRAFT" ? "orange" : "green"}
                  >
                    {d.status === "DRAFT" ? "Draft" : "Dispatched"}
                  </Tag>
                </a>
              </Dropdown>
              {/* </Tooltip> */}
            </div>
          </>
        ),
        number: d.invoice_number,

        address:
          d.delivery_place && d.delivery_place.length > 50
            ?
            <Tooltip title={d.delivery_place} placement="bottom">
              {d.delivery_place.slice(0, 50) + "..."}
            </Tooltip>
            : d.delivery_place,
        user: d?.user?.first_name + " " + d?.user?.last_name,
        bags: d?.bags,
        mainStatus: d?.status,
      }));
    setData(newData);
    dispatches && dispatches.length && setStaticId(dispatches[0].id);
  }, [dispatchData]);

  const handleTableChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize,
      total: dispatchCount,
    });
  };

  useEffect(() => {
    if (staticId) {
      dispatch(actionGetDispatchDetail(staticId));
    }
  }, [staticId]);

  // Delete API
  const confirm = (id) => {
    dispatch(actionDeleteDispatch(id))
      .then((response) => {
        try {
          if (deleteSuccess) {
            const deletedRowIndex = dispatches.findIndex(
              (item) => item.id === id
            );
            const newStaticId =
              deletedRowIndex > 0 ? dispatches[deletedRowIndex - 1].id : null;
            setSelectedRowKey(deletedRowIndex);
            dispatch(actionGetDispatchDetail(newStaticId));
          } else {
            console.log("Error Occurs");
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const columns = [
    {
      title: "#",
      // width: 50,
      dataIndex: "num",
      key: "num",
    },
    {
      title: "Business Name",
      width: 150,
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Person Name",
      width: 150,
      dataIndex: "person_name",
      key: "person_name",
      ellipsis: true,
    },
    {
      title: "Transport Agency",
      width: 150,
      dataIndex: "agency_name",
      key: "agency_name",
      ellipsis: true,
    },
    {
      title: "Dispatch Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Invoice Number",
      dataIndex: "number",
      key: "number",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Delivery Address",
      dataIndex: "address",
      key: "address",
      width: 300,
      // ellipsis: true,
    },

    {
      title: "QA",
      dataIndex: "user",
      key: "user",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      width: 50,
      ellipsis: true,
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      align: "left",
      width: 100,
      render: (item) => {
        return (
          <Space size="small">
            <Tooltip title="Edit">
              <Link
                to={`/edit-dispatch-batch/${item.id}`}
                className="flex text-primary hover:text-primary"
              >
                <EditOutlined className="action-icon" />
              </Link>
            </Tooltip>
            <Tooltip title="Add Bag">
              <div onClick={() => showModal(item.id)}>
                {/* <img src={addBag} alt="print" /> */}
                <Icon component={add} className="mr-1" />
              </div>
            </Tooltip>
            {item.mainStatus == "DISPATCH" && (
              <Tooltip title="Print Gatepass">
                <Link to={`/print-gatepass/${item.id}`} target="_blank">
                  <img src={print} alt="print" className="print-icon" />
                </Link>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  const handleDeleteBag = (id, dispatchId) => {
    dispatch(actionDeleteDispatchBag({ id, dispatchId }));
  };

  const getBagNameFromType = (type) => {
    let name;
    let imageIcon;
    switch (type) {
      case "Standard_sand_grade_I":
        name = "Grade-I";
        imageIcon = iconGradeI;
        break;
      case "Standard_sand_grade_II":
        name = "Grade-II";
        imageIcon = iconGradeII;
        break;
      case "Standard_sand_grade_III":
        name = "Grade-III";
        imageIcon = iconGradeIII;
        break;
      case "Standard_Density_sand_IV":
        name = "Density Sand";
        imageIcon = iconGradeVII;
        break;
      case "Bsen196_iso679_V":
        name = "BSEN 196";
        imageIcon = iconGradeIV;
        break;
      case "Astm_c778_grade_sand_VI":
        name = "ASTM C-778";
        imageIcon = iconGradeV;
        break;
      case "Astm_20_30_sand_VII":
        name = "ASTM 20/30";
        imageIcon = iconGradeVI;
        break;
      case "Bs_1377_density_sand_VIII":
        name = "BS 1377";
        imageIcon = iconGradeVIII;
        break;
      case "Astm_d1556m_15_density_sand_IX":
        name = "ASTM D1556";
        imageIcon = iconGradeIX;
        break;
    }
    return { name, imageIcon };
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setDispatchId(id);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    const req = {
      ...values,
      dispatch_id: dispatchId,
    };
    dispatch(actionAddDispatchBag({ req, setIsModalOpen }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // useEffect(() => {
  //   console.log({ bags: dispatchDetail.bags });
  // }, [dispatchDetail]);

  const add = () => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9261 16.875H2.82359C2.5575 16.8751 2.29443 16.8186 2.05188 16.7092C1.80932 16.5998 1.59282 16.44 1.41678 16.2405C1.24073 16.041 1.10917 15.8063 1.03084 15.552C0.95251 15.2977 0.929199 15.0296 0.96246 14.7656L2.08746 5.76562C2.1451 5.3126 2.36587 4.89612 2.70845 4.59413C3.05102 4.29214 3.49191 4.12536 3.94859 4.125H11.8011C12.2578 4.12537 12.6988 4.29221 13.0413 4.59427C13.3839 4.89633 13.6047 5.31291 13.6622 5.766L14.1606 9.76725C14.1667 9.81613 14.1631 9.86572 14.15 9.91321C14.1369 9.96069 14.1146 10.0051 14.0843 10.044C14.0541 10.0829 14.0164 10.1154 13.9736 10.1397C13.9308 10.164 13.8836 10.1797 13.8347 10.1858C13.7858 10.1918 13.7362 10.1882 13.6888 10.1751C13.6413 10.1621 13.5968 10.1397 13.558 10.1095C13.5191 10.0792 13.4866 10.0416 13.4623 9.99878C13.4379 9.95595 13.4223 9.90875 13.4162 9.85987L12.9178 5.859C12.8835 5.58711 12.7511 5.3371 12.5455 5.15592C12.3398 4.97474 12.0751 4.87485 11.8011 4.875H3.94859C3.67453 4.87485 3.40984 4.97474 3.20422 5.15592C2.9986 5.3371 2.86618 5.58711 2.83184 5.859L1.70684 14.859C1.68713 15.0174 1.70129 15.1781 1.74839 15.3306C1.79548 15.4831 1.87443 15.6239 1.98001 15.7436C2.08559 15.8633 2.21539 15.9592 2.36082 16.0249C2.50625 16.0907 2.66398 16.1248 2.82359 16.125H12.9261C12.9741 16.1159 13.0234 16.1165 13.0713 16.1265C13.1191 16.1366 13.1645 16.156 13.2048 16.1836C13.2451 16.2112 13.2795 16.2465 13.3062 16.2875C13.3328 16.3285 13.3511 16.3743 13.36 16.4224C13.3778 16.5207 13.3564 16.6221 13.3003 16.7048C13.2442 16.7874 13.1579 16.8448 13.06 16.8645C13.0157 16.872 12.9709 16.8755 12.9261 16.875Z"
        fill="#919DA9"
        stroke="#919DA9"
        stroke-width="0.1"
      />
      <path
        d="M10.5 7.5C10.4005 7.5 10.3052 7.46049 10.2348 7.39017C10.1645 7.31984 10.125 7.22446 10.125 7.125V4.125C10.125 3.52826 9.88795 2.95597 9.46599 2.53401C9.04403 2.11205 8.47174 1.875 7.875 1.875C7.27826 1.875 6.70597 2.11205 6.28401 2.53401C5.86205 2.95597 5.625 3.52826 5.625 4.125V7.125C5.625 7.22446 5.58549 7.31984 5.51516 7.39017C5.44484 7.46049 5.34946 7.5 5.25 7.5C5.15054 7.5 5.05516 7.46049 4.98484 7.39017C4.91451 7.31984 4.875 7.22446 4.875 7.125V4.125C4.875 3.32935 5.19107 2.56629 5.75368 2.00368C6.31629 1.44107 7.07935 1.125 7.875 1.125C8.67065 1.125 9.43371 1.44107 9.99632 2.00368C10.5589 2.56629 10.875 3.32935 10.875 4.125V7.125C10.875 7.22446 10.8355 7.31984 10.7652 7.39017C10.6948 7.46049 10.5995 7.5 10.5 7.5ZM13.125 16.875C12.3833 16.875 11.6583 16.6551 11.0416 16.243C10.4249 15.831 9.94428 15.2453 9.66045 14.5601C9.37662 13.8748 9.30236 13.1208 9.44705 12.3934C9.59175 11.666 9.9489 10.9978 10.4733 10.4733C10.9978 9.9489 11.666 9.59175 12.3934 9.44705C13.1208 9.30236 13.8748 9.37662 14.5601 9.66045C15.2453 9.94428 15.831 10.4249 16.243 11.0416C16.6551 11.6583 16.875 12.3833 16.875 13.125C16.8739 14.1192 16.4785 15.0724 15.7754 15.7754C15.0724 16.4785 14.1192 16.8739 13.125 16.875ZM13.125 10.125C12.5317 10.125 11.9516 10.3009 11.4583 10.6306C10.9649 10.9602 10.5804 11.4288 10.3534 11.9769C10.1263 12.5251 10.0669 13.1283 10.1826 13.7103C10.2984 14.2922 10.5841 14.8268 11.0037 15.2463C11.4232 15.6659 11.9578 15.9516 12.5397 16.0674C13.1217 16.1831 13.7249 16.1237 14.2731 15.8966C14.8212 15.6696 15.2898 15.2851 15.6194 14.7917C15.9491 14.2984 16.125 13.7183 16.125 13.125C16.1241 12.3296 15.8077 11.5671 15.2453 11.0047C14.6829 10.4423 13.9204 10.1259 13.125 10.125Z"
        fill="#919DA9"
        stroke="#919DA9"
        stroke-width="0.1"
      />
      <path
        d="M13.125 14.625C13.0255 14.625 12.9302 14.5855 12.8598 14.5152C12.7895 14.4448 12.75 14.3495 12.75 14.25V12C12.75 11.9005 12.7895 11.8052 12.8598 11.7348C12.9302 11.6645 13.0255 11.625 13.125 11.625C13.2245 11.625 13.3198 11.6645 13.3902 11.7348C13.4605 11.8052 13.5 11.9005 13.5 12V14.25C13.5 14.3495 13.4605 14.4448 13.3902 14.5152C13.3198 14.5855 13.2245 14.625 13.125 14.625Z"
        fill="#919DA9"
        stroke="#919DA9"
        stroke-width="0.1"
      />
      <path
        d="M14.25 13.5H12C11.9005 13.5 11.8052 13.4605 11.7348 13.3902C11.6645 13.3198 11.625 13.2245 11.625 13.125C11.625 13.0255 11.6645 12.9302 11.7348 12.8598C11.8052 12.7895 11.9005 12.75 12 12.75H14.25C14.3495 12.75 14.4448 12.7895 14.5152 12.8598C14.5855 12.9302 14.625 13.0255 14.625 13.125C14.625 13.2245 14.5855 13.3198 14.5152 13.3902C14.4448 13.4605 14.3495 13.5 14.25 13.5Z"
        fill="#919DA9"
        stroke="#919DA9"
        stroke-width="0.1"
      />
    </svg>
  );

  return (
    <div className="flex justify-center h-full max-h-screen gatepass-container">
      <div className="bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden">
        <Row
          justify={"center"}
          align={"middle"}
          className="border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]"
        >
          <Col span={3}>
            <p className="font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5">
              Dispatches
            </p>
          </Col>
          <Col
            className="flex gap-x-2 mx-2"
            xxl={12}
            lg={16}
            sm={16}
            xl={16}
            align="end"
          >
            <Select
              className="dispatch-select"
              showSearch
              allowClear
              placeholder="Select Status"
              onClear={() => setStatus("")}
              onChange={(val) => setStatus(val)}
              options={[
                {
                  value: "DRAFT",
                  label: "Draft",
                },
                {
                  value: "DISPATCH",
                  label: "Dispatch",
                },
              ]}
            />
            <DatePicker
              placeholder="Select Date"
              className="w-full "
              onChange={handleGetWithDate}
            />
            <Input
              allowClear
              className="custom-search "
              placeholder="Search here..."
              onChange={(e) => setSearch(e.target.value)}
              suffix={<img src={searchImg} alt="search" />}
            />
          </Col>
        </Row>
        <div className="flex-1 overflow-hidden  px-6 py-4 scrollbar-container">
          <Table className="table-scrollbar"
            columns={columns}
            dataSource={data}
            loading={dispatchLoader || updateDispatchStatusLoader}
            rowClassName={(record) =>
              record.key === selectedRowKey ? "selected-row" : ""
            }
            onRow={(record) => ({
              onClick: () => {
                if (record.key !== selectedRowKey) {
                  console.log(record.key);
                  dispatch(actionGetDispatchDetail(record.id));
                  setSelectedRowKey(record.key);
                }
              },
            })}
            pagination={false}
            scroll={{
              x: "max-content",
              y: window.innerWidth < 1500 ? "65vh" : "70vh",
            }}
          />
        </div>
        <div
          className=" flex items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] border-t border-t-custom-gray border-opacity-[30%] px-5"
          align={"center"}
        >
          <Link to="/add-dispatch-batch">
            {" "}
            <Button className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]">
              Add New Dispatch
            </Button>
          </Link>
          <div className="flex justify-end" id="custom-pagination">
            <Pagination
              hideOnSinglePage
              className="flex items-center"
              {...pagination}
              showSizeChanger
              showQuickJumper
              pageSizeOptions={["10", "20", "30"]}
              total={dispatchCount}
              onChange={(page, pageSize) => handleTableChange(page, pageSize)}
            />
          </div>
        </div>
      </div>

      <div className="bg-[#FFFFFF] border-l border-l-[#919da94d] w-[30%] flex flex-col ">
        <SideCardHeader />
        {dispatchCount ? (
          <div className="overflow-auto custom-scrollbar  px-1 flex-1 py-1">
            <Spin spinning={dispatchDetailLoader}>
              <div className="px-4  ">
                <div className=" flex items-center justify-between w-full border-b py-4 border-opacity-[30%] border-custom-gray">
                  <div className="flex items-center justify-center mb-0 ">
                    <Avatar
                      shape="square"
                      size={45}
                      icon={
                        <p className="mb-0">
                          {dispatchDetail?.company?.business_name
                            ?.slice(0, 2)
                            ?.toUpperCase()}
                        </p>
                      }
                    />
                  </div>
                  <div className="flex-col justify-stretch items-center ml-3 ">
                    <p className="text-primary 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold lg:text-[14px] ">
                      {dispatchDetail?.company?.business_name}
                      <span className='text-[12px] font-normal'>{` ( ${dispatchDetail?.bags?.length || 0} Bags )`}</span>
                    </p>
                    <p className="text-[#919DA9] 2xl:text-[10px] xl:text-[10px] font-gilroyMedium lg:text-[10px] text-[10px]">{`${moment(
                      dispatchDetail?.company?.date
                    ).format("DD-MM-YYYY")} | ${dispatchDetail?.invoice_number
                      } | ${dispatchDetail?.delivery_place}`}</p>
                  </div>
                  <div className="ml-auto flex items-center justify-center w-[100px]">
                    {dispatchDetail &&
                      dispatchDetail.status &&
                      dispatchDetail.status === "DRAFT" && (
                        <Popconfirm
                          okButtonProps={{
                            loading: deletedispatchLoader,
                          }}
                          placement="bottom"
                          title="Are you sure you want to delete this Dispatch?"
                          onConfirm={() =>
                            confirm(
                              dispatchData &&
                              dispatchData.dispatchDetail &&
                              dispatchData?.dispatchDetail?.id
                            )
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <div className="border-[#FF5A26] border h-auto cursor-pointer 2xl:p-3 xl:p-2 p-1 ml-auto  flex justify-center items-center rounded-[15px] ">
                            <img
                              src={deleteIconn}
                              alt="logout"
                            // width={"20px"}
                            // height={"20px"}
                            />
                          </div>
                        </Popconfirm>
                      )}
                  </div>
                </div>
                {dispatchDetail &&
                  dispatchDetail.bags &&
                  dispatchDetail.bags.length > 0 ? (
                  <div className="mt-4">
                    <Space direction="vertical" className="w-full">
                      {certificates &&
                        certificates.length > 0 &&
                        certificates.map(
                          (c, index) =>
                            dispatchDetail &&
                            dispatchDetail.bags &&
                            dispatchDetail.bags.length > 0 &&
                            dispatchDetail.bags.filter((b) => b.type === c.type)
                              .length > 0 && (
                              <Collapse
                                key={index}
                                expandIconPosition="end"
                                expandIcon={(panelProps) => {
                                  const { isActive } = panelProps;
                                  return isActive ? (
                                    <img src={up} alt="up" />
                                  ) : (
                                    <img src={down} alt="down" />
                                  );
                                }}
                                collapsible="header"
                                defaultActiveKey={[0]} // Set the defaultActiveKey to [0] for the first element
                                items={[
                                  {
                                    key: c.id,
                                    label: (
                                      <div className="flex items-center">
                                        <img
                                          src={
                                            getBagNameFromType(c.type).imageIcon
                                          }
                                          alt="icon"
                                          className="mr-3"
                                        />
                                        <p className="text-[#000] 2xl:text-[20px] xl:text-[18px] text-[17px] font-gilroyMedium font-[400]">
                                          {c.name} ({" "}
                                          {dispatchDetail &&
                                            dispatchDetail.bags &&
                                            dispatchDetail.bags.length > 0 &&
                                            dispatchDetail.bags.filter(
                                              (b) => b.type === c.type
                                            ).length}{" "}
                                          Bags Added )
                                        </p>
                                      </div>
                                    ),
                                    children: (
                                      <>
                                        {dispatchDetail &&
                                          dispatchDetail.bags &&
                                          dispatchDetail.bags.length > 0 &&
                                          dispatchDetail.bags.map(
                                            (bag) =>
                                              bag.type === c.type && (
                                                <div className="border border-[#B2BCCF] flex items-center justify-between p-2 mb-1.5 rounded-lg font-gilroyMedium">
                                                  <div className="flex items-center">
                                                    <img
                                                      src={
                                                        getBagNameFromType(
                                                          c.type
                                                        ).imageIcon
                                                      }
                                                      className="mr-2"
                                                      width={25}
                                                    />
                                                    <p className="text-[#2B2A28] text-[14px]">
                                                      {bag.batch_number}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <Popconfirm
                                                      okButtonProps={{
                                                        loading:
                                                          dispatchData
                                                            .deletedispatchBagLoader[
                                                          bag.id
                                                          ],
                                                      }}
                                                      placement="bottom"
                                                      title="Are you sure you want to delete Bag?"
                                                      onConfirm={() =>
                                                        handleDeleteBag(
                                                          bag.id,
                                                          dispatchDetail?.id
                                                        )
                                                      }
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      {" "}
                                                      <p className="text-[#FF3C3C] cursor-pointer text-[12px]">
                                                        Delete
                                                      </p>
                                                    </Popconfirm>
                                                  </div>
                                                </div>
                                              )
                                          )}
                                      </>
                                    ),
                                  },
                                ]}
                              />
                            )
                        )}
                    </Space>
                  </div>
                ) : (
                  <div className="mt-10">
                    <Empty
                      description="No Bags"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </div>
                )}
              </div>
            </Spin>
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="pt-10 flex items-center justify-center flex-col"
          />
        )}
      </div>

      <Modal
        footer={false}
        afterClose={() => {
          setIsModalOpen(false);
          setDispatchId(null);
          form.resetFields();
        }}
        title="Add Bag"
        className="dispatch-modal"
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          className="lot-form"
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[16, 0]} className="graed  mt-3">
            <Col span={24}>
              <Form.Item
                label="Test Report no"
                name="batch_number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Test Repost no!",
                  },
                ]}
              >
                <Input className="" placeholder="Enter Test Report no" />
              </Form.Item>
            </Col>
          </Row>

          <div className="footer z-0  flex items-center gap-x-2 bottom-0 w-full   bg-transparent  py-3">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                htmlType="submit"
                loading={addBagLoader}
                className="outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px]"
                style={{ padding: "5px 20px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Dispatches;
