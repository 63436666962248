import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web.config";

//Get Gatepass
export const actionGetGatepass = createAsyncThunk('actionGetGatepass', async ({ limit, offset, keyword }) => {
    try {
        const res = await axios.get(`${BASE_URL}/gatepass?limit=${limit}&offset=${offset}${keyword ? "&keyword=" + keyword : ""}&order=DESC`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            return res.data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Get Gatepass Detail
export const actionGetGatepassDetail = createAsyncThunk('actionGetGatepassDetail', async (id) => {
    try {
        const res = await axios.get(`${BASE_URL}/gatepass/${id}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Create Gatepass
export const actionCreateGatepass = createAsyncThunk('actionCreateGatepass', async (data) => {
    const { req, navigate } = data;
    try {
        const res = await axios.post(`${BASE_URL}/gatepass`, req);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/gatepass');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})

//Update Gatepass
export const actionUpdateGatepass = createAsyncThunk('actionUpdateGatepass', async (data) => {
    const { request, id, navigate } = data;
    try {
        const res = await axios.patch(`${BASE_URL}/gatepass/${id}`, request);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/gatepass');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})

// Delete Gatepass
export const actionDeleteGatepass = createAsyncThunk('actionDeleteGatepass', async (id) => {
    try {
        const res = await axios.delete(`${BASE_URL}/gatepass/${id}`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            return id;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

