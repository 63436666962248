import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Checkbox,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import india from "../../assets/img/all/india.svg";
import usersvg from "../../assets/img/dashboard/user.svg";
import { actionCreateMember } from "../../store/services/memberService";
import cetrificate from "../../assets/img/dashboard/cetrificate.svg";
import { actionGetCertificate } from "../../store/services/memberService";
import { actionGetRoles } from "../../store/services/rbacService";
import SideCardHeader from "../common/SideCardHeader";
import { BASE_URL_UPLOAD } from "../config/web.config";
import moment from "moment";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const AddMember = () => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const errorRef = useRef(null);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleData = useSelector((state) => state.rbac);
  const certificateData = useSelector((state) => state.member);
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(info.file.response.file.path);
      });
    }
  };

  const uploadButton = (
    <button type="button">
      {loading ? (
        <LoadingOutlined className="text-[#919DA9]" />
      ) : (
        <PlusOutlined className="text-[#919DA9]" />
      )}
      <div className="text-[16px] text-[#919DA9] font-gilroyMedium">Upload</div>
    </button>
  );

  useEffect(() => {
    dispatch(actionGetRoles({ limit: 10, offset: 0, getall: "YES" }));
    dispatch(actionGetCertificate());
  }, []);

  const onFinish = (val) => {
    console.log(val, "form values");
    const formatedDate = moment(dateOfBirth).format("YYYY-MM-DD");
    const request = {
      ...val,
      // roles: [val.roles],
      profile_pic:
        typeof val.profile_pic === "string"
          ? val.profile_pic
          : imageUrl
          ? imageUrl?.split("/").reverse()[0]
          : "",
      date_of_birth: val.date_of_birth ? formatedDate : "",
      certificates: val.certificates ? certificates : null,
    };
    dispatch(actionCreateMember({ request, navigate }));
  };
  const arr = [];

  roleData &&
    roleData.roles &&
    roleData.roles.length > 0 &&
    roleData.roles?.map((d) => {
      arr.push({
        value: d.id,
        label: <p className="capitalize">{d.name}</p>,
      });
    });

  const onChange = (date, dateString) => {
    setDateOfBirth(dateString);
  };
  const discardAgency = () => {
    navigate("/system-management");
  };
  const onChangeCheck = (checkedValues) => {
    setCertificates(checkedValues);
  };

  const onFinishFailed = (e) => {
    console.log({ e });
    // message.error("Please Select atleast one certificate!")
    if (
      certificates &&
      certificates.length > 0 &&
      !certificates.some((c) => c.isSelected)
    ) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
  };

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="h-full relative max-h-screen gatepass-container overflow-hidden"
    >
      <div className="flex justify-center h-full ">
        {/* CONTENT SECTION STARTS  */}
        <div className="bg-main w-[70%] relative table-scrollbar flex flex-col overflow-hidden">
          <Row
            justify={"center"}
            align={"middle"}
            className="border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]"
          >
            <Col span={12}>
              <p className="font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5">
                Add Member
              </p>
            </Col>
          </Row>
          <div ref={errorRef} className="flex-1 overflow-auto  px-6 py-4 ">
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label="Member Type"
                  name="roles"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Member Type!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    loading={roleData.rolesLoader}
                    showSearch
                    className="capitalize"
                    placeholder="Select Member Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={arr}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <div className="flex items-center gap-x-2 mb-4">
                  <img src={usersvg} alt="usersvg" />{" "}
                  <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                    Member Detail
                  </p>
                </div>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="First name"
                  name="first_name"
                  normalize={(value) => value.replace(/[^a-zA-Z]/g, "")}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter First Name!",
                    },
                  ]}
                >
                  <Input
                    className="custom-input-group"
                    placeholder="Enter First Name"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last name"
                  name="last_name"
                  normalize={(value) => value.replace(/[^a-zA-Z]/g, "")}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Last Name!",
                    },
                  ]}
                >
                  <Input
                    className="custom-input-group"
                    placeholder="Enter Last Name"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Email Address!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email Address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone number"
                  name="phone"
                  normalize={(value) => value.replace(/[^0-9]/g, "").trim()}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Phone Number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length !== 10) {
                          return Promise.reject(
                            "Please Enter Valid Phone number!"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    controls={false}
                    placeholder="Enter Phone Number"
                    className="rounded-0 w-full border-0 country-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Gender!",
                    },
                  ]}
                >
                  <Select
                    loading={roleData.rolesLoader}
                    className="capitalize"
                    placeholder="Select Gender"
                    options={[
                      {
                        value: "Male",
                        label: "Male",
                      },
                      {
                        value: "Female",
                        label: "Female",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Password!",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length < 8) {
                          return Promise.reject(
                            "Password must be at least 8 characters long!"
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Enter Password" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  dependencies={["password"]}
                  label="Re-enter Password"
                  name="cpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please Re-enter Password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Enter Re-enter Password" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="profile_pic"
                  label="Profile Picture"
                  // rules={[
                  //     {
                  //         required: imageUrl ? false : true,
                  //         message: !imageUrl && "Please upload profile picture!",
                  //     },
                  // ]}
                >
                  <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      accept="image/png, image/jpeg ,image/png, image/jpg"
                      // beforeUpload={beforeUpload}
                      headers={{
                        Authorization:
                          "Bearer " +
                          localStorage.getItem("vytechQmsBackendJwtToken"),
                      }}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          className="p-1"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="flex items-center gap-x-2 mb-4">
                  <img src={cetrificate} alt="cetrificate" />{" "}
                  <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                    Certificate Permission
                  </p>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="certificates"
                  rules={[
                    {
                      required: true,
                      message: "Please select atleast one certificate!",
                    },
                  ]}
                >
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={onChangeCheck}
                    className="custom-checkbox"
                    value={certificates}
                  >
                    <Row gutter={[16, 8]}>
                      {certificateData &&
                        certificateData.certificates &&
                        certificateData.certificates.length > 0 &&
                        certificateData.certificates.map((certificate) => (
                          <Col span={12} key={certificate.id}>
                            <Checkbox value={certificate.id}>
                              {certificate.name}
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </div>
          {/* Footer Start */}
          <div className="footer z-0 bg-main sticky flex items-center px-4 gap-x-2 bottom-0 w-full  border-t border-t-[rgba(145, 157, 169, 0.3)]  bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                htmlType="submit"
                className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2"
              >
                Create Member
              </Button>
            </Form.Item>
            <Button
              onClick={discardAgency}
              className="warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px]"
            >
              Discard Entry
            </Button>
          </div>

          {/* FOOTER SECTION ENDS  */}
        </div>

        {/* SIDEBAR START HERE */}
        {/* <div className='bg-[#FFFFFF] border-l border-l-[#919da94d] w-[30%] flex flex-col '> */}
        <div className="bg-main border-l  border-l-[#919da94d] w-[30%] flex flex-col ">
          <SideCardHeader />

          <div className="overflow-auto custom-scrollbar  px-1 flex-1 py-1">
            <div className="px-4 pt-5" id="custom-increment-input">
              <div className="flex items-center gap-x-2 mb-4">
                <img src={usersvg} alt="usersvg" />{" "}
                <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                  Additional Details
                </p>
              </div>
            </div>
            <Row gutter={[16, 0]} className="px-4">
              <Col span={24}></Col>
              <Col span={24}>
                <Form.Item
                  label="Date of Birth"
                  name="date_of_birth"
                  rules={[
                    {
                      required: false,
                      message: "Please Select Date!",
                    },
                  ]}
                >
                  <DatePicker className="w-full" onChange={onChange} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        {/* SIDEBAR ENDS HERE */}
      </div>
    </Form>
  );
};

export default AddMember;
