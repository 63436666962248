import { Button, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import lock from "../../assets/img/dashboard/lock.svg";
import logonew from '../../assets/img/dashboard/logonew.svg';
import email from "../../assets/img/dashboard/emailIcon.svg";
import { actionForgotPassword } from '../../store/services/authService';

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const forgotStatus = useSelector(state => state.auth)

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onFinish = (values) => {
        dispatch(actionForgotPassword({ values, navigate }));
        console.log(values, "values")
    };
    console.log(forgotStatus);

    return (
        <div className=' flex justify-center py-10 items-center h-screen max-h-screen ' id="login-container-new">
            <div className='login-card shadow-[0_0px_15px_0px_rgba(0,0,0,0.10)] 2xl:p-10 xl:p-8 lg:p-8  p-4 pb-10 md:my-3 sm:mx-5 bg-[#fff] rounded-2xl '>

                <div className='pb-5 2xl:pb-10 xl:pb-8 lg:pb-6'>
                    <img src={logonew} placeholder="Logo" className='mx-auto w-[200px]' />
                </div>
                <p className='font-Glegoo 2xl:text-[36px] xl:text-[30px] lg:text-[30px] text-[25px] font-[700] ' >Forgot Password</p>
                <Form className='mt-3 2xl:mt-6 xl:mt-5 lg:mt-4'
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                >
                    <Form.Item className='text-left'
                        // label="Username"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input placeholder='Email' prefix={<img src={email} alt="user" className='pr-2 border-r' />} className='login-input py-[0px] border border-[#919da9]' />
                    </Form.Item>
                    <p className='custom-login-text custom-sixt font-gilroyMedium w-[80%] mx-auto mt-2  capitalize text-[#2B2A28]'
                    >Provide your account email address to receive an email to reset your password.</p>
                    <Form.Item>
                        <Button htmlType="submit"
                            loading={forgotStatus?.forgotPasswordLoader}
                            className='custom-sixt mt-4'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword