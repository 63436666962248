import { createSlice } from "@reduxjs/toolkit";
import { actionDeleteGatepass, actionGetGatepass, actionGetGatepassDetail, actionUpdateGatepass } from "../services/gatepassService";

const gatepassService = createSlice({
    name: 'gatepass',
    initialState: {
        gatepasses: "",
        getGetapassLoader: false,
        gatepassCount: "",
        DeleteGatepassLoader: false,
        gatepassDetail: "",
        gatepassDetailLoader: false,
        UpdateGatepassLoader: false,
    }
    ,
    reducers: {},
    extraReducers: (builder) => {
        // Get gatepass
        builder
            .addCase(actionGetGatepass.pending, (state) => {
                state.getGetapassLoader = true;
            })
            .addCase(actionGetGatepass.fulfilled, (state, action) => {
                state.gatepasses = action?.payload?.data;
                state.gatepassCount = action?.payload?.total_records;
                state.getGetapassLoader = false;
            })

            .addCase(actionGetGatepass.rejected, (state) => {
                state.getGetapassLoader = false;
            })

            // Get Single gatepass
            .addCase(actionGetGatepassDetail.pending, (state) => {
                state.gatepassDetailLoader = true;
            })
            .addCase(actionGetGatepassDetail.fulfilled, (state, action) => {
                state.gatepassDetail = action.payload;
                state.gatepassDetailLoader = false;
            })
            .addCase(actionGetGatepassDetail.rejected, (state) => {
                state.gatepassDetailLoader = false;
            })

            // Update gatepass
            .addCase(actionUpdateGatepass.pending, (state) => {
                state.UpdateGatepassLoader = true;
            })
            .addCase(actionUpdateGatepass.fulfilled, (state, action) => {
                state.UpdateGatepassLoader = false;
            })
            .addCase(actionUpdateGatepass.rejected, (state) => {
                state.UpdateGatepassLoader = false;
            })

            // Delete gatepass
            .addCase(actionDeleteGatepass.pending, (state) => {
                state.DeleteGatepassLoader = true;
            })
            .addCase(actionDeleteGatepass.fulfilled, (state, action) => {
                state.gatepasses = state.gatepasses.filter(gatepass => gatepass.id !== action.payload)
                state.DeleteGatepassLoader = false;
            })
            .addCase(actionDeleteGatepass.rejected, (state) => {
                state.DeleteGatepassLoader = false;
            })

    }
})

export default gatepassService.reducer;
