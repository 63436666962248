import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web.config";
import { deleteSuccess } from "../slices/lotSlice";
import { handleGetResponse } from "./commonService";

// get batch for table
export const actionGetBatchs = createAsyncThunk(
  "actionGetBatchs",
  async ({ limit, offset, keyword, date, manufacture_time }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/batch?limit=${limit}&offset=${offset}${
          keyword ? "&keyword=" + keyword : ""
        }${date ? "&date=" + date : ""}${
          manufacture_time ? "&manufacture_time=" + manufacture_time : ""
        }`
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        return res.data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);
// get Single Batch for table
export const actionGetBatchDetail = createAsyncThunk(
  "actionGetBatchDetail",
  async (data) => {
    const { id, offset, limit, screen, type } = data;
    try {
      const res = await axios.get(
        `${BASE_URL}/batch/${id}?limit=${limit}&offset=${offset}${
          screen ? "&screen=" + screen : ""
        }${type ? "&type=" + type : ""}`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return { data, offset };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        message.error(customMsg, 5);
        return "";
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Get Machines
export const getMachine = createAsyncThunk(
  "getMachine",
  async ({ limit, keyword, offset, getall }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/machines?limit=${limit}&offset=${offset}${
          keyword ? "&keyword=" + keyword : ""
        }${getall ? "&getall=" + getall : ""}&orderByColumn=NAME`
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        return res.data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        message.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

export const getSingleMachines = createAsyncThunk(
  "getSingleMachine",
  async (id) => {
    // return handleGetResponse("machines?$getall=YES&orderByColumn=name&order=ASC");
    return handleGetResponse(`machines/${id}`);
  }
);

// Delete Machines
export const actionDeleteMachine = createAsyncThunk(
  "actionDeleteMachine",
  async (id, { dispatch }) => {
    try {
      const res = await axios.delete(`${BASE_URL}/machines/${id}`);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        dispatch(deleteSuccess());
        return id;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);
// add Machine
export const actionAddMachine = createAsyncThunk(
  "actionAddMachine",
  async (data) => {
    const { val, setModalOpen, form, setMachineId } = data;
    try {
      // Type Check
      const res = await axios.post(`${BASE_URL}/machines`, val);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        setModalOpen && setModalOpen(false);
        form && form.resetFields();
        setMachineId && setMachineId("");
        return data;
      } else {
        // message.error(customMsg, 5);
        throw new Error(`${customMsg}`);
      }
    } catch (error) {
      message.error(error?.response?.data.message, 5);
      throw new Error(`API Error: ${error?.message}`);
    }
  }
);

export const actionUpdateMachine = createAsyncThunk(
  "actionUpdateMachine",
  async (data) => {
    const { val, setModalOpen, form, machineId } = data;
    try {
      // Type Check
      const res = await axios.patch(`${BASE_URL}/machines/${machineId}`, val);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);

        setModalOpen && setModalOpen(false);
        form && form.resetFields();
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Add Bag
export const actionAddBags = createAsyncThunk("actionAddBags", async (data) => {
  const { req, form, setSelectedValue } = data;
  try {
    const res = await axios.post(`${BASE_URL}/batch/add-bag`, req);
    const { status, message: customMsg, data } = res.data;
    if (parseInt(status) === 200) {
      message.success(customMsg, 5);
      form && form.resetFields();
      setSelectedValue && setSelectedValue("");
      return data;
    } else {
      message.error(customMsg, 5);
      throw new Error(`API Error: ${message}`);
    }
  } catch (error) {
    message.error(error?.message, 5);
    throw Error(error.message);
  }
});

// get temp bags sidebar
export const getTempBags = createAsyncThunk(
  "getTempBags",
  async ({ limit, offset }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/batch/temp-bags?limit=${limit}&offset=${offset}`
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        return { data: res.data, offset };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        message.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

export const actionGetBagDetail = createAsyncThunk(
  "actionGetBagDetail",
  async (batchnum) => {
    const url = encodeURIComponent(batchnum);
    return handleGetResponse(`batch/bag/${url}`);
  }
);
export const actionGetTempBagDetail = createAsyncThunk(
  "actionGetTempBagDetail",
  async (batchnum) => {
    const url = encodeURIComponent(batchnum);
    return handleGetResponse(`batch/temp-bag/${url}`);
  }
);

// add batch of all bags
export const actionAddBatch = createAsyncThunk(
  "actionAddBatch",
  async ({ req, navigate, setTime }) => {
    try {
      const res = await axios.post(`${BASE_URL}/batch`, req);
      const { status, message: customMsg, data } = res.data;
      console.log(parseInt(status) === 200, "Status", res);
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        navigate("/batch");
        setTime(null);
        return data;
      } else {
        console.log("from else", status, customMsg);
        throw new Error(customMsg);
      }
    } catch (error) {
      console.log(error?.message);
      if (error.response.data.statusCode == 429) {
        message.error("To many Request! Please try again after 1min!", 5);
        throw new Error(`To many Request! Please try again after 1min!)`);
      } else {
        message.error(error?.message, 5);
        throw new Error(error?.message);
      }
    }
  }
);

//discard batch of all bags
export const actionDiscardBatch = createAsyncThunk(
  "actionDiscardBatch",
  async (req) => {
    try {
      const res = await axios.post(`${BASE_URL}/batch/discard-batch`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        //         navigate('lot');
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Delete temp bag
export const actionDeleteBag = createAsyncThunk(
  "actionDeleteBag",
  async ({ id, sandType }) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/batch/temp-bag/${id}?type=${sandType}`
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        return id;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Update bag
export const actionUpdateBag = createAsyncThunk(
  "actionUpdateBag",
  async (data) => {
    const { id, val, form, setModalVisible } = data;
    try {
      const res = await axios.patch(`${BASE_URL}/batch/bag/${id}`, val);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        setModalVisible && setModalVisible(false);
        form && form.resetFields();
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Update Temp bag
export const actionUpdatTempBag = createAsyncThunk(
  "actionUpdateBag",
  async (data) => {
    const { id, type, val } = data;
    try {
      const res = await axios.patch(
        `${BASE_URL}/batch/bag/${id}?type=${type}`,
        val
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        /* return id; */
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

export const actionDownloadBags = createAsyncThunk(
  "actionDownloadBags",
  async ({ id, type, screen, limit, offset }, { dispatch }) => {
    let queryString = "";
    screen && (queryString += `screen=${screen}&`);
    type && (queryString += `type=${type}`);
    try {
      const response = await axios({
        url: `${BASE_URL}/batch/print-bags/${id}${
          queryString ? "?" + queryString : ""
        }`,
        method: "GET",
        responseType: "blob",
      });
      console.log({ response });
      dispatch(actionGetBatchDetail({ id: id, limit, offset, type, screen }));
      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  }
);

export const actionDownloadSingleBag = createAsyncThunk(
  "actionDownloadSingleBag",
  async ({ id, batch_number }) => {
    try {
      const response = await axios({
        url: `${BASE_URL}/batch/print-bags/${id}${
          batch_number
            ? "?batch_number=" + encodeURIComponent(batch_number)
            : ""
        }`,
        method: "GET",
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      message.error(error, 5);
      console.error("Error downloading PDF:", error);
    }
  }
);

export const actionChangeBatchStatus = createAsyncThunk(
  "actionChangeBatchStatus",
  async (data) => {
    const { id, updatedStatus } = data;
    try {
      const res = await axios.patch(`${BASE_URL}/batch/status/${id}`, {
        status: updatedStatus,
      });
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
      throw new Error(`API Error: ${error?.message}`);
    }
  }
);
