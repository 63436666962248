import { deleteSuccess } from "../slices/dispatchSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web.config";

// Action to get all Dispatch
export const actionGetDispatches = createAsyncThunk(
  "actionGetDispatches",
  async ({ limit, offset, keyword, date, s }) => {
    console.log({ s });
    try {
      const res = await axios.get(
        `${BASE_URL}/dispatch?limit=${limit}&offset=${offset}&order=DESC${
          keyword ? "&keyword=" + keyword : ""
        }${date ? "&date=" + date : ""}${s ? "&status=" + s : ""}`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return res.data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

//Get Dispatch Detail
export const actionGetDispatchDetail = createAsyncThunk(
  "actionGetDispatchDetail",
  async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}/dispatch/${id}`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Delete Dispatch
export const actionDeleteDispatch = createAsyncThunk(
  "actionDeleteDispatch",
  async (id, { dispatch, getState }) => {
    try {
      const res = await axios.delete(`${BASE_URL}/dispatch/${id}`);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        dispatch(deleteSuccess());
        return id;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

//Create Dispatch
export const actionCreateDispatch = createAsyncThunk(
  "actionCreateDispatch",
  async (data) => {
    const { req, navigate } = data;
    try {
      const res = await axios.post(`${BASE_URL}/dispatch`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        navigate && navigate("/dispatch-batch");
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

//Update Dispatch
export const actionUpdateDispatch = createAsyncThunk(
  "actionUpdateDispatch",
  async (data) => {
    const { req, id, navigate } = data;
    try {
      const res = await axios.patch(`${BASE_URL}/dispatch/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        navigate && navigate("/dispatch-batch");
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

// Delete Dispatch Bag
export const actionDeleteDispatchBag = createAsyncThunk(
  "actionDeleteDispatchBag",
  async ({ id, dispatchId }, { dispatch, getState }) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/dispatch/bags/${dispatchId}/${id}`
      );
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        return id;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

export const actionAddDispatchBag = createAsyncThunk(
  "actionAddDispatchBag",
  async ({ req, setIsModalOpen }, { dispatch, getState }) => {
    try {
      const res = await axios.post(`${BASE_URL}/dispatch/add-bags`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        setIsModalOpen && setIsModalOpen(false);
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);

//Update Dispatch Status
export const actionUpdateDispatchStatus = createAsyncThunk(
  "actionUpdateDispatchStatus",
  async (data) => {
    const { dispatchStatus, id } = data;
    try {
      const res = await axios.patch(
        `${BASE_URL}/dispatch/update-status/${id}`,
        { status: dispatchStatus }
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        return data;
      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
    }
  }
);
