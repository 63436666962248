import { Button, Col, Form, Input, Row, Spin } from 'antd'
import React, { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionAddBags } from '../../../store/services/lotService';


const GradeASTM2 = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [customValidationError, setCustomValidationError] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const grade = useSelector(state => state.lot);

  const { user } = useSelector(state => state.auth)

  const { machines,addBagLoader, getMachinesLoader } = grade;

  const onFinish = (values) => {
    const isValueSelected = selectedValue.length > 0;
    if (!isValueSelected) {
      setCustomValidationError('Please select a Machine Type.');
    } else {
      setCustomValidationError("");

      const req = {
        ...values,
        type: "Astm_20_30_sand_VII",
        ps_16: values.ps_16 ? parseFloat(values.ps_16).toFixed(2) : "",
        ps_20: values.ps_20 ? parseFloat(values.ps_20).toFixed(2) : "",
        ps_30: values.ps_30 ? parseFloat(values.ps_30).toFixed(2) : "",
        user_id: user?.id,
        screen: selectedValue,
      };

      console.log({ req });
      // Check if selectedValue is not empty before calling the API
      if (isValueSelected) {
        dispatch(actionAddBags({ req, form, setSelectedValue }));
      }
    }
  };

  const onFinishFailed = (err) => {
    if (selectedValue.length <= 0) {
      setCustomValidationError('Please select a Machine Type.');
      console.log("Empty");
    } else {
      setCustomValidationError("");
    }
    console.log({ err });
  }
  return (
    <>
    <div className='rounded-[15px] px-4 py-1 bg-white  my-2 '>
    <Form initialValues={{
          ps_16: 100
        }}
      className="lot-form"
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <div className='text-[20px] text-[#000] mt-3'>
      ASTM 20 / 30 ( SAND )
      </div>
      <div className='text-[#000]'>
            <p className='text-[12px] mt-4 '>Machine Name ( Screen )</p>
            <div className='mt-1 w-auto flex text-[14px] font-semibold'>
              {
                getMachinesLoader ? <Spin className='my-2' size='small'
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: "#00205E"
                      }}
                      spin
                    />
                  }
                /> : machines && machines.length && machines?.map((letter, index) => {
                  return (
                    <div
                      key={letter.id}
                      className={`border-2 cursor-pointer ${((machines.length - 1) === index) ? 'border-r-1' : 'border-r-0'}  border-[#B2BCCF] bg-[#fff] px-3.5 py-1.5 ${selectedValue === letter.name ? 'bg-primary   text-white' : ''
                        }`}
                      onClick={() => {
                        setSelectedValue(letter.name)
                        setCustomValidationError(false)
                      }}
                    >
                      {letter.name}
                    </div>
                  );
                })
              }
            </div>
          </div>
      {customValidationError && (
          <p className="text-red-500 mt-1" >{customValidationError}</p>
        )}
      <Row gutter={[16, 0]} className="graed  mt-3">
        <Col span={10}>
          <Form.Item
            label="Particle Size in #16"
            name="ps_16"
            normalize={(value) =>
              value.replace(/[^0-9.]/g, "").trim()
            }
            rules={[
              {
                required: true,
                message: 'Please Enter Particle Size in #16!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && !(value >= 90.00 && value <= 100.00)) {
                    return Promise.reject('Please enter a valid value between 90.00 and 100.00!');
                  }

                  return Promise.resolve();
                },
              })
            ]}
          >
            <Input className='' placeholder='Enter Particle Size in #16' />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
              label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in #20</p><p className='text-[12px] text-[#717171] text-right'>( 85.00 - 100.00 )</p></div>}
            name="ps_20"
            normalize={(value) =>
              value.replace(/[^0-9.]/g, "").trim()
            }
            rules={[
              {
                required: true,
                message: 'Please Enter Particle Size in #20!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && !(value >= 85.00 && value <= 100.00)) {
                    return Promise.reject('Please enter a valid value between 85.00 and 100.00!');
                  }

                  return Promise.resolve();
                },
              })
            ]}
          >
            <Input className='' placeholder='Enter Particle Size in #20' />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in #30</p><p className='text-[12px] text-[#717171] text-right'>( 0.00 - 5.00 )</p></div>}
            name="ps_30"
            normalize={(value) =>
              value.replace(/[^0-9.]/g, "").trim()
            }
            rules={[
              {
                required: true,
                message: 'Please Enter Particle Size in #30!',
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && !(value >= 0.00 && value <= 5.00)) {
                    return Promise.reject('Please enter a valid value between 0.00 and 5.00!');
                  }

                  return Promise.resolve();
                },
              })
            ]}

          >
            <Input placeholder='Enter Particle Size in #30' />
          </Form.Item>
        </Col>
      </Row>

      <div className='footer z-0  flex items-center gap-x-2 bottom-0 w-full   bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3'>
            <Form.Item style={{ marginBottom: 0 }}>
<Button loading={addBagLoader} disabled={addBagLoader} htmlType="submit" className='outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2'>Add Bag</Button>            </Form.Item>
          </div>
    </Form>
    </div>
  </>
  )
}

export default GradeASTM2