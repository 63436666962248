import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web.config";
import { deleteSuccess } from "../slices/memberSlice";

//Get Member
export const actionGetMembers = createAsyncThunk('actionGetMembers', async ({ limit, offset, keyword, role, getall }) => {
    try {
        const res = await axios.get(`${BASE_URL}/user?limit=${limit}&offset=${offset}${keyword ? "&keyword=" + keyword : ""}${role ? "&role=" + role : ""}${getall ? "&getall=" + getall : ""}&order=DESC`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            return res.data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Get Member Detail
export const actionGetMemberDetail = createAsyncThunk('actionGetMemberDetail', async (id) => {
    try {
        const res = await axios.get(`${BASE_URL}/user/${id}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Create Member
export const actionCreateMember = createAsyncThunk('actionCreateMember', async (data) => {
    const { request, navigate } = data;
    try {
        const res = await axios.post(`${BASE_URL}/user`, request);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/system-management');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})

//Update Member
export const actionUpdateMember = createAsyncThunk('actionUpdateMember', async (data) => {
    const { request, id, navigate } = data;
    try {
        const res = await axios.patch(`${BASE_URL}/user/${id}`, request);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/system-management');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})

// Delete Member
export const actionDeleteMember = createAsyncThunk('actionDeleteMember', async (id, { dispatch }) => {
    try {
        const res = await axios.delete(`${BASE_URL}/user/${id}`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            dispatch(deleteSuccess())
            return id;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Get certificate
export const actionGetCertificate = createAsyncThunk('actionGetCertificate', async () => {
    try {
        const res = await axios.get(`${BASE_URL}/certificates`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            return res.data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

