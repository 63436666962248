import { Button, Col, Form, Input, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionAddBags, actionUpdateBag } from '../../../store/services/lotService';

const EditGradeASTMd = ({ id, setModalVisible }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [customValidationError, setCustomValidationError] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const grade = useSelector(state => state.lot)
  const { user } = useSelector(state => state.auth)

  const { singleBag } = grade;

  useEffect(() => {
    if (singleBag) {
      form.setFieldsValue({
        ss_10: singleBag.ss_10 ? singleBag.ss_10 : "",
        ss_60: singleBag.ss_60 ? singleBag.ss_60 : "",
      });
    }
  }, [singleBag, form]);

  const onFinish = (values) => {
    const req = {
      ...values,
      type: "Astm_d1556m_15_density_sand_IX",
      ss_10: values.ss_10 ? parseFloat(values.ss_10).toFixed(2) : "",
      ss_60: values.ss_60 ? parseFloat(values.ss_60).toFixed(2) : "",
    };
    dispatch(actionUpdateBag({ id, val: req, setModalVisible, form }))

  };

  const onFinishFailed = (err) => {
    if (selectedValue.length <= 0) {
      setCustomValidationError('Please select a Machine Type.');
      console.log("Empty");
    } else {
      setCustomValidationError("");
    }
    console.log({ err });
  }
  return (
    <>
      <div className='rounded-[15px] px-4 py-1 bg-white  my-2 '>
        <Form initialValues={{
          ss_10: 100
        }}
          name="lot-form"
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <div className='text-[16px] text-[#000] mt-3'>
            ASTM D1556/D1556M - 15E1 ( Density SAND )
          </div>

          <Row gutter={[16, 0]} className="graed  mt-3">
            <Col span={24}>
              <Form.Item
                label="Value for Pass From Sieve"
                name="ss_10"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Value for Pass From Sieve!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 90.00 && value <= 100.00)) {
                        return Promise.reject('Please enter a valid value between 90.00 and 100.00!');
                      }

                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input className='' placeholder='Enter Value for Pass From Sieve' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Value for test retain on Sieve</p><p className='text-[12px] text-[#717171] text-right'>( 0.01 - 3.00 )</p></div>}
                name="ss_60"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Value for test retain on Sieve!',
                  },

                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 0.01 && value <= 3.00)) {
                        return Promise.reject('Please enter a valid value between 0.01 and 3.00!');
                      }

                      return Promise.resolve();
                    },
                  })
                ]}

              >
                <Input placeholder='Enter Value for test retain on Sieve' />
              </Form.Item>
            </Col>
          </Row>

          <div className='footer z-0  flex items-center gap-x-2 bottom-0 w-full   bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3'>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button htmlType="submit" className='outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2'>Update  Bag</Button>
            </Form.Item>
          </div>
        </Form></div>
    </>
  )
}

export default EditGradeASTMd