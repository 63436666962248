import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  List,
  Row,
  Segmented,
  Spin,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import bagBig from "../../assets/img/all/bagBig.svg";
import bagBlue from "../../assets/img/all/bagBlue.svg";
import greymoon from "../../assets/img/all/greymoon.svg";
import greysun from "../../assets/img/all/greysun.svg";
import moon from "../../assets/img/all/moon.svg";
import recentg from "../../assets/img/all/recentegatepass.svg";
import sun from "../../assets/img/all/sun.svg";
import activeAstm from "../../assets/img/grade/activeAstm.svg";
import activeAstm2 from "../../assets/img/grade/activeAstm2.svg";
import activeAstm3 from "../../assets/img/grade/activeAstm3.svg";
import activeBs from "../../assets/img/grade/activeBs.svg";
import activeBsen from "../../assets/img/grade/activeBsen.svg";
import activeGrade1 from "../../assets/img/grade/activeGrade1.svg";
import activeGrade2 from "../../assets/img/grade/activeGrade2.svg";
import activeSd from "../../assets/img/grade/activeSd.svg";
import activegrade3 from "../../assets/img/grade/activegrade3.svg";
import astm from "../../assets/img/grade/astm.svg";
import astm2 from "../../assets/img/grade/astm2.svg";
import astm3 from "../../assets/img/grade/astm3.svg";
import bs from "../../assets/img/grade/bs.svg";
import bsen from "../../assets/img/grade/bsen.svg";
import grade1 from "../../assets/img/grade/grade1.svg";
import grade2 from "../../assets/img/grade/grade2.svg";
import grade3 from "../../assets/img/grade/grade3.svg";
import iconGradeI from "../../assets/img/grade/iconGradeI.svg";
import iconGradeII from "../../assets/img/grade/iconGradeII.svg";
import iconGradeIII from "../../assets/img/grade/iconGradeIII.svg";
import iconGradeIV from "../../assets/img/grade/iconGradeIV.svg";
import iconGradeIX from "../../assets/img/grade/iconGradeIX.svg";
import iconGradeV from "../../assets/img/grade/iconGradeV.svg";
import iconGradeVI from "../../assets/img/grade/iconGradeVI.svg";
import iconGradeVII from "../../assets/img/grade/iconGradeVII.svg";
import iconGradeVIII from "../../assets/img/grade/iconGradeVIII.svg";
import sd from "../../assets/img/grade/sd.svg";
import {
  actionAddBatch,
  actionDeleteBag,
  actionDiscardBatch,
  getMachine,
  getTempBags,
} from "../../store/services/lotService";
import SideCardHeader from "../common/SideCardHeader";
import GradeASTM2 from "./grade/GradeASTM2";
import GradeASTMc from "./grade/GradeASTMc";
import GradeASTMd from "./grade/GradeASTMd";
import GradeBS13 from "./grade/GradeBS13";
import GradeBSEN from "./grade/GradeBSEN";
import GradeDS from "./grade/GradeDS";
import GradeI from "./grade/GradeI";
import GradeII from "./grade/GradeII";
import GradeIII from "./grade/GradeIII";
import { useNavigate } from "react-router-dom";

const AddLot = () => {
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const [time, setTime] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [testedFor, setTestedFor] = useState("");
  const [error, setError] = useState("");
  const [lotId, setLotId] = useState("");
  const [selectedItemType, setSelectedItemType] = useState("");
  const [modalVisible, setModalVisible] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(
    moment().format("DD MMMM YYYY, hh:mm A")
  );
  const [activeTab, setActiveTab] = useState("I");
  const bags = useSelector((state) => state.lot);
  const { totalBags, tempBagCount, getBagsLoader, addBatchLoader } = bags;
  const divRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMachine({ limit: 10, offset: 0, getall: "YES" }));
    dispatch(getTempBags({ limit: 50, offset: 0 }));
  }, [dispatch]);

  const onFinish = (values) => {
    console.log({ values });
  };

  const tabs = [
    { key: "I", image: grade1, activeImage: activeGrade1, label: "Grade-I" },
    { key: "II", image: grade2, activeImage: activeGrade2, label: "Grade-II" },
    {
      key: "III",
      image: grade3,
      activeImage: activegrade3,
      label: "Grade-III",
    },
    { key: "VII", image: sd, activeImage: activeSd, label: "Density Sand" },
    { key: "IV", image: bsen, activeImage: activeBsen, label: "BSEN 196" },
    { key: "V", image: astm, activeImage: activeAstm, label: "ASTM C-778" },
    { key: "VI", image: astm2, activeImage: activeAstm2, label: "ASTM 20/30" },
    { key: "VIII", image: bs, activeImage: activeBs, label: "BS 1377" },
    { key: "IX", image: astm3, activeImage: activeAstm3, label: "ASTM D1556" },
  ];

  const getBagNameFromType = (type) => {
    let name;
    let imageIcon;
    switch (type) {
      case "Standard_sand_grade_I":
        name = "Grade-I";
        imageIcon = iconGradeI;
        break;
      case "Standard_sand_grade_II":
        name = "Grade-II";
        imageIcon = iconGradeII;
        break;
      case "Standard_sand_grade_III":
        name = "Grade-III";
        imageIcon = iconGradeIII;
        break;
      case "Standard_Density_sand_IV":
        name = "Density Sand";
        imageIcon = iconGradeVII;
        break;
      case "Bsen196_iso679_V":
        name = "BSEN 196";
        imageIcon = iconGradeIV;
        break;
      case "Astm_c778_grade_sand_VI":
        name = "ASTM C-778";
        imageIcon = iconGradeV;
        break;
      case "Astm_20_30_sand_VII":
        name = "ASTM 20/30";
        imageIcon = iconGradeVI;
        break;
      case "Bs_1377_density_sand_VIII":
        name = "BS 1377";
        imageIcon = iconGradeVIII;
        break;
      case "Astm_d1556m_15_density_sand_IX":
        name = "ASTM D1556";
        imageIcon = iconGradeIX;
        break;
    }
    return { name, imageIcon };
  };

  const handleChange = (value) => {
    console.log({ value });
    setTime(value);
    setError("");
  };
  const handleChangeTestedFor = (value) => {
    setTestedFor(value);
  };

  const renderGradeComponent = () => {
    switch (activeTab) {
      case "I":
        return <GradeI />;
      case "II":
        return <GradeII />;
      case "III":
        return <GradeIII />;
      case "IV":
        return <GradeBSEN />;
      case "V":
        return <GradeASTMc />;
      case "VI":
        return <GradeASTM2 />;
      case "VII":
        return <GradeDS />;
      case "VIII":
        return <GradeBS13 />;
      case "IX":
        return <GradeASTMd />;
      default:
        return null; // Default case, no component rendered
    }
  };

  const handleFinalSubmit = () => {
    if (totalBags && totalBags.length && !time) {
      setError("Please Select time of manufacture!");
      divRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    } else {
      setError("");
    }

    if (!totalBags || totalBags.length === 0) {
      message.error("Please Add at least one bag to proceed!");
    } else {
      console.log("Finish");
    }

    const req = {
      manufacture_time: time,
      tested_for: testedFor,

    };
    if (totalBags && totalBags.length > 0) {
      dispatch(actionAddBatch({ req, navigate, setTime }));
    }
  };

  const handleDiscardBatch = () => {
    if (!totalBags || totalBags.length === 0) {
      message.error("Please Add at least one bag to proceed!");
      setError("");
      setTime(null);
    } else {
      console.log("Discard Batch");
      dispatch(actionDiscardBatch());
      setError("");
      setTime(null);
    }
  };

  const handleDeleteBag = (id, sandType) => {
    dispatch(actionDeleteBag({ id, sandType }));
  };

  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(moment().format("DD MMMM YYYY, hh:mm A"));
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  /*   const renderTypeForms = () => {
          // Implement logic to render different forms for each item type
          switch (selectedItemType) {
              case 'Standard_sand_grade_I':
                  return <EditGradeI tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Standard_sand_grade_II':
                  return <EditGradeII tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Standard_sand_grade_III':
                  return <EditGradeIII tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Standard_Density_sand_IV':
                  return <EditGradeVII tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Bsen196_iso679_V':
                  return <EditGradeIV tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Astm_c778_grade_sand_VI':
                  return <EditGradeV tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Astm_20_30_sand_VII':
                  return <EditGradeVI tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Bs_1377_density_sand_VIII':
                  return <EditGradeVIII tempBag={true} id={lotId} type={selectedItemType} />;
              case 'Astm_d1556m_15_density_sand_IX':
                  return <EditGradeIX tempBag={true} id={lotId} type={selectedItemType} />;
              default:
                  return null;
          }
      };
  
      const handleModalOpen = (type, id, batchNum) => {
          setSelectedItemType(type);
          setLotId(id);
          setModalVisible(true);
  
          dispatch(actionGetTempBagDetail(batchNum))
      };
  
      const handleModalClose = () => {
          setModalVisible(false);
          setSelectedItemType(null);
      }; */
  const onLoadMore = () => {
    if (!getBagsLoader && totalBags.length > 0) {
      console.log("Conditions met. Fetching more data.");
      dispatch(getTempBags({ limit: 50, offset: offsetRef.current + 1 }));
      offsetRef.current += 1;
    }
  };

  const LoadMore = () => {
    const showButton = totalBags && (totalBags.length >= 50 && totalBags.length < tempBagCount);

    return showButton ? (
      <div className="text-center h-[60px]">
        {getBagsLoader ? (
          <Spin indicator={<LoadingOutlined />} />
        ) : (
          <Button
            style={{
              borderRadius: 5,
              padding: "5px 15px",
              border: "1px solid #B2BCCF",
            }}
            onClick={onLoadMore}
            className="text-center cursor-pointer rounded-sm mx-auto outline-btn border-1-[#B2BCCF]"
          >
            Load More...
          </Button>
        )}
      </div>
    ) : null;
  };

  return (
    <>
      <div className="flex justify-center h-screen overflow-hidden max-h-screen ">
        <div className="bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col h-screen overflow-hidden">
          <div className="border-b border-b-[#919da94d] flex items-center 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]">
            <p className="font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-3">
              Create Batch
            </p>
          </div>
          <div
            ref={divRef}
            className=" scrollbar-container flex-1 overflow-auto   pb-0 "
          >
            <Row gutter={[16, 8]} className="overflow-auto px-6 pb-4">
              <Col span={24}>
                <div className="flex items-center gap-x-2 mb-4 pt-4">
                  <img src={recentg} alt="Recentg" />{" "}
                  <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                    Batch Creation Details
                  </p>
                </div>
              </Col>
              <Col span={12}>
                <div className="flex ant-input-date p-0 items-center justify-between">
                  <p className="text-custom-gray custom-sixt">
                    Time of Manufacture
                  </p>
                  <div className="flex items-center">
                    <Segmented
                      id="Dark-Segment"
                      value={time}
                      onChange={handleChange}
                      className="w-full border-0 2xl:my-2 xl:my-2 lg:my-1 2xl:mr-2 xl:mr-2 lg:mr-1 day-night-segment"
                      options={[
                        {
                          value: "D",
                          icon: (
                            <div className="flex space-x-1 2xl:py-1 xl:py-0 lg:py-0">
                              <img src={sun} alt="sun" className="bluesun" />
                              <img
                                src={greysun}
                                alt="sun"
                                className="greysun"
                              />
                              <span>Day</span>
                            </div>
                          ),
                        },
                        {
                          value: "N",
                          icon: (
                            <div className="flex space-x-1 2xl:py-1 xl:py-0 lg:py-0">
                              <img src={moon} alt="moon" className="bluemoon" />
                              <img
                                src={greymoon}
                                alt="moon"
                                className="greymoon"
                              />
                              <span>Night</span>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
                {error && <p className="mt-2 text-red-500">{error}</p>}
              </Col>
              <Col span={12}>
                <Input
                  value={testedFor && testedFor}
                  onChange={(e) => handleChangeTestedFor(e.target.value)}
                  className="custom-input-group"
                  placeholder="Enter Tested For"
                />
              </Col>
              <Divider className="my-2" />
              <Col span={24} className="">
                <div className="flex items-center gap-x-2 mb-4">
                  <img src={bagBig} alt="Bag Big" />{" "}
                  <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                    Bag Details
                  </p>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex items-start justify-between">
                  {tabs &&
                    tabs.map((data, i) => {
                      return (
                        <a
                          key={i}
                          className=" cursor-pointer"
                          onClick={() => setActiveTab(data.key)}
                        >
                          <div className="flex-col flex items-center text-center">
                            <img
                              src={
                                activeTab === data.key
                                  ? data.activeImage
                                  : data.image
                              }
                              alt="grade1"
                            />
                            <p className="font-gilroyBold text-[12px] text-[#2B2A28] mt-1">
                              {data.label}
                            </p>
                          </div>
                        </a>
                      );
                    })}
                </div>
              </Col>
              <Col span={24}>{renderGradeComponent()}</Col>
            </Row>
          </div>
          <div className="footer z-0 bg-white sticky flex items-center px-4 gap-x-2 bottom-0 w-full  border-t border-t-[rgba(145, 157, 169, 0.3)]  bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                onClick={handleFinalSubmit} loading={addBatchLoader} disabled={addBatchLoader}
                htmlType="submit"
                className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2"
              >
                Finish Batch
              </Button>
            </Form.Item>
            <Button
              onClick={handleDiscardBatch}
              className="warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px]"
            >
              Discard Batch
            </Button>
          </div>
        </div>

        {/* SIDEBAR START HERE */}
        <div
          div
          className="bg-white  border-l color-scroll border-l-[#919da94d] w-[30%] flex flex-col"
        >
          <SideCardHeader />
          <div className=" flex-1 py-1 flex flex-col overflow-hidden">
            <div className="px-4">
              <div className=" flex w-full border-b py-4 border-opacity-[30%] border-custom-gray">
                {/* <img src={url}  alt="URL" /> */}
                <div className="flex items-center justify-center px-3 py-0 bg-[#E9E9ED] rounded-[15px]">
                  <img src={bagBlue} alt="Bag Blue" />
                </div>
                <div className="flex-col justify-stretch items-center ml-3 ">
                  <p className="text-primary 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold lg:text-[14px] font-bold">
                    Current Batch
                    <span className="text-[12px] font-normal">
                      ({tempBagCount} Bags)
                    </span>
                  </p>
                  <p className="text-[#919DA9] 2xl:text-[10px] xl:text-[10px] font-gilroyMedium lg:text-[10px] text-[10px]">
                    {currentDateTime}
                  </p>
                </div>
              </div>
            </div>

            {totalBags && totalBags.length > 0 ? (
              <div className="px-2 pt-3 overflow-auto flex-1">
                <div className="px-2   pt-3">
                  <List
                    grid={{
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 1,
                      xl: 1,
                      xxl: 2,
                      gutter: 16,
                    }}
                    className="demo-loadmore-list"
                    loading={getBagsLoader}
                    itemLayout="horizontal"
                    loadMore={<LoadMore />}
                    dataSource={totalBags}
                    // pagination
                    renderItem={(item) => {
                      return (
                        <List.Item>
                          <Card
                            hoverable
                            className="lot-side-section-card border-none"
                          >
                            <div className=" border border-1-[#B2BCCF] p-3 rounded-[8px] flex items-center space-x-2 bg-[#FFF]">
                              <img
                                src={
                                  getBagNameFromType(item && item.type)
                                    .imageIcon
                                }
                                alt="iconGradeI"
                              />
                              <div className="space-y-1 w-full">
                                <p className="text-black  font-gilroyMedium 2xl:text-[14px] xl:text-[13px] text-[10px]">
                                  {getBagNameFromType(item && item.type).name}
                                </p>
                                <div className="flex justify-between">
                                  <p className="text-[#919DA9] font-gilroyBold 2xl:text-[10px] xl:text-[9px] text-[8px]">
                                    Machine: {item && item.screen}
                                  </p>
                                  <div className="flex space-x-1">
                                    <p
                                      className="text-[#FF5B5B] font-gilroyMedium 2xl:text-[10px] xl:text-[9px] text-[8px]"
                                      onClick={() =>
                                        handleDeleteBag(item.id, item.type)
                                      }
                                    >
                                      Delete
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="mt-[20%] flex items-center justify-center">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No Bags"}
                />
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default AddLot;
