import { Button, Col, Form, Input, Row, Spin } from 'antd'
import React, { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionAddBags } from '../../../store/services/lotService';

const GradeBSEN = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [customValidationError, setCustomValidationError] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'L', 'J', 'K', 'M', 'N', 'O', 'P'];

  const grade = useSelector(state => state.lot)
  const { user } = useSelector(state => state.auth)

  const { machines,addBagLoader, getMachinesLoader } = grade;

  const onFinish = (values) => {
    const isValueSelected = selectedValue.length > 0;
    if (!isValueSelected) {
      setCustomValidationError('Please select a Machine Type.');
    } else {
      setCustomValidationError("");

      const req = {
        ...values,
        type: "Bsen196_iso679_V",
        ps_2_0: values.ps_2_0 ? parseFloat(values.ps_2_0).toFixed(1) : "",
        ps_1_60: values.ps_1_60 ? parseFloat(values.ps_1_60).toFixed(2) : "",
        ps_1_00: values.ps_1_00 ? parseFloat(values.ps_1_00).toFixed(2) : "",
        ps_0_50: values.ps_0_50 ? parseFloat(values.ps_0_50).toFixed(2) : "",
        ps_0_16: values.ps_0_16 ? parseFloat(values.ps_0_16).toFixed(2) : "",
        ps_0_08: values.ps_0_08 ? parseFloat(values.ps_0_08).toFixed(2) : "",
        moisture_content: values.moisture_content ? parseFloat(values.moisture_content).toFixed(2) : "",
        user_id: user?.id,
        screen: selectedValue,
      };

      console.log({ req });
      // Check if selectedValue is not empty before calling the API
      if (isValueSelected) {
        dispatch(actionAddBags({ req, form, setSelectedValue }));
      }
    }
  };

  const onFinishFailed = (err) => {
    if (selectedValue.length <= 0) {
      setCustomValidationError('Please select a Machine Type.');
      console.log("Empty");
    } else {
      setCustomValidationError("");
    }
    console.log({ err });
  }
  return (
    <>
      <div className='rounded-[15px] px-4 py-1 bg-white  my-2 '>
        <Form initialValues={{
          ps_2_0: "0.0"
        }}
          name="lot-form"
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <div className='text-[20px] text-[#000] mt-3'>
            BSEN 196 - 1 - 2016 ISO:679-2009(E)
          </div>
          <div className='text-[#000]'>
            <p className='text-[12px] mt-4 '>Machine Name ( Screen )</p>
            <div className='mt-1 w-auto flex text-[14px] font-semibold'>
              {
                getMachinesLoader ? <Spin className='my-2' size='small'
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: "#00205E"
                      }}
                      spin
                    />
                  }
                /> : machines && machines.length && machines?.map((letter, index) => {
                  return (
                    <div
                      key={letter.id}
                      className={`border-2 cursor-pointer ${((machines.length - 1) === index) ? 'border-r-1' : 'border-r-0'}  border-[#B2BCCF] bg-[#fff] px-3.5 py-1.5 ${selectedValue === letter.name ? 'bg-primary   text-white' : ''
                        }`}
                      onClick={() => {
                        setSelectedValue(letter.name)
                        setCustomValidationError(false)
                      }}
                    >
                      {letter.name}
                    </div>
                  );
                })
              }
            </div>
          </div>
          {customValidationError && (
            <p className="text-red-500 mt-1" >{customValidationError}</p>
          )}

          <Row gutter={[16, 0]} className="graed  mt-3">
            <Col span={10}>
              <Form.Item
                label="Particle Size in ( 2.00mm )"
                name="ps_2_0"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in ( 2.00mm )!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if ( value && parseFloat(value) != 0) {
                        return Promise.reject('Please enter the value 0!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input className='' placeholder='Enter Particle Size in ( 2.00mm )' />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in ( 1.60mm )</p><p className='text-[12px] text-[#717171] text-right'>( 2.00 - 12.00 )</p></div>}
                name="ps_1_60"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in ( 1.60mm )!',
                  },

                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 2.00 && value <= 12.00)) {
                        return Promise.reject('Please enter a valid value between 2.00 and 12.00!');
                      }

                      return Promise.resolve();
                    },
                  })
                ]}

              >
                <Input placeholder='Enter Particle Size in ( 1.60mm )' />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in ( 0.50mm )</p><p className='text-[12px] text-[#717171] text-right'>( 62.00 - 72.00 )</p></div>}
                name="ps_0_50"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in ( 0.50mm )!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 62.00 && value <= 72.00)) {
                        return Promise.reject('Please enter a valid value between 62.00 and 72.00!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Particle Size in ( 0.50mm )' />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in ( 1.00mm )</p><p className='text-[12px] text-[#717171] text-right'>( 28.00 - 38.00 )</p></div>}
                name="ps_1_00"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in ( 1.00mm )!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 28.00 && value <= 38.00)) {
                        return Promise.reject('Please enter a valid value between 28.00 and 38.00!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Particle Size in ( 1.00mm )' />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in ( 0.16mm )</p><p className='text-[12px] text-[#717171] text-right'>( 82.00 - 92.00 )</p></div>}
                name="ps_0_16"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in ( 0.16mm )!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 82.00 && value <= 92.00)) {
                        return Promise.reject('Please enter a valid value between 82.00 and 92.00!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Particle Size in ( 0.16mm )' />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in ( 0.08mm )</p><p className='text-[12px] text-[#717171] text-right'>( 99.00 - 100.00 )</p></div>}
                name="ps_0_08"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in ( 0.08mm )!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 99.00 && value <= 100.00)) {
                        return Promise.reject('Please enter a valid value between 99.00 and 100.00!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Particle Size in ( 0.08mm )' />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Moisture Content Test Result (% Cumulative)</p><p className='text-[12px] text-[#717171] text-right'>( 0.01 - 0.20 )</p></div>}
                name="moisture_content"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Moisture Content Test Result (% Cumulative)!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 0.01 && value <= 0.20)) {
                        return Promise.reject('Please enter a valid value between 0.01 and 0.20!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Moisture Content Test Result (% Cumulative)' />
              </Form.Item>
            </Col>
          </Row>

          <div className='footer z-0  flex items-center gap-x-2 bottom-0 w-full   bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3'>
            <Form.Item style={{ marginBottom: 0 }}>
<Button loading={addBagLoader} disabled={addBagLoader} htmlType="submit" className='outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2'>Add Bag</Button>            </Form.Item>
          </div>
        </Form></div>
    </>
  )
}

export default GradeBSEN