import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web.config";
import { deleteSuccess } from "../slices/companySlice";

// Action to get all countries
export const actionGetCountries = createAsyncThunk("actionGetCountries", async () => {
    try {
        const res = await axios.get(`${BASE_URL}/location/get-all-countries`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
            throw new Error(`API Error: ${customMsg}`);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});

// Action to get states based on a provided URL
export const actionGetStates = createAsyncThunk("actionGetStates", async (stateCode) => {
    try {
        const res = await axios.get(`${BASE_URL}/location/get-states-of-country/${stateCode}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
            throw new Error(`API Error: ${customMsg}`);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});

// Action to get cities based on a provided URL
export const actionGetCities = createAsyncThunk("actionGetCities", async ({ countryCode, stateCode }) => {
    try {
        const res = await axios.get(`${BASE_URL}/location/get-cities-of-states/${countryCode}/${stateCode}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});


export const actionGetDeliveryCountries = createAsyncThunk("actionGetDeliveryCountries", async () => {
    try {
        const res = await axios.get(`${BASE_URL}/location/get-all-countries`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
            throw new Error(`API Error: ${customMsg}`);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});

// Action to get states based on a provided URL
export const actionGetDeliveryStates = createAsyncThunk("actionGetDeliveryStates", async (stateCode) => {
    try {
        const res = await axios.get(`${BASE_URL}/location/get-states-of-country/${stateCode}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
            throw new Error(`API Error: ${customMsg}`);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});

// Action to get cities based on a provided URL
export const actionGetDeliveryCities = createAsyncThunk("actionGetDeliveryCities", async ({ countryCode, stateCode }) => {
    try {
        const res = await axios.get(`${BASE_URL}/location/get-cities-of-states/${countryCode}/${stateCode}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});

// Get Customers 
export const actionGetCompany = createAsyncThunk("actionGetCompany", async ({ limit, offset, getall, keyword }) => {
    try {
        const res = await axios.get(`${BASE_URL}/company?limit=${limit}&offset=${offset}&order=${"DESC"}${keyword ? "&keyword=" + keyword : ""}${getall ? "&getall=" + getall : ""}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return res.data;
        } else if (parseInt(status) === 404) {
            return "";
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
});

//Get Customer Detail
export const actionGetCompanyDetail = createAsyncThunk('actionGetCompanyDetail', async (id) => {
    try {
        const res = await axios.get(`${BASE_URL}/company/${id}`);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Create Customer
export const actionCreateCompany = createAsyncThunk('actionCreateCompany', async (data) => {
    const { req, navigate } = data;
    try {
        const res = await axios.post(`${BASE_URL}/company`, req);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/company');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})

//Update Customer
export const actionUpdateCompany = createAsyncThunk('actionUpdateCompany', async (data) => {
    const { req, Id, navigate } = data;
    try {
        const res = await axios.patch(`${BASE_URL}/company/${Id}`, req);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            navigate && navigate('/company');
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        // message.error(error?.message, 5);
        message.error(error.response.data && error.response.data.message.map((d) => d));
    }

})

// Delete Customer
export const actionDeleteCompany = createAsyncThunk('actionDeleteCompany', async (id, { dispatch }) => {
    try {
        const res = await axios.delete(`${BASE_URL}/company/${id}`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            dispatch(deleteSuccess());
            return id;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

