import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import cetrificate from "../../assets/img/dashboard/cetrificate.svg";
import usersvg from "../../assets/img/dashboard/user.svg";
import {
  actionGetCertificate,
  actionGetMemberDetail,
  actionUpdateMember,
} from "../../store/services/memberService";
import { actionGetRoles } from "../../store/services/rbacService";
import SideCardHeader from "../common/SideCardHeader";
import { BASE_URL_UPLOAD } from "../config/web.config";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const EditMember = () => {
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const errorRef = useRef(null);
  const [imageUrl, setImageUrl] = useState();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const roleData = useSelector((state) => state.rbac);
  const memberState = useSelector((state) => state.member);

  const { certificates, memberDetail } = memberState;

  const [form] = Form.useForm();

  useEffect(() => {
    if (!roleData.rolesLoader) {
      dispatch(actionGetRoles({ limit: 10, offset: 0, getall: "YES" }));
    }
    if (!memberState.getCertificatesLoader) {
      dispatch(actionGetCertificate());
    }
  }, [dispatch]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(info.file.response.file.path);
      });
    }
  };
  const uploadButton = (
    <button type="button">
      {loading ? (
        <LoadingOutlined className="text-[#919DA9]" />
      ) : (
        <PlusOutlined className="text-[#919DA9]" />
      )}
      <div className="text-[16px] text-[#919DA9] font-gilroyMedium">Upload</div>
    </button>
  );

  useEffect(() => {
    if (id) {
      dispatch(actionGetMemberDetail(id));
    }
  }, [id, dispatch]);

  const handleCheckPermission = (id) => {
    const newSelectedCertificates = selectedCertificates.map((c) =>
      c.id === id ? { ...c, isSelected: !c.isSelected } : c
    );

    console.log("Selected Certificates:", newSelectedCertificates);

    setSelectedCertificates(newSelectedCertificates);
  };

  useEffect(() => {
    if (memberDetail) {
      form.setFieldsValue({
        first_name: memberDetail?.first_name,
        last_name: memberDetail?.last_name,
        email: memberDetail?.email,
        phone: memberDetail?.phone,
        // roles: memberDetail?.roles,
        roles:
          memberDetail.roles && memberDetail.roles.length
            ? memberDetail.roles.map((data) => data.id)
            : [],
        date_of_birth: memberDetail.date_of_birth
          ? dayjs(memberDetail.date_of_birth)
          : undefined /* .format('YYYY-MM-DD')  */,

        gender: memberDetail?.gender,
        first_name: memberDetail?.first_name,
        profile_pic: memberDetail?.profile_pic,
      });
    }
    setImageUrl(memberDetail?.profile_pic);
  }, [memberDetail]);

  useEffect(() => {
    const updateSelectedCertificates = async () => {
      // Set initial state
      setSelectedCertificates(
        (certificates &&
          certificates.length > 0 &&
          certificates.map((certificate) => ({
            ...certificate,
            isSelected: false,
          }))) ||
          []
      );

      await Promise.resolve();

      const newSelectedCertificates =
        (certificates &&
          certificates.length > 0 &&
          certificates.map((certificate) => ({
            ...certificate,
            isSelected: Boolean(
              memberDetail?.certificates?.find((pc) => certificate.id === pc.id)
            ),
          }))) ||
        [];

      // Set the final state
      setSelectedCertificates(newSelectedCertificates);
    };

    updateSelectedCertificates();
  }, [certificates]);

  const onFinish = (val) => {
    if (
      selectedCertificates &&
      selectedCertificates.length > 0 &&
      !selectedCertificates.some((c) => c.isSelected)
    ) {
      errorRef.current.scrollTo(0, errorRef.current.scrollHeight);

      setError("Please Select Certificate");
      return;
    }

    // Clear any previous error
    setError(null);

    const certificates =
      selectedCertificates?.filter((c) => c.isSelected)?.map((c) => c.id) || [];
    const request = {
      ...val,
      profile_pic: imageUrl
        ? imageUrl?.split("/").reverse()[0]
        : val.profile_pic.file.response.file.filename,
      // date_of_birth: val.date_of_birth ? formatedDate : "",
      date_of_birth: val.date_of_birth
        ? dayjs(val.date_of_birth).format("YYYY-MM-DD")
        : undefined,
      certificates,
    };
    dispatch(actionUpdateMember({ id, request, navigate }));
  };
  const arr = [];
  roleData &&
    roleData.roles.length > 0 &&
    roleData.roles?.map((d, index) => {
      arr.push({
        key: index,
        value: d.id,
        label: <p className="capitalize">{d.name}</p>,
      });
    });
  const onChange = (date, dateString) => {
    setDateOfBirth(dateString);
  };
  const discardAgency = () => {
    navigate("/system-management");
  };

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
      form={form}
      className="h-full relative max-h-screen gatepass-container overflow-hidden"
    >
      <div className="flex justify-center h-full ">
        {/* CONTENT SECTION STARTS  */}
        <div className="bg-main w-[70%] relative table-scrollbar flex flex-col overflow-hidden">
          <Row
            justify={"center"}
            align={"middle"}
            className="border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]"
          >
            <Col span={12}>
              <p className="font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5">
                Edit Member
              </p>
            </Col>
          </Row>
          <div ref={errorRef} className="flex-1 overflow-auto  px-6 py-4 ">
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  label="Member Type"
                  name="roles"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Member Type!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    loading={roleData.rolesLoader}
                    showSearch
                    className="capitalize"
                    placeholder="Select Member Type"
                    optionFilterProp="children"
                    // onChange={handleChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={arr}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <div className="flex items-center gap-x-2 mb-4">
                  <img src={usersvg} alt="usersvg" />{" "}
                  <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                    Member Detail
                  </p>
                </div>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="First name"
                  name="first_name"
                  normalize={(value) => value.replace(/[^a-zA-Z]/g, "")}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter First Name!",
                    },
                  ]}
                >
                  <Input
                    className="custom-input-group"
                    placeholder="Enter First Name"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last name"
                  name="last_name"
                  normalize={(value) => value.replace(/[^a-zA-Z]/g, "")}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Last Name!",
                    },
                  ]}
                >
                  <Input
                    className="custom-input-group"
                    placeholder="Enter Last Name"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Email Address!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email Address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone number"
                  name="phone"
                  normalize={(value) => value.replace(/[^0-9]/g, "").trim()}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Phone Number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length !== 10) {
                          return Promise.reject(
                            "Please Enter Valid Phone number!"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    controls={false}
                    placeholder="Enter Phone Number"
                    className="rounded-0 w-full border-0 country-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Gender!",
                    },
                  ]}
                >
                  <Select
                    className="capitalize"
                    placeholder="Select Gender"
                    options={[
                      {
                        value: "Male",
                        label: "Male",
                      },
                      {
                        value: "Female",
                        label: "Female",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="profile_pic" label="Profile Picture">
                  <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                    <Upload
                      name="file"
                      accept="image/png, image/jpeg ,image/png, image/jpg"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      // beforeUpload={beforeUpload}
                      headers={{
                        Authorization:
                          "Bearer " +
                          localStorage.getItem("vytechQmsBackendJwtToken"),
                      }}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          className="p-1"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="flex items-center gap-x-2 mb-4">
                  <img src={cetrificate} alt="cetrificate" />{" "}
                  <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                    Certificate Permission
                  </p>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="certificates"
                  /* rules={[
                                    {
                                        required: !selectedCertificates || selectedCertificates.some(certificate => certificate.isSelected),
                                        message: 'Please select at least one certificate!',
                                    },
                                ]} */
                >
                  <Row gutter={[16, 8]}>
                    {selectedCertificates &&
                      selectedCertificates.length > 0 &&
                      selectedCertificates.map((certificate) => (
                        <Col span={12} key={certificate.id}>
                          <Checkbox
                            className="custom-checkbox"
                            onChange={() =>
                              handleCheckPermission(certificate.id)
                            }
                            checked={certificate.isSelected}
                          >
                            {certificate.name}
                          </Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Form.Item>
                {error && <p className="text-red-500">{error}</p>}
              </Col>
            </Row>
          </div>
          {/* Footer Start */}
          <div className="footer z-0 bg-main sticky flex items-center px-4 gap-x-2 bottom-0 w-full  border-t border-t-[rgba(145, 157, 169, 0.3)]  bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                htmlType="submit"
                className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2"
              >
                Save Details
              </Button>
            </Form.Item>
            <Button
              onClick={discardAgency}
              className="warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px]"
            >
              Discard Entry
            </Button>
          </div>

          {/* FOOTER SECTION ENDS  */}
        </div>

        {/* SIDEBAR START HERE */}
        {/* <div className='bg-[#FFFFFF] border-l border-l-[#919da94d] w-[30%] flex flex-col '> */}
        <div className="bg-main border-l  border-l-[#919da94d] w-[30%] flex flex-col ">
          <SideCardHeader />
          <div className="overflow-auto custom-scrollbar  px-1 flex-1 py-1">
            <div className="px-4 pt-5" id="custom-increment-input">
              <div className="flex items-center gap-x-2 mb-4">
                <img src={usersvg} alt="usersvg" />{" "}
                <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                  Additional Details
                </p>
              </div>
            </div>
            <Row gutter={[16, 0]} className="px-4">
              <Col span={24}></Col>
              <Col span={24}>
                <Form.Item
                  label="Date of Birth"
                  name="date_of_birth"
                  rules={[
                    {
                      required: false,
                      message: "Please Select Date!",
                    },
                  ]}
                >
                  <DatePicker className="w-full" onChange={onChange} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        {/* SIDEBAR ENDS HERE */}
      </div>
    </Form>
  );
};

export default EditMember;
