import React from "react";
import { Button, Cascader, Col, Form, Input, Row } from "antd";
import lock from "../../assets/img/dashboard/lock.svg";
import { actionChangePassword } from "../../store/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetMemberDetail } from "../../store/services/memberService";

const ChangePassword = ({ id, setShowChangePassword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = localStorage.getItem("vytechQmsBackendJwtToken");
  const memberData = useSelector((state) => state.member);

  const onFinish = (values) => {
    dispatch(
      actionChangePassword({
        id,
        password: values.password,
        form,
        token,
        setShowChangePassword,
      })
    );
    console.log(values, "change password");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="w-[90%]">
        <p className="text-center font-GlegooBold whitespace-nowrap text-primary 2xl:text-[30px] xl:text-[20px] lg:text-[12px]">
          Change Password
        </p>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="h-full relative max-h-screen gatepass-container overflow-hidden"
        >
          <Form.Item
            className="mt-5 text-left"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              {
                min: 8,
                message:
                  "passwords must be longer than or equal to 8 characters !",
              },
            ]}
          >
            <Input.Password
              placeholder="New Password"
              prefix={<img src={lock} alt="user" className="pr-2 border-r" />}
              className="login-input py-[3px] border border-[#919da9]"
            />
          </Form.Item>
          <Form.Item
            className="mt-5 text-left"
            name="confirm_new_password"
            rules={[
              {
                required: true,
                message: "Please input your confirm new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm New Password"
              prefix={<img src={lock} alt="user" className="pr-2 border-r" />}
              className="login-input py-[3px] border border-[#919da9]"
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              // loading={loginStatus?.resetPasswordLoader}
              style={{ padding: "8px 20px" }}
              className=" text-center flex items-center justify-center filled-btn 2xl:text-[14px] xl:text-[12px] text-[10px]"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
