import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Input, Pagination, Row, Table, Popconfirm } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import url from '../../assets/img/all/gatepassside.svg';
import searchh from "../../assets/img/all/search.svg";
import SideCardHeader from '../common/SideCardHeader';
import { actionDeleteGatepass, actionGetGatepass, actionGetGatepassDetail, GetGatepassDetail } from "../../store/services/gatepassService"
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const Gatepass = () => {
    const [selectedRowKey, setSelectedRowKey] = useState(0);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [staticId, setStaticId] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const dispatch = useDispatch();
    const gatepassData = useSelector(state => state.gatepass);

    useMemo(() => {
        const newData = gatepassData && gatepassData?.gatepasses && gatepassData?.gatepasses.length && gatepassData?.gatepasses?.map((gatepass, i) => ({
            key: i,
            num: (pagination.current - 1) * pagination.pageSize + i + 1,
            id: gatepass.id,
            date: gatepass.date ? moment(gatepass.date).format('DD MMM YYYY') : "-",
            company_name: gatepass.business_name,
            tra_agency: gatepass.transport_agency ? gatepass.transport_agency : "-",
            driver_name: gatepass.driver_name,
            invoice_num: gatepass.invoice_number,
        }));
        setData(newData);
        setStaticId(gatepassData && gatepassData.gatepasses && gatepassData.gatepasses[0].id);
    }, [gatepassData]);


    useEffect(() => {
        if (search) {
            dispatch(actionGetGatepass({ offset: 0, limit: 10, keyword: search }))
        } else {
            dispatch(actionGetGatepass({ offset: pagination.current - 1, limit: pagination.pageSize, keyword: search ? search : "" }))
        }
    }, [dispatch, search, /* pagination */]);

    const handleTableChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
            total: data.length,
        });
    };


    const handleSearch = (value) => {
        console.log(value);
        setSearch(value);
        // dispatch(actionGetMembers({ limit: 10, offset: 0, keyword: value }));
    };

    useEffect(() => {
        staticId && dispatch(actionGetGatepassDetail(staticId));
        setSelectedRowKey(0)
    }, [staticId]);

    const GetGatepassDetailApiCall = (id) => {
        if (id) {
            dispatch(actionGetGatepassDetail(id));
        }
    }

    // Delete API
    const confirm = (id) => {
        dispatch(actionDeleteGatepass(id))
            .then(response => {
                try {
                    if (gatepassData?.deleteSuccess) {
                        const deletedRowIndex = gatepassData?.gatepasses?.findIndex(item => item.id === id);
                        const newStaticId = deletedRowIndex > 0 ? gatepassData?.gatepasses[deletedRowIndex - 1].id : null;
                        setSelectedRowKey(deletedRowIndex);
                        dispatch(actionGetGatepassDetail(newStaticId));
                    } else {
                        console.log("Error Occurs");
                    }
                } catch (err) {
                    console.log({ err });
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };
    const columns = [
        {
            title: '#',
            width: 100,
            fixed: 'left',
            dataIndex: 'num',
            key: 'num',
        },
        {
            title: 'Date',
            width: 100,
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
            width: 150,
        },
        {
            title: 'Transporting Agency',
            dataIndex: 'tra_agency',
            key: 'tra_agency',
            width: 150,
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_num',
            key: 'invoice_num',
            width: 150,
        },
        {
            title: 'Driver Name',
            dataIndex: 'driver_name',
            key: 'driver_name',
            width: 150,
        },
        // {
        //     title: 'Action',
        //     key: 'operation',
        //     fixed: 'right',
        //     width: 100,
        //     render: () => <p>action</p>,
        // },
    ];
    const desitems = [
        {
            key: '1',
            label: 'Transporting Agency',
            children: gatepassData && gatepassData.gatepassDetail && gatepassData.gatepassDetail.transport_agency || "-",
        },
        {
            key: '2',
            label: 'Date',
            children: gatepassData && gatepassData.gatepassDetail.date ? moment(gatepassData.gatepassDetail.date).format('DD MMM YYYY') : "-",
        },
        {
            key: '3',
            label: 'Invoice Number',
            children: gatepassData && gatepassData.gatepassDetail && gatepassData.gatepassDetail.invoice_number,
        },
        {
            key: '4',
            label: 'LR Number',
            children: gatepassData && gatepassData.gatepassDetail && gatepassData.gatepassDetail.lr_number,
        },
        {
            key: '5',
            label: 'Driver Name',
            children: gatepassData && gatepassData.gatepassDetail && gatepassData.gatepassDetail.driver_name,
        },
        {
            key: '6',
            label: 'Contact Number',
            children: gatepassData && gatepassData.gatepassDetail && gatepassData.gatepassDetail.contact_number,
        },
        // {
        //     key: '7',
        //     label: 'Delivery Address',
        //     children: 'Guwahati, Assam - 781 001.',
        // },
    ];

    return (
        <div className='flex justify-center h-full max-h-screen gatepass-container'>
            {/* Content Section */}
            <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>
                <Row
                    justify={'center'}
                    align={'middle'}
                    className='border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'
                >
                    <Col span={6}>
                        <p className='font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>
                            Gatepass
                        </p>
                    </Col>
                    <Col span={14} xxl={12} lg={18} sm={18} xl={18} align="end">
                        <div className='mr-5 w-1/2 ml-auto'>
                            <Input onChange={(e) => handleSearch(e.target.value)} className='custom-search' placeholder="Search Gatepass" suffix={<img src={searchh} alt="search" />} />
                        </div>
                    </Col>

                </Row>
                <div className='flex-1 overflow-auto px-6 py-4 scrollbar-container'>
                    <Table
                        loading={gatepassData.getGetapassLoader}
                        columns={columns}
                        dataSource={data}
                        rowClassName={(record) => (record.key === selectedRowKey ? 'selected-row' : '')}
                        onRow={(record) => ({
                            onClick: () => {
                                GetGatepassDetailApiCall(record.id)
                                setSelectedRowKey(record.key)
                            }
                        })}
                        pagination={false}
                        scroll={{
                            x: 'max-content',
                        }}
                    />
                </div>
                <div className=' flex items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] border-t border-t-custom-gray border-opacity-[30%] px-5' align={'center'}>

                    <Link to="/add-gatepass">  <Button className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Add New Gatepass</Button></Link>
                    <div className='flex justify-end' id='custom-pagination'>
                        <Pagination
                            className='flex items-center'
                            {...pagination}
                            showSizeChanger
                            showQuickJumper
                            hideOnSinglePage
                            pageSizeOptions={['10', '20', '30']}
                            total={gatepassData.gatepassCount}
                            onChange={(page, pageSize) => handleTableChange(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
            {/* Content Section */}


            {/* Sidebar  Section Start Here */}
            <div className='bg-white  border-l border-l-[#919da94d] w-[30%] flex flex-col'>
                <SideCardHeader />
                <div className='overflow-auto px-1 flex-1 py-1'>
                    <div className='px-4'>

                        <div className=' flex justify-between w-full border-b py-4 border-opacity-[30%] border-custom-gray'>
                            <img src={url} alt="URL" />
                            <div className='flex-col justify-stretch items-center ml-3 '>
                                <p className='text-primary 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold   lg:text-[14px] font-bold'>Air & Industrial Equipment Co.</p>
                                <p className='text-[#919DA9] 2xl:text-[10px] xl:text-[10px] font-gilroyMedium lg:text-[10px] text-[10px]'>#925460  |  Guwahati, Assam</p>
                            </div>
                            <div className='ml-auto'>
                                <Popconfirm
                                    okButtonProps={{
                                        // loading: memberData.deleteMemberLoader,
                                    }}
                                    placement="top"
                                    title="Are you sure you want to delete this gatepass?"
                                    onConfirm={() => confirm(gatepassData && gatepassData.gatepassDetail && gatepassData.gatepassDetail.id)}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button id="" className='bg-[#DFDEFF] Delete-btn h-auto 2xl:p-3 xl:p-2 p-2 ml-auto bg-transparent border border-secondary hover:border-secondary  flex    justify-center items-center rounded-[15px]'>
                                        <DeleteOutlined style={{ color: '#FF5A26' }} />
                                    </Button>
                                </Popconfirm>
                            </div>
                        </div>

                        <Descriptions column={2} layout="vertical" id='gatepass-description' className='mt-4' items={desitems} />

                        <Row gutter={[16, 0]}>


                            <Col span={8}>
                                <Card id="Dashboard-card" className='cursor-pointer flex justify-center  bg-white rounded-[15px]'>
                                    <p className='text-primary text-center text-[12px] 2xl:text-[24px] xl:text-[16px] group-hover:text-primary transition-all'>10</p>

                                    <p className='text-custom-gray text-center 2xl:text-[12px] transition-all  xl:text-[10px] text-[10px]'>Grade 1</p>
                                    <p className='text-custom-gray text-center 2xl:text-[12px] transition-all   xl:text-[10px] text-[10px]'>Bag Count</p>

                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card id="Dashboard-card" className='cursor-pointer flex justify-center  bg-white rounded-[15px]'>
                                    <p className='text-primary text-center text-[12px] 2xl:text-[24px] xl:text-[16px] group-hover:text-primary transition-all'>10</p>

                                    <p className='text-custom-gray text-center 2xl:text-[12px] transition-all  xl:text-[10px] text-[10px]'>Grade 1</p>
                                    <p className='text-custom-gray text-center 2xl:text-[12px] transition-all   xl:text-[10px] text-[10px]'>Bag Count</p>

                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card id="Dashboard-card" className='cursor-pointer flex justify-center  bg-white rounded-[15px]'>
                                    <p className='text-primary text-center text-[12px] 2xl:text-[24px] xl:text-[16px] group-hover:text-primary transition-all'>10</p>

                                    <p className='text-custom-gray text-center 2xl:text-[12px] transition-all  xl:text-[10px] text-[10px]'>Grade 1</p>
                                    <p className='text-custom-gray text-center 2xl:text-[12px] transition-all   xl:text-[10px] text-[10px]'>Bag Count</p>

                                </Card>
                            </Col>
                        </Row>
                    </div>

                </div>
                <div className=' gap-x-2 bg-white relative flex z-50 justify-evenly  px-3 border-t border-t-custom-gray border-opacity-[30%] 2xl:h-[85px] xl:h-[65px] lg:h-[55px]  sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3 '>

                    <Link to={`/edit-gatepass/${gatepassData && gatepassData.gatepassDetail && gatepassData.gatepassDetail.id}`} className='w-1/2 filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] px-5 flex items-center justify-center '>Edit Gatepass Details</Link>
                    <Button className='outline-btn 2xl:text-[16px] w-1/2  xl:text-[14px] text-[12px]  flex items-center justify-center'>Print Gatepass</Button>



                </div>
            </div>
            {/* Sidebar  Section Start Here */}
        </div >
    );
};

export default Gatepass;
