import { createSlice } from "@reduxjs/toolkit";
import { actionCreateCompany, actionDeleteCompany, actionGetCities, actionGetCountries, actionGetCompany, actionGetCompanyDetail, actionGetDeliveryCities, actionGetDeliveryCountries, actionGetDeliveryStates, actionGetStates, actionUpdateCompany } from "../services/companyService";

const companySlice = createSlice({
    name: "lot",
    initialState: {
        countries: [],
        deliveryCountries: [],
        getCountryLoader: false,
        getDeliveryCountryLoader: false,
        statesLoader: false,
        getDeliveryStatesLoader: false,
        citiesLoader: false,
        getDeliveryCitiesLoader: false,
        states: [],
        getDeliveryStates: [],
        cities: [],
        getDeliveryCities: [],
        getCompanyLoader: false,
        companies: [],
        companiesCount: false,
        companyDetailLoader: false,
        companyDetail: "",
        createCompanyLoader: false,
        updateCompanyLoader: false,
        deleteCompanyLoader: false,
        deleteSuccess: false,
    },
    reducers: {
        deleteSuccess: (state) => {
            state.deleteSuccess = true;
        }
    },
    extraReducers: (buider) => {
        buider
            // actionGetCountries Call
            .addCase(actionGetCountries.pending, (state) => {
                state.getCountryLoader = true;
            })
            .addCase(actionGetCountries.fulfilled, (state, action) => {
                state.countries = action.payload;
                state.getCountryLoader = false;
            })
            .addCase(actionGetCountries.rejected, (state) => {
                state.getCountryLoader = false;
            })

            // actionGetStates State
            .addCase(actionGetStates.pending, (state) => {
                state.statesLoader = true;
            })
            .addCase(actionGetStates.fulfilled, (state, action) => {
                state.states = action.payload;
                state.statesLoader = false;
            })
            .addCase(actionGetStates.rejected, (state) => {
                state.statesLoader = false;
            })

            // actionGetCities State
            .addCase(actionGetCities.pending, (state) => {
                state.citiesLoader = true;
            })
            .addCase(actionGetCities.fulfilled, (state, action) => {
                state.cities = action.payload;
                state.citiesLoader = false;
            })
            .addCase(actionGetCities.rejected, (state) => {
                state.citiesLoader = false;
            })
            // actionGetDeliveryCountries Call
            .addCase(actionGetDeliveryCountries.pending, (state) => {
                state.getDeliveryCountryLoader = true;
            })
            .addCase(actionGetDeliveryCountries.fulfilled, (state, action) => {
                state.deliveryCountries = action.payload;
                state.getDeliveryCountryLoader = false;
            })
            .addCase(actionGetDeliveryCountries.rejected, (state) => {
                state.getDeliveryCountryLoader = false;
            })

            // actionGetDeliveryStates State
            .addCase(actionGetDeliveryStates.pending, (state) => {
                state.getDeliveryStatesLoader = true;
            })
            .addCase(actionGetDeliveryStates.fulfilled, (state, action) => {
                state.getDeliveryStates = action.payload;
                state.getDeliveryStatesLoader = false;
            })
            .addCase(actionGetDeliveryStates.rejected, (state) => {
                state.getDeliveryStatesLoader = false;
            })

            // actionGetDeliveryCities State
            .addCase(actionGetDeliveryCities.pending, (state) => {
                state.getDeliveryCitiesLoader = true;
            })
            .addCase(actionGetDeliveryCities.fulfilled, (state, action) => {
                state.getDeliveryCities = action.payload;
                state.getDeliveryCitiesLoader = false;
            })
            .addCase(actionGetDeliveryCities.rejected, (state) => {
                state.getDeliveryCitiesLoader = false;
            })

            // Get companies Call
            .addCase(actionGetCompany.pending, (state) => {
                state.getCompanyLoader = true;
            })
            .addCase(actionGetCompany.fulfilled, (state, action) => {
                state.companies = action.payload?.data;
                state.companiesCount = action.payload?.total_records;
                state.getCompanyLoader = false;
            })

            .addCase(actionGetCompany.rejected, (state) => {
                state.getCompanyLoader = false;
            })

            // Get Single Customer
            .addCase(actionGetCompanyDetail.pending, (state) => {
                state.companyDetailLoader = true;
            })
            .addCase(actionGetCompanyDetail.fulfilled, (state, action) => {
                state.companyDetail = action.payload;
                state.companyDetailLoader = false;
            })
            .addCase(actionGetCompanyDetail.rejected, (state) => {
                state.companyDetailLoader = false;
            })

            // Create Customer
            .addCase(actionCreateCompany.pending, (state) => {
                state.createCompanyLoader = true;
            })
            .addCase(actionCreateCompany.fulfilled, (state, action) => {
                state.createCompanyLoader = false;
            })
            .addCase(actionCreateCompany.rejected, (state) => {
                state.createCompanyLoader = false;
            })
            // Update Customer
            .addCase(actionUpdateCompany.pending, (state) => {
                state.updateCompanyLoader = true;
            })
            .addCase(actionUpdateCompany.fulfilled, (state, action) => {
                state.updateCompanyLoader = false;
            })
            .addCase(actionUpdateCompany.rejected, (state) => {
                state.updateCompanyLoader = false;
            })

            // Delete Customer
            .addCase(actionDeleteCompany.pending, (state) => {
                state.deleteCompanyLoader = true;
            })
            .addCase(actionDeleteCompany.fulfilled, (state, action) => {
                state.companies = state.companies.filter(companies => companies.id !== action.payload)
                state.companiesCount = state.companiesCount - 1
                state.deleteCompanyLoader = false;
            })
            .addCase(actionDeleteCompany.rejected, (state) => {
                state.deleteCompanyLoader = false;
            })

    },
});
export default companySlice.reducer;
export const { deleteSuccess } = companySlice.actions