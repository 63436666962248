import { createSlice } from "@reduxjs/toolkit";
import { actionDeleteMember, actionGetCertificate, actionGetMemberDetail, actionGetMembers, actionUpdateMember } from "../services/memberService";

const memberSlice = createSlice({
    name: 'member',
    initialState: {
        members: "",
        getMemberLoader: false,
        memberCount: "",
        deleteMemberLoader: false,
        memberDetail: "",
        memberDetailLoader: false,
        updateMemberLoader: false,
        deleteSuccess: false,
        certificates: "",
        getCertificatesLoader: false,
    }
    ,
    reducers: {
        deleteSuccess: (state) => {
            state.deleteSuccess = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(actionGetMembers.pending, (state) => {
                state.getMemberLoader = true;
            })
            .addCase(actionGetMembers.fulfilled, (state, action) => {
                state.members = action?.payload?.data;
                state.memberCount = action?.payload?.total_records;
                state.getMemberLoader = false;
            })

            .addCase(actionGetMembers.rejected, (state) => {
                state.getMemberLoader = false;
            })

            // Get Single Member
            .addCase(actionGetMemberDetail.pending, (state) => {
                state.memberDetailLoader = true;
            })
            .addCase(actionGetMemberDetail.fulfilled, (state, action) => {
                state.memberDetail = action.payload;
                state.memberDetailLoader = false;
            })
            .addCase(actionGetMemberDetail.rejected, (state) => {
                state.memberDetailLoader = false;
            })

            // Update Member
            .addCase(actionUpdateMember.pending, (state) => {
                state.updateMemberLoader = true;
            })
            .addCase(actionUpdateMember.fulfilled, (state, action) => {
                state.updateMemberLoader = false;
            })
            .addCase(actionUpdateMember.rejected, (state) => {
                state.updateMemberLoader = false;
            })

            // Delete Member
            .addCase(actionDeleteMember.pending, (state) => {
                state.deleteMemberLoader = true;
            })
            .addCase(actionDeleteMember.fulfilled, (state, action) => {
                state.members = state.members.filter(member => member.id !== action.payload);
                /* state.memberCount = state.memberCount - 1; */
                state.deleteMemberLoader = false;
            })
            .addCase(actionDeleteMember.rejected, (state) => {
                state.deleteMemberLoader = false;
            })
            // GET CERTIFICATE
            .addCase(actionGetCertificate.pending, (state) => {
                state.getCertificatesLoader = true;
            })
            .addCase(actionGetCertificate.fulfilled, (state, action) => {
                state.certificates = action?.payload?.data;
                state.getCertificatesLoader = false;
            })

            .addCase(actionGetCertificate.rejected, (state) => {
                state.getCertificatesLoader = false;
            })

    }
})

export default memberSlice.reducer;
export const { deleteSuccess } = memberSlice.actions;
