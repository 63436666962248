import { createSlice } from "@reduxjs/toolkit";
import { actionChangePassword, actionForgotPassword, actionLogin, actionLogout, actionResetPassword, authMe, getDashboardData } from "../services/authService";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: "",
        loginLoader: false,
        userLoader: false,
        forgotPasswordLoader: false,
        loginErr: false,
        resetPasswordLoader: false,
        changePasswordLoader: false,
        dashboardData: [],
        dashboardDataLoader: false,

    },
    reducers: {},
    extraReducers: (buider) => {
        buider
            // Login State
            .addCase(actionLogin.pending, (state) => {
                state.loginLoader = true;
            })
            .addCase(actionLogin.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loginLoader = false;
                state.loginErr = false;
            })
            .addCase(actionLogin.rejected, (state) => {
                state.loginLoader = false;
                state.loginErr = true;
            })

            // Fetch userprofile
            .addCase(authMe.pending, (state) => {
                state.userLoader = true;
            })
            .addCase(authMe.fulfilled, (state, action) => {
                state.user = action.payload;
                state.userLoader = false;
            })
            .addCase(authMe.rejected, (state) => {
                state.userLoader = false;
            })

            // Forgot Password 
            .addCase(actionForgotPassword.pending, (state) => {
                state.forgotPasswordLoader = true;
            })
            .addCase(actionForgotPassword.fulfilled, (state) => {
                state.forgotPasswordLoader = false;
            })
            .addCase(actionForgotPassword.rejected, (state) => {
                state.forgotPasswordLoader = false;
            })

            // Reset Password 
            .addCase(actionResetPassword.pending, (state) => {
                state.resetPasswordLoader = true;
            })
            .addCase(actionResetPassword.fulfilled, (state, action) => {
                state.resetPasswordLoader = false;
            })
            .addCase(actionResetPassword.rejected, (state) => {
                state.resetPasswordLoader = false;
            })

            // change Password 
            .addCase(actionChangePassword.pending, (state) => {
                state.changePasswordLoader = true;
            })
            .addCase(actionChangePassword.fulfilled, (state, action) => {
                state.changePasswordLoader = false;
            })
            .addCase(actionChangePassword.rejected, (state) => {
                state.changePasswordLoader = false;
            })
            // Logout 
            .addCase(actionLogout.fulfilled, (state) => {
                state.user = "";
            })
            // Dashboard 
            .addCase(getDashboardData.pending, (state) => {
                state.dashboardDataLoader = true;
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                state.dashboardData = action.payload;
                state.dashboardDataLoader = false;
            })
            .addCase(getDashboardData.rejected, (state) => {
                state.dashboardDataLoader = false;
            })


    }
})


export default authSlice.reducer;
