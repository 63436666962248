import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import memberSlice from "./slices/memberSlice";
import rbacSlice from "./slices/rbacSlice";
import lotSlice from "./slices/lotSlice";
import companySlice from "./slices/companySlice";
import transportSlice from "./slices/transportSlice";
import dispatchSlice from "./slices/dispatchSlice";
import gatepassSlice from "./slices/gatepassSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        member: memberSlice,
        rbac: rbacSlice,
        lot: lotSlice,
        company: companySlice,
        transportAgency: transportSlice,
        dispatch: dispatchSlice,
        gatepass: gatepassSlice,
    }
})

export default store;